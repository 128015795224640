import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';

import { Avatar } from 'antd';
import { UserOutlined, HomeOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import * as d3 from  "d3";
import _ from 'lodash';
import { parse } from 'papaparse';
import { GlobalStyle } from '../../../../../types/style.d';
import { viewSize, svgSize, themeColor, caseMark } from '../../../../../types/style.global';
import { PaperInspectionTypes, ResearcherStatisticsTypes } from '../../../../../types/data';
import LollipopChart from '../../../common/Visualization/LollipopChart';


interface ResearcherDetailCard {
    viewParam: GlobalStyle.ViewSize;
    ResearcherStatisticsData: ResearcherStatisticsTypes.ResearcherStatisticsUnit;
    boxShadowStyle: string;
    divOutlineColor: string;

}

const ResearcherDetailCard: FC<ResearcherDetailCard> = (props) => {
    const { viewParam, ResearcherStatisticsData, boxShadowStyle, divOutlineColor } = props

    const selectedResearcherBarplotData: PaperInspectionTypes.PaperBarplotData = useSelector((state: State) => state.dataReducer?.selectedResearcherBarplotData) as PaperInspectionTypes.PaperBarplotData

    const [barplotWidth, setBarplotWidth] = useState(475.536);


    const barplotHeight = 200;
    const genderLetter = ResearcherStatisticsData.demographicInfo.gender === "Male" ? "M" : "F"
    const tenureStatus = ResearcherStatisticsData.demographicInfo['tenureStatus']
    //const departmentName = ResearcherStatisticsData.demographicInfo['departmentName'].split(' - ')[1] for Vanderbilt
    const [showImage, setShowImage] = useState<boolean>(true);
    const handleImgError = (event:any) => {
        event.preventDefault();
        setShowImage(false)
    };
    // get avatar image
    let avatarSrc = null;
    // try {
    //   // 使用require引入图片
    //   avatarSrc = require(`../../../../../public/avatar/${ResearcherStatisticsData.empidHash}.png`);
    //   // avatarSrc = require("../../../../../public/avatar/Frame 1.png");
    // } catch (e) {
    //   // 如果文件不存在，则使用默认值
    //   avatarSrc = null;
    // }

    useEffect(() => {
        const updateChartWidth = () => {
            const rightDiv = document.querySelector(".right-div") as HTMLElement;
            if (rightDiv) {
                const rightDivWidth = rightDiv.offsetWidth;
                const leftHalfWidth = 550; // 360; // Fixed width of the left half
                const chartWidth = rightDivWidth - leftHalfWidth - 48; // Subtract margins
                console.log('chartWidth:', chartWidth);
                setBarplotWidth(chartWidth);
            }
        };
    
        // 初始化时更新一次宽度
        updateChartWidth();
    
        // 当窗口大小变化时更新宽度
        window.addEventListener("resize", updateChartWidth);
    
        // 在组件卸载时移除resize事件监听器
        return () => {
            window.removeEventListener("resize", updateChartWidth);
        };
      }, []);

    return (
        <div className='researcherDetailCardDiv'>
            <div className='researcher-left-div'>
                <div className = 'researcher-img-container'>
                    <div className='researcher-img'>
                        {/* <img src={require(`../../../../../assets/11-vu-researchers-20240816/Steven D Townsend.jpg`)} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" /> */}
                        <UserOutlined />
                        {/* {showImage ? (
                            <img src={`${process.env.PUBLIC_URL}/researcherPhoto/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`} onError={handleImgError} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" />
                        // <img src={require(`../../../../../assets/11-vu-researchers-20240816/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`)} onError={handleImgError} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" />
                        ) : (
                            <UserOutlined />
                        )} */}
                    </div>
                </div>
                <div className='researcher-name'>
                    {ResearcherStatisticsData.demographicInfo['fullName']}
                </div>
                <div className="researcher-actions">
                    {/* <div className="researcher-action-icon">
                        <HomeOutlined className="action-icon" href={ResearcherStatisticsData.demographicInfo['weblink']} target="_blank" onClick={(event) => event.stopPropagation()}/>
                    </div>
                    <div className="researcher-action-icon">
                        <PhoneOutlined className="action-icon" />
                    </div> */}
                    {/* <div className="researcher-action-icon">
                        <MailOutlined className="action-icon" />
                    </div> */}
                </div>
            </div>

            <div className='researcher-center-div'>
                <div className='researcher-school'>
                    {ResearcherStatisticsData.demographicInfo['schoolName']}
                </div>
                <div className='researcher-demographic'>
                    <div className='researcher-gender'>
                    <div className='researcher-gender-letter'>
                        {genderLetter}
                    </div>
                    </div>
                    <div className='researcher-tenure'>
                        {ResearcherStatisticsData.demographicInfo['tenureStatus']}
                    </div>
                </div>
                <div className='scisci-index-div-group'>
                    <div className='cardCitation scisci-index'>
                        <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                            <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
                        </svg>
                        <div className='index-name'>papers cited by patents (2001-2023)</div>
                        <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numPapersSince2001CitedByCompanyPatents']}</div>
                    </div>
                    <div className='cardLeadAuthorPapers scisci-index'>
                        <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                            <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
                        </svg>
                        <div className='index-name'>recent high-score lead-author papers (2019-2024)</div>
                        <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numHighScoreLeadRecentPapers']}</div>
                    </div>
                    <div className='cardInventions scisci-index'>
                        <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                            <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
                        </svg>
                        <div className='index-name'>invention disclosures (2001-2023)</div>
                        <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numInventions_2001_2023']}</div>
                    </div>
                </div>
            </div>

            <div className='researcher-right-div'>
                <div className="bar-plot">
                    <LollipopChart paperList={selectedResearcherBarplotData.paper_list} chartHeight={barplotHeight} chartWidth={barplotWidth}/>
                </div>
            </div>
                
        </div>
    )

}


export default ResearcherDetailCard;