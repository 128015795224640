import { ActionType } from '../../action-types';
import { Action } from '../../actions/index';
import produce from 'immer';

// import functions
// import calcDSViewFrontendInputData from './calculators/calcDSViewFrontendInputData';

// import interfaces & data
import { PaperFieldLevel, MatrixDataRange } from '.././../../types/data.global';
import { MatrixTypes2, TooltipTypes } from '../../../types/data';

interface InitialState {
  isLoginPage: boolean;
  authState: {
    token: string | null,
    isAuthenticated: boolean | null,
    loading: boolean | null,
    user: string | null,
    error: null | string
  }

  registerState: {
    registerStatus: boolean,
    error: string | null
  }

  deleteAccount: {
    deleteStatus: boolean,
    error: string | null
  }

  researcherCardListFilter: {
    untapFilter: string;
  }
  // 1. matrix view
  treemapTooltip: {
    tooltipData: TooltipTypes.TooltipData,
    tooltipPosition: TooltipTypes.TooltipPosition,
    tooltipOpacity: number;
  }
  // topKFlow: number;
  paperFieldLevel: string;
  matrixDataRange: string;
  matrixCellPositionData: MatrixTypes2.MatrixPosition;
  paperFieldL0Name: string;

  // tech inspection view(patent)
  topKPatentAssignee: number;

  // 2. timeline brush
  paperYearRange: number[];
  patentYearRange: number[];

  // 3. prediction result组合
  /* 两种模式:
      (1) 仅historical treemap
      (2) historical和prediction flow并存
     交互:
      1. 系统默认：进入(1) 仅historical treemap, flowWidthScale按照仅historicalData计算得到
      2. User Input
          - Step 1 (触发后台get数据，前端不变): 刷选 prediction scoreRange (predictionScoreRange)
            --> 后台get prediction flow
                Note: 只要其中一个change，就去后端get data
          - Step 2-1: 打开switch (showPredictionResult)
            - 前端默认展示all historical + precition flows
          - Step 2-2 (触发前端更新flow): 打开switch && 点击 (rowIdx === 0)的一个或多个cell
            - 前端选择性展示prediction flow - mode 1: 直接从这几个paper cell show prediction flow到patent category
          - Step 2-3 (触发前端更新flow): 打开switch && 点击 patentRect的一个或者多个
            - 前端选择性展示both historical + prediction flow - mode 2
  */
 predictionResultParam: MatrixTypes2.PredictionParam;
}

const initialState: InitialState = {
  isLoginPage: true, 

  authState: {
    token: sessionStorage.getItem('token'),
    isAuthenticated: false,
    loading: false,
    user: null,
    error: null
  },

  registerState: {
    registerStatus: false,
    error: null
  },

  deleteAccount: {
    deleteStatus: false,
    error:null
  },

  researcherCardListFilter: {
    untapFilter: 'all'
  },

  treemapTooltip: {
    tooltipData: {} as TooltipTypes.TooltipData,
    tooltipPosition: {} as TooltipTypes.TooltipPosition,
    tooltipOpacity: 0
  },
  // topKFlow: 1000,
  predictionResultParam: {
    showPredictionResult: false, // mainview: switch
    predictionScoreRange: [0.2, 0.3],  // mainview: slider
    selectedPaperMatrixRowZeroCellList: [],
    selectedPatentFieldList: [],
  },
  paperFieldLevel: PaperFieldLevel.L0,
  matrixDataRange: MatrixDataRange.ALL, // remember全局数据的matrix位置；如果选定不忍researcher高亮，则不需要update paper field cell position
  matrixCellPositionData: {
    cellWidthHeight: 0,
    cellGapWidth:  0,
    colLabelList: [],
    cellElementPositionList: [],
    paperMatrixGapStartingPointXList: []
  },
  paperFieldL0Name: '',
  paperYearRange: [2001, 2020],
  patentYearRange: [2001, 2020],
  topKPatentAssignee: 100,
}

const reducer = produce((state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_IS_LOGIN_PAGE:
      state.isLoginPage = action.isLoginPage
      break
    case ActionType.UPDATE_PAPER_FIELD_LEVEL:
      state.paperFieldLevel = action.paperFieldLevel
      state.paperFieldL0Name = action.paperFieldL0Name
      break
    case ActionType.UPDATE_MATRIX_DATA_RANGE:
      state.matrixDataRange = action.matrixDataRange
      break
    case ActionType.UPDATE_MATRIX_CELL_POSITION_DATA:
      state.matrixCellPositionData = action.matrixPositionData
      break
    case ActionType.UPDATE_PAPER_TIMERANGE:
      state.paperYearRange = action.timeRange
      break
    case ActionType.UPDATE_PATENT_TIMERANGE:
      state.patentYearRange = action.timeRange
      break
    case ActionType.UPDATE_PREDICTION_SCORE_RANGE:
      state.predictionResultParam.predictionScoreRange = action.predictionScoreRange
      break
    case ActionType.UPDATE_HIGHLIGHTED_PAPER_CELL_ROW_ZERO_LIST:
      state.predictionResultParam.selectedPaperMatrixRowZeroCellList = action.selectedPaperMatrixRowZeroCellList
      break
    case ActionType.UPDATE_SHOW_PREDICTION_RESULT:
      state.predictionResultParam.showPredictionResult = action.showPredictionResult
      break
    case ActionType.UPDATE_TREEMAP_TOOLTIP_DATA:
      state.treemapTooltip = action.tooltipData
      break
    case ActionType.UPDATE_TOP_K_PATENT_ASSIGNEE:
      state.topKPatentAssignee = action.topKPatentAssignee
      break
    case ActionType.UPDATE_RESEARCHER_CARD_LIST_FILTER:
      state.researcherCardListFilter.untapFilter = action.untapFilter
      break
    case ActionType.PERFORM_LOGIN:
      state.authState.isAuthenticated = action.isAuthenticated
      state.authState.error = action.error
      state.authState.token = action.token
      break
    case ActionType.PERFORM_LOGOUT:
      state.authState.error = action.error
      state.authState.token = action.token
      state.authState.isAuthenticated = action.isAuthenticated
      break
    case ActionType.PERFORM_REGISTER:
      state.registerState.error = action.error
      state.registerState.registerStatus = action.registerStatus
      break
    case ActionType.PERFORM_DELETE_ACCOUNT:
      state.deleteAccount.error = action.error 
      state.deleteAccount.deleteStatus = action.deleteStatus
      break
    default:
      break
  }
  return state
})

export default reducer;