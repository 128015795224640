import { InteractionTypes, MatrixTypes2 } from '../../../../types/data';
import * as dataForge from 'data-forge';
import _ from 'lodash';

const getSelectedPaperFieldList = (selectedCPCCodeList: InteractionTypes.SelectedCPCCodeInfo[], compondFlowData: MatrixTypes2.CompondFlowUnit[], topKFlow: number) => {
  // 1. filter topKFlow
  const topKFlowList = _.orderBy(compondFlowData, (compondFlowUnit) => compondFlowUnit.numPatent, ['desc']).slice(0, topKFlow + 1)

  // console.log('topKFlowList: ', topKFlowList)

  // 2. group by paper field
  const topKFlowListDF: dataForge.DataFrame<number, MatrixTypes2.CompondFlowUnit> = new dataForge.DataFrame(topKFlowList)

  // get global cpcFieldList for computing cosine similarity of paperFieldPair
  const selectedPaperFieldList: InteractionTypes.SelectedPaperFieldInfo[] = topKFlowListDF
    .groupBy(item => item.colName)
    .select(group => {
      return {
        colIdx: group.first().colIdx,
        colName: group.first().colName,
      }
    }).inflate().toArray()

  // console.log('selectedPaperFieldList: ', selectedPaperFieldList)

  return (selectedPaperFieldList)
}

export default getSelectedPaperFieldList;