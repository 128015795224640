// To replace strings into a unified types. 防止coder在别的文件里面写错string

export enum ActionType {
  GET_MSG = "getMsg",
  GET_LIST = "getList",
  GET_NAME = "getName",
  GET_BACKEND_DATA = "getBackendData", // test
  GET_TIMELINE_DATA = "getTimelineData",
  GET_MATRIX_DATA = "getMatrixData",
  UPDATE_PAPER_FIELD_LEVEL = "updatePaperFieldLevel",
  GET_SCATTERPLOT_DATA = "getScatterplotData",
  GET_ALL_RESEARCHER_ID_LIST = "getAllResearcherIdList",
  GET_RESEARCHER_STATISTICS_DATA = "getResearcherStatisticsData",
  GET_PATENT_INSPECTION_DATA = "getPatentInspectionData",
  GET_PAPER_INSPECTION_DATA = "getPaperInspectionData",
  UPDATE_MATRIX_DATA_RANGE = "updateMatrixDataRange",
  UPDATE_MATRIX_CELL_POSITION_DATA = "updateMatrixCellPositionData",
  UPDATE_SELECTED_RESEARCHER_ID_LIST = "updateSelectedResearcherIdList",
  UPDATE_PAPER_TIMERANGE = 'updatePaperTimerange',
  UPDATE_PATENT_TIMERANGE = 'updatePatentTimerange',
  UPDATE_PREDICTION_SCORE_RANGE = 'updatePredictionScoreRange',
  GET_PREDICTION_RESULT = 'getPredictionResult',
  UPDATE_HIGHLIGHTED_PAPER_CELL_ROW_ZERO_LIST = 'updateHighlightedPaperCellRowZeroList',
  UPDATE_SHOW_PREDICTION_RESULT = 'updateShowPredictionResultSwitch',
  // UPDATE_PATENT_TREEMAP_FLOW_POSITION = 'updatePatentTreemapFlowPosition',
  UPDATE_BRUSHED_PAPER_LIST = "updateBrushedPaperList",
  UPDATE_All_SUBVIEW_PAPER_LIST = "updateAllSubviewPaperList",
  UPDATE_PAPER_BRUSHED_RANGE = "updatePaperBrushedRange",
  UPDATE_SUNBURST_SELECTED_PATENT_LIST = 'updateSunburstSelectedPatentList',
  UPDATE_SHOWED_MIN_TREEMAP_LEVEL = 'updateShowedMinTreemapLevel',
  UPDATE_SELECTED_CPC_CODE_LIST = 'updateSelectedCPCCodeList',
  UPDATE_SELECTED_PAPERCELL_LIST = 'updateSelectedPaperCellList',
  // UPDATE_INTERPLAY_VIEW_DATA_BY_TREEMAP_SELECTION = 'updateInterplayViewDataByTreemapSelection',
  UPDATE_TOP_K_FLOW = 'updateTopKFlow',
  UPDATE_SELECTED_RESEARCHER_GROUP_LIST = 'updateSelectedResearcherGroupList',
  UPDATE_SELECTED_RESEARCHER_CATEGORY_LIST = 'updateSelectedResearcherCategoryList',
  UPDATE_TREEMAP_TOOLTIP_DATA = 'updateTreemapTooltipData',
  UPDATE_TOP_K_PATENT_ASSIGNEE = 'updateTopKPatentAssignee',
  UPDATE_LEFT_VIEW_SELECTED_RESEARCHER_ID_LIST = 'updateLeftViewSelectedResearcherIdList',
  UPDATE_SCATTERPLOT_LASSO_MARK = 'updateScatterplotLassoMark',
  // loading related
  UPDATE_RESEARCHER_OVERVIEW_LOADING = 'updateResearcherOverviewLoading',
  UPDATE_RESEARCHER_STATISTIC_VIEW_LOADING = 'updateResearcherStatisticViewLoading',
  UPDATE_TECH_INSPECTION_VIEW_LOADING = 'updateTechnologyInspectionViewLoading',
  UPDATE_SCIENCE_INSPECTION_VIEW_LOADING = 'updateScienceInspectionViewLoading',
  UPDATE_MAINVIEW_LOADING = 'updateMainviewLoading',

  // Beta2024
  SET_IDEA_FINDER_SELECTED_PAPER = 'setideaFinderSelectedPaper',
  SET_IS_LOGIN_PAGE =  'setIsLoginPage',
  SET_RESEARCHER_RANK_BY_FIELD = 'setResearcherRankByField',
  SET_RESEARCHER_RANK_ORDER = 'setResearcherRankOrder',
  SET_SEARCH_FACULTY_KEYWORD = 'setSearchFacultyKeyword',
  GET_SELECTED_RESEARCHER_ID_LIST = 'getSelectedResearcherIDList',
  GET_RESEARCHER_SCATTERPLOT_DATA = 'getResearcherScatterplotData',
  GET_RESEARCHER_CARD_LIST_DATA = 'getResearcherCardListData',

  SET_PAPER_RANK_BY_FIELD = 'setPaperRankByField',
  SET_PAPER_RANK_ORDER = 'setPaperRankOrder',
  SET_SEARCH_PAPER_KEYWORD = 'setSearchPaperKeyword',
  GET_SELECTED_PAPER_ID_LIST = 'getSelectedPaperIDList',
  GET_PAPER_CARD_LIST_DATA = 'getPaperCardListData',

  SET_SELECTED_RESEARCHER_ID = 'setSelectedResearcherID',
  SET_SELECTED_AUTHOR_ID = 'setSelectedAuthorID',
  GET_RESEARCHER_BARPLOT_DATA = 'getResearcherBarplotData',
  GET_SELECTED_RESEARCHER_PAPER_PATENT_LIST_DATA = 'getSelectedResearcherPaperPatentList',

  UPDATE_RESEARCHER_CARD_LIST_FILTER = 'updateResearcherCardListFilter',
  GET_TOPK_PAPER_CARD_LIST_DATA = 'getTopKPaperCardListData',
  PERFORM_LOGIN = 'performLogin',
  PERFORM_LOGOUT = 'performLogout',
  PERFORM_REGISTER = 'performRegister',
  PERFORM_DELETE_ACCOUNT = 'performDeleteAccount',

  SET_SELECTED_PAPER_ID = 'setSelectedPaperID',
  GET_SELECTED_PAPER_PATENT_LIST = 'getSelectedPaperPatentList'
}