import React, { FunctionComponent as FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';


import { GlobalStyle } from '../../../types/style.d';

import TeaserPage from './TeaserPage';
import BackgroundPage from './BackgroundPage';
import ThreeFinderPage from './ThreeFinderPage';
import AboutPage from './AboutPage';

interface HomePageProps {

}

const HomePage: FC<HomePageProps> = (props) => {

  const {
  } = props


  return (
    <div className='home-page-div'>
      <TeaserPage />
      {/* <BackgroundPage /> */}
      <ThreeFinderPage />
      <AboutPage />
    </div>

  )
}

export default HomePage