import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';

import { Avatar, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as d3 from  "d3";
import _ from 'lodash';
import { parse } from 'papaparse';
import { GlobalStyle } from '../../../../../types/style';
import { viewSize, svgSize, themeColor, caseMark } from '../../../../../types/style.global';
import { PatentInspectionTypes, } from '../../../../../types/data';
//import ResearcherDetails from '../../../ThreePages/PeopleFinderPage/ResearcherDetails';


interface PatentInspectionCard {
  viewName: string;
  viewParam: GlobalStyle.ViewSize;
  PatentInspectionData: PatentInspectionTypes.PatentCardInspectionUnit;
}

const PatentCard: FC<PatentInspectionCard> = (props) => {
  const { viewName, viewParam, PatentInspectionData } = props
  console.log('PatentInspectionData`:', PatentInspectionData)

  // const dispatch = useDispatch()
  // const {
  //   setSelectedAuthorID,
  //   setSelectedResearcherID,
  // } = bindActionCreators(actionCreators, dispatch)

  // const navigate = useNavigate();

  const getWhereFoundLabel = (status:any) => {
    console.log('status: ', status)
    return status;
    switch (status) {
      case 'frontonly':
        return 'front page';
      case 'bodyonly':
        return 'body';
      case 'both':
        return 'front page and body';
    }
  };

  const combinedAssignees = [
    ...PatentInspectionData.universityAssignees,
    ...PatentInspectionData.otherAssignees,
  ];
  
  return (
    <div className='patentCardDiv'
    style={{
          height: "250px", 
          width: viewParam.width, 
          margin:"20px 20px"}}
  >
    <div className='card-top-div'>
      <div className='card-metadata'>
          <div className='card-year'>
              <div className='card-year-text'>
                <span>{`${PatentInspectionData.patent_grantedyear}`}</span>
                {/* <span>{PatentInspectionData.patent_applicationyear}</span> */}
              </div>
          </div>

          {/* <div className='card-assignee'>
              <span className="card-assignee-country">{PatentInspectionData.patent_assignee_country}</span>
              <span className="card-assignee-type">{' > ' + PatentInspectionData.patent_assignee_type}</span>
          </div> */}
      </div>

      <div className='card-title'>
          <div className='card-title-text'> {PatentInspectionData.patent_title}</div>
          <div className='card-title-tooltip'> 
              <div className='card-title-tooltip-text'>
                {PatentInspectionData.patent_title}
              </div>
          </div>
      </div>
      <div className='card-assignee-company'>
        <div className='card-assignee-company-text'>
          <span>{'Company Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {_.isEmpty(PatentInspectionData.companyAssignees) ? '-' : PatentInspectionData.companyAssignees.join(', ')}
          </div>
          {!_.isEmpty(PatentInspectionData.companyAssignees) && (
            <div className='card-assignee-name-tooltip'>
              <div className='card-assignee-name-tooltip-text'>
                {PatentInspectionData.companyAssignees.join(', ')}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='card-assignee-other'>
        <div className='card-assignee-other-text'>
          <span>{'Other Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {_.isEmpty(combinedAssignees) ? '-' : combinedAssignees.join(', ')}
          </div>
          {!_.isEmpty(combinedAssignees) && (
            <div className='card-assignee-name-tooltip'>
              <div className='card-assignee-name-tooltip-text'>
                {combinedAssignees.join(', ')}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {(!_.isEmpty(PatentInspectionData.universityAssignees) && <div className='card-assignee-university'>
        <div className='card-assignee-university-text'>
          <span>{'University Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {PatentInspectionData.universityAssignees.join(', ')}
          </div>
          <div className='card-assignee-name-tooltip'>
              <div className='card-assignee-name-tooltip-text'>
              {PatentInspectionData.universityAssignees.join(', ')}
              </div>
          </div>
        </div>
      </div>
      )}
      {(_.isEmpty(PatentInspectionData.universityAssignees) && <div className='card-assignee-university'>
        <div className='card-assignee-university-text'>
          <span>{'University Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {"-"}
          </div>
        </div>
      </div>
      )} */}

      {/* {(!_.isEmpty(PatentInspectionData.otherAssignees) && <div className='card-assignee-other'>
        <div className='card-assignee-other-text'>
          <span>{'Other Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {PatentInspectionData.otherAssignees.join(', ')}
          </div>
          <div className='card-assignee-name-tooltip'>
              <div className='card-assignee-name-tooltip-text'>
              {PatentInspectionData.otherAssignees.join(', ')}
              </div>
        </div>
        </div>
      </div>
      )}
      {(_.isEmpty(PatentInspectionData.otherAssignees) && <div className='card-assignee-other'>
        <div className='card-assignee-other-text'>
          <span>{'Other Assignee'}</span>
        </div>
        <div className='card-assignee-name'>
          <div className='card-assignee-name-text'>
            {"-"}
          </div>
        </div>
      </div>
      )} */}
      

      <div className='card-bottom-metadata'>
        <div className='card-app-where'>
          <div className='card-app-label-group'>
            <div className='card-app-label'>Citation Type: </div>
            <div className='card-app-container'>
              <div className='card-app-badge'>
                <strong>{PatentInspectionData.applicantAdded_vs_examinerAdded}</strong>
                {/* <strong>{(PatentInspectionData.applicantAdded_vs_examinerAdded) === 'app' ? 'applicant' : 'examiner'}</strong> */}
              </div>
            </div>
          </div>
          <div className='card-app-label-group'>
            <div className='card-app-label'>Citation Placement: </div>
            <div className='card-where-container'>
              <div className='card-where-badge'>
                <strong>{PatentInspectionData.where_found}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-weblink'>
        <span className="card-weblink-text"><a href={PatentInspectionData.patent_weblink} target="_blank">{"USPTO Patent Link"}</a></span>
      </div> 
    </div>
    
  </div>

  )
}


export default PatentCard;