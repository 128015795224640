import { ActionType } from "../../action-types";
import { Action } from "../../actions/index";
import { current } from '@reduxjs/toolkit'; // https://stackoverflow.com/questions/57240245/how-do-i-see-state-when-logging-to-the-console-instead-of-proxy-object-inside-re
import produce from "immer";
import _ from 'lodash';

// import functions
import getSelectedPaperFieldList from './function/getSelectedPaperFieldList';
import filterAndReorderTimelineData from './function/filterAndReorderTimelineData'
import { getOrderedFilteredFlowData, getOrderedFilteredPaperFieldData } from './function/getOrderedFilteredPaperFieldData';
import filterInterplayViewDataBySelectedCPCField from './function/filterInterplayViewDataBySelectedCPCField';
import calcInterplayViewData from './function/calcInterplayViewData';
import getPaperFieldPatentCitationDiversity from './function/getPaperFieldPatentCitationDiversity';
import getCompondPatentTreemapData from './function/getCompondPatentTreemapData';
import getCompondFlowData from './function/getCompondFlowData';
import getViolinPlotXRange from './function/getViolinPlotXRange';
import { getPaperMatrixFigureParam, getPatentTreemapFigureParam, getFlowGroupFigureParam } from './function/getInterplayViewFigureParam';

// import interfaces
import {
  TimelineTypes,
  MatrixTypes2,
  ResearcherOverviewTypes,
  ResearcherStatisticsTypes,
  PatentInspectionTypes,
  PaperInspectionTypes,
  InteractionTypes,
  QueryViewTypes,
} from "../../../types/data";
import {
  ignoredPaperField,
  paperFieldL0Order,
  CPCFieldLevelList,
} from "../../../types/data.global";
import { svgSize } from '../../../types/style.global';

interface InitialState {
  ideaFinderSelectedPaper: PaperInspectionTypes.PaperInspectionUnit | null;
  testData: string;

  selectedResearcherIdList: string[];
  scatterplotData: QueryViewTypes.ScatterPlotUnit[];
  researcherRankByField: string,
  researcherRankOrder: string,
  searchFacultyKeyword: string,
  selectedPaperIdList: string[];
  paperRankByField: string,
  paperRankOrder: string,
  searchPaperKeyword: string,
  paperCardListData:  PaperInspectionTypes.PaperInspectionUnit[];
  paperCardSize: number;
  selectedResearcherId: ResearcherStatisticsTypes.ResearcherStatisticsUnit | null;
  selectedAuthorId: string; 
  //selectedResearcherPaperList: PaperInspectionTypes.PaperInspectionUnit[];
  selectedResearcherPaperList: PaperInspectionTypes.PaperInspectionUnit[];
  selectedResearcherPaperPatentList: PaperInspectionTypes.PaperPatentInspectionUnit[];
  selectedResearcherBarplotData: PaperInspectionTypes.PaperBarplotData;

  selectedPaperID: string;
  selectedPaperPatentList: PatentInspectionTypes.PatentInspectionUnit[];

  timelineData: TimelineTypes.TimelineData;
  // papermetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict;
  // papermetriceRangeDictBackend: MatrixTypes2.PaperMetriceRangeDict;
  matrixData: MatrixTypes2.InputData;
  // diversityData: MatrixTypes2.DiversityData; // 目前没有时间信息
  // scatterplotData: ResearcherOverviewTypes.ScatterPlotUnit[];
  researcherStatisticsData: ResearcherStatisticsTypes.ResearcherStatisticsUnit[];
  researcherCardSize: number;
  selectedResearcherGroupList: ResearcherStatisticsTypes.ResearcherStatisticsUnit[],
  selectedResearcherCategoryList: ResearcherStatisticsTypes.SelectedCategory[],
  patentInspectionData: PatentInspectionTypes.PatentData;
  // selectedSunburstPatentData的type待修改
  selectedPatentAssigneeID: string;
  paperInspectionData: PaperInspectionTypes.PaperInspectionUnit[];

  // selectedResearcherIdList: string[];
  leftViewSelectedResearcherIdList: string[];
  allResearcherIdList: string[];
  scatterplotLassoMark: boolean;
  brushedPaperList: PaperInspectionTypes.PaperInspectionUnit[];
  allSubviewPaperList: PaperInspectionTypes.SubViewPaperData;
  brushedRange: PaperInspectionTypes.histogramBrushedRange;

  // 1. Interplay View
  filteredColIdxDict: { [rawColIdx: number]: number };
  topKFlow: number;
  interplayViewData: MatrixTypes2.InterplayViewData;
  selectedCPCFieldCode: string; // filter mainview data，基于选择的cpc field rect
  selectedCPCFieldLevel: string;
  selectedPaperCellList: InteractionTypes.SelectedPaperCellInfo[]; // to highlight flow
  selectedCPCCodeList: InteractionTypes.SelectedCPCCodeInfo[]; // to highlight flow
  selectedPaperFieldList: InteractionTypes.SelectedPaperFieldInfo[];
  // showedTreemapLevelList: string[];
  cpcFieldLevel: string;
  // compondFlowData: MatrixTypes2.CompondFlowUnit[];
  // compondPatentTreemapData: MatrixTypes2.CompondTreemapUnit[];
  predictionResult: {
    predictedFlowList: MatrixTypes2.FlowUnit[];
    predictedPatentTreemap: MatrixTypes2.TreemapUnit[];
  },
  // loading related
  isResearcherOverviewLoading: boolean,
  isResearcherStatisticViewLoading: boolean,
  isTechnologyInspectionViewLoading: boolean,
  isScienceInspectionViewLoading: boolean,
  isMainviewLoading: boolean,
  // interplayViewFigureParam: MatrixTypes2.InterplayViewFigureParam;

  // // 0. loading
  // isLoadingDSViewTableData: boolean,
  // // 1. header
  // datasetSize: number,
  // // 2. DS View
  // dsViewBackendReturnedData: DescriptiveStatisticsTypes.BackendReturnedData,
}

const initialState: InitialState = {
  ideaFinderSelectedPaper: null,
  selectedResearcherIdList: ['all'],
  scatterplotData: [
    {
      "researcher_id": "376ddddf6a458d491d4bd6f23f6870dfd18abec4",
      "xValue": 3,
      "yValue": 4,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "ee805ceec341cfc00f0af83e9ca307cc5996e70a",
      "xValue": 8,
      "yValue": 3,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "abac3ed7e6e00a5fef5f4f190fa18f9c3d4fc25a",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "acbf6fc630cb25c691d254efb8ef331c517b469c",
      "xValue": 0,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "db897a43acbd6a21be7402dadd9607449e0fd3bd",
      "xValue": 1,
      "yValue": 6,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "cc717d18f15a1a25e99cb7a2df07c4db44d0e24a",
      "xValue": 9,
      "yValue": 12,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "97c1a9655e7f89cdd17a8d1edf4b1dacdf0c0eed",
      "xValue": 2,
      "yValue": 7,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "d1a9bf3cde30feef21d16284531821f3931f7be4",
      "xValue": 0,
      "yValue": 15,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "54ba78b5c42b4b6cf997ef59f7b3a27f344acb7e",
      "xValue": 16,
      "yValue": 54,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "07d30118ebd422d64f13efdedc2cc8e7fd287f3b",
      "xValue": 8,
      "yValue": 16,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "cd5d82c80c9aa4d0d0177e3e7fb7e0c051247111",
      "xValue": 10,
      "yValue": 15,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "8b6dbfcf5664935a2d0551880f1630e65163ad8e",
      "xValue": 15,
      "yValue": 4,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "b352771dc7a0f6bb286d6d578d8f4c8ee93dccd7",
      "xValue": 2,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "da06f5f591a1d744d9b38589080c4c1bae2b0cfd",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "5d99fb58b943fba276c4b919707076840a52e496",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "2b9a2dde992ee0c5e97cf9a69991acb9a1aa053d",
      "xValue": 0,
      "yValue": 13,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "820479326cf88ca7954c7ccc2a76fb5078f31a58",
      "xValue": 1,
      "yValue": 8,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "ccce6e18c55659e315570c1e646da18a5d21c3d3",
      "xValue": 6,
      "yValue": 40,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "d28e235e710175def40447c877638c142646bc3d",
      "xValue": 8,
      "yValue": 60,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "31f892aac47a806bdedda37c4523734a143ce867",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "78470afbd00fc6faefaa4a84baaf19c4365c16f2",
      "xValue": 1,
      "yValue": 8,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "5e9f0bb5240b91a70b842a5d3ec6eb8efe3034ca",
      "xValue": 1,
      "yValue": 8,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "7fbe77c4228c78968b0307b75ff25eff54aa76dc",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "2a5f4a486bb9cecc4fd7c4672c5118823d70e9e6",
      "xValue": 11,
      "yValue": 23,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "a9a4a8b2093752aa655752375b4b6e9ef0517a95",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "141eb03d45b492320cfafde445a632e9e5bcdcb8",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "df0931378e6a8bf33c133693789d7451a36794e2",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "dbdec4d0bf43ae9814ebfe5f8657407f1aaad09e",
      "xValue": 2,
      "yValue": 17,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "d85862913a526a48e9b0e25efae6ea0b908b2edf",
      "xValue": 2,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "7030fe430c5798f4f51071ec0a060aaa1498eecd",
      "xValue": 1,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "007ea231bf7270cf23610fbfb10f8985659a1e88",
      "xValue": 3,
      "yValue": 27,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "f3e734954b5155dd31f8adfbb9be5b8d7e46a1de",
      "xValue": 9,
      "yValue": 35,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "b65c90e13fd20af984ac7d7668858413d10f2dac",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "2e3622ae3630168f0ee9dacdb3fb7f830f08ce0f",
      "xValue": 1,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "aadda93e13bb38341af65b0a75bc0a3937db048f",
      "xValue": 0,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "3a3798b2c17279dbbcdfa4d15b1d6cb3acad2317",
      "xValue": 0,
      "yValue": 4,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "c532c1d1f6311d9703906c6da46fddf5c7ba13e4",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "2ad0b8914d35fdf0c85a106a34ca2674c3ca502e",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4f2fc9d319c01b788e19efcf25ccaa798d4a4236",
      "xValue": 6,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "9e5407977bacc03e2308e6e98cb24091c35747ee",
      "xValue": 0,
      "yValue": 14,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "f9aadad568e028165ea20477da4fcd552ab74951",
      "xValue": 7,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "171a2812a8044a12af35f45aa1d16b8dc4ef5c1e",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "16294d6031aaad9ece5897d9d52396609ee557d8",
      "xValue": 0,
      "yValue": 2,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "c147d5da66fa5b1ad498ac9b1ab9a20ead7cd9ae",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "3de3edaf71175d1a48822a04b94a38083cdf26c5",
      "xValue": 22,
      "yValue": 32,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "f1a97342dfc62abd3211b986e975cc188eac140d",
      "xValue": 1,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "8388c92dc518a13575d23e9d571ab420bfbadbbf",
      "xValue": 15,
      "yValue": 30,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "1de563a84b6943171df5f10c96bc660c3dd67631",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "f390565731901b912ada881129a386646dd7e731",
      "xValue": 1,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "bccbe3d064783e8205440479f1adaff44399c50a",
      "xValue": 1,
      "yValue": 22,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "96d5993d47834aa6974dabc289b4879d4e1c09b1",
      "xValue": 1,
      "yValue": 9,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "8683e55161eb1a384a4a47f8a998b0e7d094aa56",
      "xValue": 0,
      "yValue": 20,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4e12f8e53920f2739944d5d4e98022f82845852d",
      "xValue": 0,
      "yValue": 6,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "86b7d67decc01aff662d3168b1397dbe193bc62c",
      "xValue": 0,
      "yValue": 3,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "eded95ae9255b3a26917b0c2a289e7cba6e3a355",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4eadbfe4dd1d91e54d09f4705b3f219705e1d6e2",
      "xValue": 0,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "0026553a02e5363b5f793b4e24e0ffb4c69e12e6",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "5aca8a50b8db93d32bd37a650c5cca77e9b0f210",
      "xValue": 0,
      "yValue": 33,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "21fea8ee71935945cdf9eb8670e4ff8fbe9ade6c",
      "xValue": 1,
      "yValue": 6,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4998c4dc7c297d303a1ade24501987d0ae779a78",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "64bdf33ce3e1789201261f7707184b67c3ea2091",
      "xValue": 4,
      "yValue": 6,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "c4a3089a9bb5bf34981cdc21fce1ae6eabdeef83",
      "xValue": 3,
      "yValue": 18,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "e44119fb279e452095dc90e380b850916e8aa538",
      "xValue": 2,
      "yValue": 11,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4ebbd253aa6a4de5237ca3492dcfde30752ee431",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "4d8749c9ca7fe07fd22ce87a5e9eb39a355f7416",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "837f2f82ac51de1ac2c9df0e02a020883dc9e438",
      "xValue": 0,
      "yValue": 3,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "73a39637b2185b354a90ed6279e2b5b5086ba5d4",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "3b9614fa3d4a5a6255060bf1e581f24fd33e7a37",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "24c52e8be69ff0d0eba2bb6ebe0376e9de2e4e87",
      "xValue": 0,
      "yValue": 4,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "ecb6903cbb0fedbae390a7196603ac6738d85292",
      "xValue": 2,
      "yValue": 21,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "ed90405f69038f5180d44c05030ec0e12aa9b8a6",
      "xValue": 0,
      "yValue": 3,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "2cdaed8c38fcce254ac2207a7aabbb9185815e35",
      "xValue": 4,
      "yValue": 31,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "d59fd72dfe9ab24320d373880d162bdb18e20e53",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "086da81ef65a59c1bf9c40739b8e77355af8cc67",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "449431da47225068ef66b0935ef534ab1878f655",
      "xValue": 6,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "873aee5e298a6d36cdadb334323e98ff18b4c48b",
      "xValue": 7,
      "yValue": 6,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "f048d6679e9dabeebe000807c764ec93b41d24b9",
      "xValue": 0,
      "yValue": 1,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "05a7320bbb484141256cb6cc8ad874da5020d453",
      "xValue": 2,
      "yValue": 18,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "5eaab4332159c7bfce6b0d8c7d0fb37687c954ca",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "b57df011e28124a2dc6f2d76d3b8522ffeb5eb95",
      "xValue": 0,
      "yValue": 3,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "e52e4c5131078615218701cf766cf8fb5c6a3cb4",
      "xValue": 1,
      "yValue": 5,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "55cfd8174187f66c58c8ad768f9d7e83fe732cfd",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "51dff4533e3b76bc6e6884612822521353710fb7",
      "xValue": 3,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "dc2d127e607c55dfca9df0c8a20d87a63edd612b",
      "xValue": 0,
      "yValue": 0,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
    {
      "researcher_id": "8dc9be6dc57a09d6304613ff3b6d6888cae3836f",
      "xValue": 0,
      "yValue": 2,
      "lof_anomaly_score": 0,
      "pIndex": 0
    },
  ],
  researcherRankByField: "numHighScoreLeadAuthorPapers", // "numInventions_2001_2023",
  researcherRankOrder: "descending",
  searchFacultyKeyword: "",

  researcherStatisticsData: [], 
  researcherCardSize: 0,
  // researcherStatisticsData: [
  //   {
  //     "empidHash": "empid_0",
  //     "demographicInfo": {
  //       "fullName": "John Doe",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Computer Science",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://johndoe.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 45,
  //       "scientificImpact": 92.3,
  //       "productivity": 85.6,
  //       "numPapersCitedByPatents": 23,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 200,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.5,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.9,
  //       "science_citations_C5": 67
  //     }
  //   },
  //   {
  //     "empidHash": "empid_1",
  //     "demographicInfo": {
  //       "fullName": "Jane Smith",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Biochemistry",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://janesmith.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 38,
  //       "scientificImpact": 88.1,
  //       "productivity": 79.4,
  //       "numPapersCitedByPatents": 15,
  //       "numPatentsCitingAuthorPapers": 9,
  //       "numPatentCitations": 130,
  //       "numInventions": 3,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.9,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.5,
  //       "science_citations_C5": 58
  //     }
  //   },
  //   {
  //     "empidHash": "empid_2",
  //     "demographicInfo": {
  //       "fullName": "Alice Johnson",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Literature",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://alicejohnson.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 22,
  //       "scientificImpact": 74.2,
  //       "productivity": 65.3,
  //       "numPapersCitedByPatents": 10,
  //       "numPatentsCitingAuthorPapers": 5,
  //       "numPatentCitations": 90,
  //       "numInventions": 2,
  //       "numPapers": 140,
  //       "numPatents": 4,
  //       "mean_Cf_L1": 2.8,
  //       "num_high_predicted_score_paper": 4,
  //       "pIndex": 6.1,
  //       "science_citations_C5": 45
  //     }
  //   },
  //   {
  //     "empidHash": "empid_3",
  //     "demographicInfo": {
  //       "fullName": "Bob Brown",
  //       "schoolName": "School of Science",
  //       "departmentName": "Physics",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://bobbrown.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 50,
  //       "scientificImpact": 95.6,
  //       "productivity": 90.1,
  //       "numPapersCitedByPatents": 30,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 7,
  //       "numPapers": 220,
  //       "numPatents": 12,
  //       "mean_Cf_L1": 5.2,
  //       "num_high_predicted_score_paper": 9,
  //       "pIndex": 9.4,
  //       "science_citations_C5": 72
  //     }
  //   },
  //   {
  //     "empidHash": "empid_4",
  //     "demographicInfo": {
  //       "fullName": "Charlie Davis",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Electrical Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Assistant Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://charliedavis.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 30,
  //       "scientificImpact": 81.4,
  //       "productivity": 75.6,
  //       "numPapersCitedByPatents": 18,
  //       "numPatentsCitingAuthorPapers": 10,
  //       "numPatentCitations": 120,
  //       "numInventions": 4,
  //       "numPapers": 160,
  //       "numPatents": 6,
  //       "mean_Cf_L1": 3.5,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.2,
  //       "science_citations_C5": 54
  //     }
  //   },
  //   {
  //     "empidHash": "empid_5",
  //     "demographicInfo": {
  //       "fullName": "Dana Evans",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Cardiology",
  //       "gender": "Female",
  //       "jobTitle": "Clinical Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://danaevans.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "France",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 40,
  //       "scientificImpact": 89.3,
  //       "productivity": 82.7,
  //       "numPapersCitedByPatents": 25,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 140,
  //       "numInventions": 6,
  //       "numPapers": 190,
  //       "numPatents": 9,
  //       "mean_Cf_L1": 4.1,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 8.5,
  //       "science_citations_C5": 64
  //     }
  //   },
  //   {
  //     "empidHash": "empid_6",
  //     "demographicInfo": {
  //       "fullName": "Eli Fisher",
  //       "schoolName": "School of Arts",
  //       "departmentName": "History",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://elifisher.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Italy",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 28,
  //       "scientificImpact": 77.1,
  //       "productivity": 68.4,
  //       "numPapersCitedByPatents": 12,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 100,
  //       "numInventions": 3,
  //       "numPapers": 150,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.2,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.8,
  //       "science_citations_C5": 48
  //     }
  //   },
  //   {
  //     "empidHash": "empid_7",
  //     "demographicInfo": {
  //       "fullName": "Fiona Green",
  //       "schoolName": "School of Science",
  //       "departmentName": "Chemistry",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://fionagreen.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Japan",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 35,
  //       "scientificImpact": 85.4,
  //       "productivity": 78.6,
  //       "numPapersCitedByPatents": 20,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 5,
  //       "numPapers": 170,
  //       "numPatents": 7,
  //       "mean_Cf_L1": 4.0,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.8,
  //       "science_citations_C5": 52
  //     }
  //   },
  //   {
  //     "empidHash": "empid_8",
  //     "demographicInfo": {
  //       "fullName": "George Harris",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Mechanical Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://georgeharris.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 47,
  //       "scientificImpact": 93.2,
  //       "productivity": 86.9,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 155,
  //       "numInventions": 6,
  //       "numPapers": 210,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.6,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 9.0,
  //       "science_citations_C5": 70
  //     }
  //   },
  //   {
  //     "empidHash": "empid_9",
  //     "demographicInfo": {
  //       "fullName": "Hannah Irwin",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Neuroscience",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://hannahirwin.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 42,
  //       "scientificImpact": 90.1,
  //       "productivity": 83.5,
  //       "numPapersCitedByPatents": 24,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 140,
  //       "numInventions": 5,
  //       "numPapers": 195,
  //       "numPatents": 9,
  //       "mean_Cf_L1": 4.2,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 8.6,
  //       "science_citations_C5": 62
  //     }
  //   },
  //   {
  //     "empidHash": "empid_10",
  //     "demographicInfo": {
  //       "fullName": "Isabel Jones",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Philosophy",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://isabeljones.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 25,
  //       "scientificImpact": 75.4,
  //       "productivity": 68.7,
  //       "numPapersCitedByPatents": 11,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 95,
  //       "numInventions": 3,
  //       "numPapers": 145,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 2.9,
  //       "num_high_predicted_score_paper": 4,
  //       "pIndex": 6.4,
  //       "science_citations_C5": 47
  //     }
  //   },
  //   {
  //     "empidHash": "empid_11",
  //     "demographicInfo": {
  //       "fullName": "Jack Kelly",
  //       "schoolName": "School of Science",
  //       "departmentName": "Mathematics",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://jackkelly.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 55,
  //       "scientificImpact": 97.4,
  //       "productivity": 92.3,
  //       "numPapersCitedByPatents": 32,
  //       "numPatentsCitingAuthorPapers": 17,
  //       "numPatentCitations": 165,
  //       "numInventions": 8,
  //       "numPapers": 225,
  //       "numPatents": 13,
  //       "mean_Cf_L1": 5.4,
  //       "num_high_predicted_score_paper": 10,
  //       "pIndex": 9.7,
  //       "science_citations_C5": 75
  //     }
  //   },
  //   {
  //     "empidHash": "empid_12",
  //     "demographicInfo": {
  //       "fullName": "Karen Lee",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Civil Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Assistant Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://karenlee.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 33,
  //       "scientificImpact": 83.7,
  //       "productivity": 77.9,
  //       "numPapersCitedByPatents": 19,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 125,
  //       "numInventions": 4,
  //       "numPapers": 165,
  //       "numPatents": 6,
  //       "mean_Cf_L1": 3.7,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.4,
  //       "science_citations_C5": 56
  //     }
  //   },
  //   {
  //     "empidHash": "empid_13",
  //     "demographicInfo": {
  //       "fullName": "Larry Martinez",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Dermatology",
  //       "gender": "Male",
  //       "jobTitle": "Clinical Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://larrymartinez.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "France",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 39,
  //       "scientificImpact": 86.5,
  //       "productivity": 80.8,
  //       "numPapersCitedByPatents": 22,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 140,
  //       "numInventions": 5,
  //       "numPapers": 185,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 4.0,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.1,
  //       "science_citations_C5": 60
  //     }
  //   },
  //   {
  //     "empidHash": "empid_14",
  //     "demographicInfo": {
  //       "fullName": "Megan Nelson",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Sociology",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://megannelson.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Italy",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 27,
  //       "scientificImpact": 78.9,
  //       "productivity": 70.2,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.3,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.9,
  //       "science_citations_C5": 50
  //     }
  //   },
  //   {
  //     "empidHash": "empid_15",
  //     "demographicInfo": {
  //       "fullName": "Nathan Ortiz",
  //       "schoolName": "School of Science",
  //       "departmentName": "Biology",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://nathanortiz.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Japan",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 44,
  //       "scientificImpact": 92.0,
  //       "productivity": 84.5,
  //       "numPapersCitedByPatents": 28,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 150,
  //       "numInventions": 6,
  //       "numPapers": 205,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.5,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 8.8,
  //       "science_citations_C5": 68
  //     }
  //   },
  //   {
  //     "empidHash": "empid_16",
  //     "demographicInfo": {
  //       "fullName": "Olivia Parker",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Chemical Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://oliviaparker.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 49,
  //       "scientificImpact": 94.8,
  //       "productivity": 87.2,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 160,
  //       "numInventions": 7,
  //       "numPapers": 215,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.8,
  //       "num_high_predicted_score_paper": 9,
  //       "pIndex": 9.2,
  //       "science_citations_C5": 73
  //     }
  //   },
  //   {
  //     "empidHash": "empid_17",
  //     "demographicInfo": {
  //       "fullName": "Patrick Quinn",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Oncology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://patrickquinn.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 37,
  //       "scientificImpact": 87.5,
  //       "productivity": 81.2,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 175,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.8,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.7,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_18",
  //     "demographicInfo": {
  //       "fullName": "Quincy Rogers",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Economics",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://quincyrogers.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 29,
  //       "scientificImpact": 79.3,
  //       "productivity": 71.5,
  //       "numPapersCitedByPatents": 14,
  //       "numPatentsCitingAuthorPapers": 8,
  //       "numPatentCitations": 110,
  //       "numInventions": 3,
  //       "numPapers": 160,
  //       "numPatents": 6,
  //       "mean_Cf_L1": 3.4,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.0,
  //       "science_citations_C5": 51
  //     }
  //   },
  //   {
  //     "empidHash": "empid_19",
  //     "demographicInfo": {
  //       "fullName": "Rachel Scott",
  //       "schoolName": "School of Science",
  //       "departmentName": "Astronomy",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://rachelscott.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 41,
  //       "scientificImpact": 91.5,
  //       "productivity": 84.0,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 145,
  //       "numInventions": 5,
  //       "numPapers": 200,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.3,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.4,
  //       "science_citations_C5": 65
  //     }
  //   },
  //   {
  //     "empidHash": "empid_20",
  //     "demographicInfo": {
  //       "fullName": "Steve Thompson",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Computer Science",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://stevethompson.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 48,
  //       "scientificImpact": 93.7,
  //       "productivity": 87.5,
  //       "numPapersCitedByPatents": 28,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 12,
  //       "mean_Cf_L1": 4.9,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.1,
  //       "science_citations_C5": 71
  //     }
  //   },
  //   {
  //     "empidHash": "empid_21",
  //     "demographicInfo": {
  //       "fullName": "Tina Underwood",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Pathology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://tinaunderwood.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 34,
  //       "scientificImpact": 88.7,
  //       "productivity": 81.8,
  //       "numPapersCitedByPatents": 20,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 4.0,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.9,
  //       "science_citations_C5": 56
  //     }
  //   },
  //   {
  //     "empidHash": "empid_22",
  //     "demographicInfo": {
  //       "fullName": "Uma Vasquez",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Art History",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://umavasquez.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 26,
  //       "scientificImpact": 76.5,
  //       "productivity": 69.8,
  //       "numPapersCitedByPatents": 12,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 100,
  //       "numInventions": 3,
  //       "numPapers": 150,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.1,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.7,
  //       "science_citations_C5": 49
  //     }
  //   },
  //   {
  //     "empidHash": "empid_23",
  //     "demographicInfo": {
  //       "fullName": "Victor Williams",
  //       "schoolName": "School of Science",
  //       "departmentName": "Geology",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://victorwilliams.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 43,
  //       "scientificImpact": 90.9,
  //       "productivity": 83.8,
  //       "numPapersCitedByPatents": 25,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 145,
  //       "numInventions": 5,
  //       "numPapers": 195,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.4,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.3,
  //       "science_citations_C5": 63
  //     }
  //   },
  //   {
  //     "empidHash": "empid_24",
  //     "demographicInfo": {
  //       "fullName": "Wendy Xavier",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Industrial Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://wendyxavier.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 50,
  //       "scientificImpact": 94.2,
  //       "productivity": 87.8,
  //       "numPapersCitedByPatents": 29,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 5.0,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.0,
  //       "science_citations_C5": 72
  //     }
  //   },
  //   {
  //     "empidHash": "empid_25",
  //     "demographicInfo": {
  //       "fullName": "Xander Young",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Radiology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://xanderyoung.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 36,
  //       "scientificImpact": 88.0,
  //       "productivity": 80.7,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 175,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.9,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.6,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_26",
  //     "demographicInfo": {
  //       "fullName": "Yasmine Zane",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Political Science",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://yasminezane.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 27,
  //       "scientificImpact": 77.0,
  //       "productivity": 70.1,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.3,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.8,
  //       "science_citations_C5": 50
  //     }
  //   },
  //   {
  //     "empidHash": "empid_27",
  //     "demographicInfo": {
  //       "fullName": "Zachary Adams",
  //       "schoolName": "School of Science",
  //       "departmentName": "Physics",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://zacharyadams.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 45,
  //       "scientificImpact": 92.8,
  //       "productivity": 85.9,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 205,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.6,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.5,
  //       "science_citations_C5": 66
  //     }
  //   },
  //   {
  //     "empidHash": "empid_28",
  //     "demographicInfo": {
  //       "fullName": "Aaron Blake",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Computer Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://aaronblake.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 46,
  //       "scientificImpact": 93.4,
  //       "productivity": 86.7,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 155,
  //       "numInventions": 6,
  //       "numPapers": 210,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.7,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 8.9,
  //       "science_citations_C5": 69
  //     }
  //   },
  //   {
  //     "empidHash": "empid_29",
  //     "demographicInfo": {
  //       "fullName": "Beth Carter",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Cardiology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://bethcarter.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 38,
  //       "scientificImpact": 88.3,
  //       "productivity": 81.4,
  //       "numPapersCitedByPatents": 20,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.9,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.6,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_30",
  //     "demographicInfo": {
  //       "fullName": "Charlie Davis",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Literature",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://charliedavis.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 24,
  //       "scientificImpact": 75.0,
  //       "productivity": 68.3,
  //       "numPapersCitedByPatents": 11,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 95,
  //       "numInventions": 3,
  //       "numPapers": 145,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 2.9,
  //       "num_high_predicted_score_paper": 4,
  //       "pIndex": 6.4,
  //       "science_citations_C5": 47
  //     }
  //   },
  //   {
  //     "empidHash": "empid_31",
  //     "demographicInfo": {
  //       "fullName": "Diana Evans",
  //       "schoolName": "School of Science",
  //       "departmentName": "Biochemistry",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://dianaevans.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 53,
  //       "scientificImpact": 96.5,
  //       "productivity": 91.2,
  //       "numPapersCitedByPatents": 31,
  //       "numPatentsCitingAuthorPapers": 16,
  //       "numPatentCitations": 170,
  //       "numInventions": 7,
  //       "numPapers": 230,
  //       "numPatents": 14,
  //       "mean_Cf_L1": 5.3,
  //       "num_high_predicted_score_paper": 9,
  //       "pIndex": 9.6,
  //       "science_citations_C5": 74
  //     }
  //   },
  //   {
  //     "empidHash": "empid_32",
  //     "demographicInfo": {
  //       "fullName": "Edward Fisher",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Electrical Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://edwardfisher.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 44,
  //       "scientificImpact": 92.4,
  //       "productivity": 86.1,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 215,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.7,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.7,
  //       "science_citations_C5": 68
  //     }
  //   },
  //   {
  //     "empidHash": "empid_33",
  //     "demographicInfo": {
  //       "fullName": "Fiona Green",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Neurology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://fionagreen.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 40,
  //       "scientificImpact": 89.2,
  //       "productivity": 82.4,
  //       "numPapersCitedByPatents": 23,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 140,
  //       "numInventions": 5,
  //       "numPapers": 185,
  //       "numPatents": 9,
  //       "mean_Cf_L1": 4.1,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 8.2,
  //       "science_citations_C5": 61
  //     }
  //   },
  //   {
  //     "empidHash": "empid_34",
  //     "demographicInfo": {
  //       "fullName": "George Harris",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Philosophy",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://georgeharris.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 28,
  //       "scientificImpact": 78.1,
  //       "productivity": 71.3,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 6,
  //       "mean_Cf_L1": 3.2,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.0,
  //       "science_citations_C5": 51
  //     }
  //   },
  //   {
  //     "empidHash": "empid_35",
  //     "demographicInfo": {
  //       "fullName": "Hannah Irwin",
  //       "schoolName": "School of Science",
  //       "departmentName": "Mathematics",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://hannahirwin.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 56,
  //       "scientificImpact": 97.9,
  //       "productivity": 93.1,
  //       "numPapersCitedByPatents": 33,
  //       "numPatentsCitingAuthorPapers": 18,
  //       "numPatentCitations": 170,
  //       "numInventions": 8,
  //       "numPapers": 235,
  //       "numPatents": 14,
  //       "mean_Cf_L1": 5.5,
  //       "num_high_predicted_score_paper": 10,
  //       "pIndex": 9.8,
  //       "science_citations_C5": 76
  //     }
  //   },
  //   {
  //     "empidHash": "empid_36",
  //     "demographicInfo": {
  //       "fullName": "Isabel Jones",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Chemical Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://isabeljones.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 47,
  //       "scientificImpact": 94.1,
  //       "productivity": 87.0,
  //       "numPapersCitedByPatents": 28,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 155,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.8,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.1,
  //       "science_citations_C5": 71
  //     }
  //   },
  //   {
  //     "empidHash": "empid_37",
  //     "demographicInfo": {
  //       "fullName": "Jack Kelly",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Oncology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://jackkelly.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 39,
  //       "scientificImpact": 87.6,
  //       "productivity": 81.3,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.8,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.7,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_38",
  //     "demographicInfo": {
  //       "fullName": "Karen Lee",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Economics",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://karenlee.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 28,
  //       "scientificImpact": 79.2,
  //       "productivity": 71.2,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.2,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.0,
  //       "science_citations_C5": 50
  //     }
  //   },
  //   {
  //     "empidHash": "empid_39",
  //     "demographicInfo": {
  //       "fullName": "Larry Martinez",
  //       "schoolName": "School of Science",
  //       "departmentName": "Astronomy",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://larrymartinez.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 42,
  //       "scientificImpact": 90.8,
  //       "productivity": 83.7,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 145,
  //       "numInventions": 5,
  //       "numPapers": 200,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.4,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.4,
  //       "science_citations_C5": 65
  //     }
  //   },
  //   {
  //     "empidHash": "empid_40",
  //     "demographicInfo": {
  //       "fullName": "Megan Nelson",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Mechanical Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://megannelson.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 50,
  //       "scientificImpact": 94.3,
  //       "productivity": 87.9,
  //       "numPapersCitedByPatents": 29,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 5.0,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.0,
  //       "science_citations_C5": 72
  //     }
  //   },
  //   {
  //     "empidHash": "empid_41",
  //     "demographicInfo": {
  //       "fullName": "Nathan Ortiz",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Neurology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://nathanortiz.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 37,
  //       "scientificImpact": 87.7,
  //       "productivity": 81.4,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.8,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.7,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_42",
  //     "demographicInfo": {
  //       "fullName": "Olivia Parker",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Political Science",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://oliviaparker.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 26,
  //       "scientificImpact": 76.7,
  //       "productivity": 69.9,
  //       "numPapersCitedByPatents": 12,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 100,
  //       "numInventions": 3,
  //       "numPapers": 150,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.1,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.7,
  //       "science_citations_C5": 49
  //     }
  //   },
  //   {
  //     "empidHash": "empid_43",
  //     "demographicInfo": {
  //       "fullName": "Patrick Quinn",
  //       "schoolName": "School of Science",
  //       "departmentName": "Physics",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://patrickquinn.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 45,
  //       "scientificImpact": 92.8,
  //       "productivity": 85.9,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 205,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.6,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.5,
  //       "science_citations_C5": 66
  //     }
  //   },
  //   {
  //     "empidHash": "empid_44",
  //     "demographicInfo": {
  //       "fullName": "Quincy Rogers",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Computer Science",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://quincyrogers.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 48,
  //       "scientificImpact": 93.7,
  //       "productivity": 87.5,
  //       "numPapersCitedByPatents": 28,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 12,
  //       "mean_Cf_L1": 4.9,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.1,
  //       "science_citations_C5": 71
  //     }
  //   },
  //   {
  //     "empidHash": "empid_45",
  //     "demographicInfo": {
  //       "fullName": "Rachel Scott",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Pathology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://rachelscott.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 34,
  //       "scientificImpact": 88.7,
  //       "productivity": 81.8,
  //       "numPapersCitedByPatents": 20,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 4.0,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.9,
  //       "science_citations_C5": 56
  //     }
  //   },
  //   {
  //     "empidHash": "empid_46",
  //     "demographicInfo": {
  //       "fullName": "Steve Thompson",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Art History",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://stevethompson.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 25,
  //       "scientificImpact": 75.3,
  //       "productivity": 68.6,
  //       "numPapersCitedByPatents": 11,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 95,
  //       "numInventions": 3,
  //       "numPapers": 145,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 2.9,
  //       "num_high_predicted_score_paper": 4,
  //       "pIndex": 6.5,
  //       "science_citations_C5": 48
  //     }
  //   },
  //   {
  //     "empidHash": "empid_47",
  //     "demographicInfo": {
  //       "fullName": "Tina Underwood",
  //       "schoolName": "School of Science",
  //       "departmentName": "Geology",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://tinaunderwood.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 43,
  //       "scientificImpact": 90.9,
  //       "productivity": 83.8,
  //       "numPapersCitedByPatents": 25,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 145,
  //       "numInventions": 5,
  //       "numPapers": 195,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.4,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.3,
  //       "science_citations_C5": 63
  //     }
  //   },
  //   {
  //     "empidHash": "empid_48",
  //     "demographicInfo": {
  //       "fullName": "Uma Vasquez",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Industrial Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://umavasquez.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 50,
  //       "scientificImpact": 94.2,
  //       "productivity": 87.8,
  //       "numPapersCitedByPatents": 29,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 5.0,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.0,
  //       "science_citations_C5": 72
  //     }
  //   },
  //   {
  //     "empidHash": "empid_49",
  //     "demographicInfo": {
  //       "fullName": "Victor Williams",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Radiology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://victorwilliams.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 36,
  //       "scientificImpact": 88.0,
  //       "productivity": 80.7,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 175,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.9,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.6,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_50",
  //     "demographicInfo": {
  //       "fullName": "Wendy Xavier",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Political Science",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://wendyxavier.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 27,
  //       "scientificImpact": 77.0,
  //       "productivity": 70.1,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.3,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.8,
  //       "science_citations_C5": 50
  //     }
  //   },
  //   {
  //     "empidHash": "empid_51",
  //     "demographicInfo": {
  //       "fullName": "Xander Young",
  //       "schoolName": "School of Science",
  //       "departmentName": "Physics",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://xanderyoung.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 45,
  //       "scientificImpact": 92.8,
  //       "productivity": 85.9,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 205,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.6,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.5,
  //       "science_citations_C5": 66
  //     }
  //   },
  //   {
  //     "empidHash": "empid_52",
  //     "demographicInfo": {
  //       "fullName": "Yasmine Zane",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Computer Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://yasminezane.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 46,
  //       "scientificImpact": 93.4,
  //       "productivity": 86.7,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 155,
  //       "numInventions": 6,
  //       "numPapers": 210,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.7,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 8.9,
  //       "science_citations_C5": 69
  //     }
  //   },
  //   {
  //     "empidHash": "empid_53",
  //     "demographicInfo": {
  //       "fullName": "Zachary Adams",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Cardiology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://zacharyadams.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 38,
  //       "scientificImpact": 88.3,
  //       "productivity": 81.4,
  //       "numPapersCitedByPatents": 20,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.9,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.6,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_54",
  //     "demographicInfo": {
  //       "fullName": "Aaron Blake",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Literature",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://aaronblake.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 24,
  //       "scientificImpact": 75.0,
  //       "productivity": 68.3,
  //       "numPapersCitedByPatents": 11,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 95,
  //       "numInventions": 3,
  //       "numPapers": 145,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 2.9,
  //       "num_high_predicted_score_paper": 4,
  //       "pIndex": 6.4,
  //       "science_citations_C5": 47
  //     }
  //   },
  //   {
  //     "empidHash": "empid_55",
  //     "demographicInfo": {
  //       "fullName": "Beth Carter",
  //       "schoolName": "School of Science",
  //       "departmentName": "Biochemistry",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://bethcarter.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 53,
  //       "scientificImpact": 96.5,
  //       "productivity": 91.2,
  //       "numPapersCitedByPatents": 31,
  //       "numPatentsCitingAuthorPapers": 16,
  //       "numPatentCitations": 170,
  //       "numInventions": 7,
  //       "numPapers": 230,
  //       "numPatents": 14,
  //       "mean_Cf_L1": 5.3,
  //       "num_high_predicted_score_paper": 9,
  //       "pIndex": 9.6,
  //       "science_citations_C5": 74
  //     }
  //   },
  //   {
  //     "empidHash": "empid_56",
  //     "demographicInfo": {
  //       "fullName": "Charlie Davis",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Electrical Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://charliedavis.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 44,
  //       "scientificImpact": 92.4,
  //       "productivity": 86.1,
  //       "numPapersCitedByPatents": 27,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 150,
  //       "numInventions": 5,
  //       "numPapers": 215,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.7,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.7,
  //       "science_citations_C5": 68
  //     }
  //   },
  //   {
  //     "empidHash": "empid_57",
  //     "demographicInfo": {
  //       "fullName": "Diana Evans",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Neurology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://dianaevans.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 40,
  //       "scientificImpact": 89.2,
  //       "productivity": 82.4,
  //       "numPapersCitedByPatents": 23,
  //       "numPatentsCitingAuthorPapers": 12,
  //       "numPatentCitations": 140,
  //       "numInventions": 5,
  //       "numPapers": 185,
  //       "numPatents": 9,
  //       "mean_Cf_L1": 4.1,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 8.2,
  //       "science_citations_C5": 61
  //     }
  //   },
  //   {
  //     "empidHash": "empid_58",
  //     "demographicInfo": {
  //       "fullName": "Edward Fisher",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Philosophy",
  //       "gender": "Male",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://edwardfisher.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 28,
  //       "scientificImpact": 78.1,
  //       "productivity": 71.3,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 6,
  //       "mean_Cf_L1": 3.2,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.0,
  //       "science_citations_C5": 51
  //     }
  //   },
  //   {
  //     "empidHash": "empid_59",
  //     "demographicInfo": {
  //       "fullName": "Fiona Green",
  //       "schoolName": "School of Science",
  //       "departmentName": "Mathematics",
  //       "gender": "Female",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://fionagreen.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 56,
  //       "scientificImpact": 97.9,
  //       "productivity": 93.1,
  //       "numPapersCitedByPatents": 33,
  //       "numPatentsCitingAuthorPapers": 18,
  //       "numPatentCitations": 170,
  //       "numInventions": 8,
  //       "numPapers": 235,
  //       "numPatents": 14,
  //       "mean_Cf_L1": 5.5,
  //       "num_high_predicted_score_paper": 10,
  //       "pIndex": 9.8,
  //       "science_citations_C5": 76
  //     }
  //   },
  //   {
  //     "empidHash": "empid_60",
  //     "demographicInfo": {
  //       "fullName": "George Harris",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Chemical Engineering",
  //       "gender": "Male",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://georgeharris.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "Australia",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 47,
  //       "scientificImpact": 94.1,
  //       "productivity": 87.0,
  //       "numPapersCitedByPatents": 28,
  //       "numPatentsCitingAuthorPapers": 14,
  //       "numPatentCitations": 155,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 4.8,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.1,
  //       "science_citations_C5": 71
  //     }
  //   },
  //   {
  //     "empidHash": "empid_61",
  //     "demographicInfo": {
  //       "fullName": "Hannah Irwin",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Oncology",
  //       "gender": "Female",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://hannahirwin.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 39,
  //       "scientificImpact": 87.6,
  //       "productivity": 81.3,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.8,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.7,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_62",
  //     "demographicInfo": {
  //       "fullName": "Isabel Jones",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Economics",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://isabeljones.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 28,
  //       "scientificImpact": 79.2,
  //       "productivity": 71.2,
  //       "numPapersCitedByPatents": 13,
  //       "numPatentsCitingAuthorPapers": 7,
  //       "numPatentCitations": 105,
  //       "numInventions": 3,
  //       "numPapers": 155,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.2,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 7.0,
  //       "science_citations_C5": 50
  //     }
  //   },
  //   {
  //     "empidHash": "empid_63",
  //     "demographicInfo": {
  //       "fullName": "Jack Kelly",
  //       "schoolName": "School of Science",
  //       "departmentName": "Astronomy",
  //       "gender": "Male",
  //       "jobTitle": "Research Scientist",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://jackkelly.com",
  //       "affiliationName": "Research Institute",
  //       "affiliationCountry": "Germany",
  //       "affiliationType": "Research"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 42,
  //       "scientificImpact": 90.8,
  //       "productivity": 83.7,
  //       "numPapersCitedByPatents": 26,
  //       "numPatentsCitingAuthorPapers": 13,
  //       "numPatentCitations": 145,
  //       "numInventions": 5,
  //       "numPapers": 200,
  //       "numPatents": 10,
  //       "mean_Cf_L1": 4.4,
  //       "num_high_predicted_score_paper": 7,
  //       "pIndex": 8.4,
  //       "science_citations_C5": 65
  //     }
  //   },
  //   {
  //     "empidHash": "empid_64",
  //     "demographicInfo": {
  //       "fullName": "Karen Lee",
  //       "schoolName": "School of Engineering",
  //       "departmentName": "Mechanical Engineering",
  //       "gender": "Female",
  //       "jobTitle": "Professor",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://karenlee.com",
  //       "affiliationName": "Tech University",
  //       "affiliationCountry": "USA",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 50,
  //       "scientificImpact": 94.3,
  //       "productivity": 87.9,
  //       "numPapersCitedByPatents": 29,
  //       "numPatentsCitingAuthorPapers": 15,
  //       "numPatentCitations": 160,
  //       "numInventions": 6,
  //       "numPapers": 220,
  //       "numPatents": 11,
  //       "mean_Cf_L1": 5.0,
  //       "num_high_predicted_score_paper": 8,
  //       "pIndex": 9.0,
  //       "science_citations_C5": 72
  //     }
  //   },
  //   {
  //     "empidHash": "empid_65",
  //     "demographicInfo": {
  //       "fullName": "Larry Martinez",
  //       "schoolName": "School of Medicine",
  //       "departmentName": "Neurology",
  //       "gender": "Male",
  //       "jobTitle": "Associate Professor",
  //       "tenureStatus": "Tenure Eligible",
  //       "weblink": "https://larrymartinez.com",
  //       "affiliationName": "Medical University",
  //       "affiliationCountry": "UK",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 37,
  //       "scientificImpact": 87.7,
  //       "productivity": 81.4,
  //       "numPapersCitedByPatents": 21,
  //       "numPatentsCitingAuthorPapers": 11,
  //       "numPatentCitations": 135,
  //       "numInventions": 4,
  //       "numPapers": 180,
  //       "numPatents": 8,
  //       "mean_Cf_L1": 3.8,
  //       "num_high_predicted_score_paper": 6,
  //       "pIndex": 7.7,
  //       "science_citations_C5": 55
  //     }
  //   },
  //   {
  //     "empidHash": "empid_66",
  //     "demographicInfo": {
  //       "fullName": "Megan Nelson",
  //       "schoolName": "School of Arts",
  //       "departmentName": "Political Science",
  //       "gender": "Female",
  //       "jobTitle": "Lecturer",
  //       "tenureStatus": "Attained",
  //       "weblink": "https://megannelson.com",
  //       "affiliationName": "Liberal Arts College",
  //       "affiliationCountry": "Canada",
  //       "affiliationType": "Academic"
  //     },
  //     "scisciIndex": {
  //       "hIndex": 26,
  //       "scientificImpact": 76.7,
  //       "productivity": 69.9,
  //       "numPapersCitedByPatents": 12,
  //       "numPatentsCitingAuthorPapers": 6,
  //       "numPatentCitations": 100,
  //       "numInventions": 3,
  //       "numPapers": 150,
  //       "numPatents": 5,
  //       "mean_Cf_L1": 3.1,
  //       "num_high_predicted_score_paper": 5,
  //       "pIndex": 6.7,
  //       "science_citations_C5": 49
  //     }
  //   },
  // ],

  selectedPaperIdList: ['all'],
  paperRankByField: "predictionScore",
  paperRankOrder: "descending",
  searchPaperKeyword: "",
  paperCardListData: [], 
  paperCardSize: 0,
  // paperCardListData: [
  //   {'paper_mag_id': 'heiwc4kszj2tlgwl',
  //     'demographicInfo': {'paperTitle': 'Morning lawyer defense yourself health wind.',
  //     'paperYear': 2015,
  //     'authorsHash': '3jtfy2qez1k2gpqn',
  //     'authorsName': 'Caroline Walker',
  //     'paperMagName': 'skill Journal',
  //     'paperDoi': '10.77108306/h0ghg',
  //     'hyperlink': 'https://carrillo.com/main/'},
  //     'scisciIndex': {'scienceCitationsCall': 231,
  //     'scienceCitationsC5': 179,
  //     'predictionScore': 16.911471972070736,
  //     'disruption': -1.9196088085904224,
  //     'atyp10pctZ': 2.7225168527127446,
  //     'patentCitationsCall': 99,
  //     'patentCitationsC5': 79,
  //     'teamSize': 10,
  //     'institutionCount': 8,
  //     'tweetCount': 49,
  //     'nihCount': 11,
  //     'nsfCount': 11}},
  //   {'paper_mag_id': 'fzp1syu509lj2nqd',
  //     'demographicInfo': {'paperTitle': 'Movie more fear board car grow.',
  //     'paperYear': 2014,
  //     'authorsHash': 'd0gbbf56ug6a2f20',
  //     'authorsName': 'Sean Porter',
  //     'paperMagName': 'parent Journal',
  //     'paperDoi': '10.03696716/dde2s',
  //     'hyperlink': 'http://www.nichols.net/list/posts/about.php'},
  //     'scisciIndex': {'scienceCitationsCall': 440,
  //     'scienceCitationsC5': 64,
  //     'predictionScore': 12.308125038578554,
  //     'disruption': -1.1303132105043585,
  //     'atyp10pctZ': -2.570320621394364,
  //     'patentCitationsCall': 79,
  //     'patentCitationsC5': 5,
  //     'teamSize': 17,
  //     'institutionCount': 7,
  //     'tweetCount': 9,
  //     'nihCount': 29,
  //     'nsfCount': 32}},
  //   {'paper_mag_id': 'vp79sbpcl5sdh8bw',
  //     'demographicInfo': {'paperTitle': 'Sense these public weight nearly.',
  //     'paperYear': 2009,
  //     'authorsHash': '00gh6scuo0vutoqz',
  //     'authorsName': 'Robin Lopez',
  //     'paperMagName': 'cold Journal',
  //     'paperDoi': '10.67405643/cus6a',
  //     'hyperlink': 'http://www.foster.biz/wp-content/terms.html'},
  //     'scisciIndex': {'scienceCitationsCall': 74,
  //     'scienceCitationsC5': 272,
  //     'predictionScore': 37.611460243681194,
  //     'disruption': 1.6164275425470698,
  //     'atyp10pctZ': 2.8074848908413106,
  //     'patentCitationsCall': 119,
  //     'patentCitationsC5': 180,
  //     'teamSize': 2,
  //     'institutionCount': 9,
  //     'tweetCount': 49,
  //     'nihCount': 18,
  //     'nsfCount': 37}},
  //   {'paper_mag_id': '3f2wco841tvj5bgs',
  //     'demographicInfo': {'paperTitle': 'Central stay anything inside.',
  //     'paperYear': 2013,
  //     'authorsHash': 'hy92ii7gewlcjt59',
  //     'authorsName': 'Danielle Frost',
  //     'paperMagName': 'Mrs Journal',
  //     'paperDoi': '10.53398537/husr2',
  //     'hyperlink': 'https://nguyen-watson.com/list/register/'},
  //     'scisciIndex': {'scienceCitationsCall': 263,
  //     'scienceCitationsC5': 499,
  //     'predictionScore': 52.11653038164879,
  //     'disruption': -1.2114585812682779,
  //     'atyp10pctZ': 2.097333457319052,
  //     'patentCitationsCall': 165,
  //     'patentCitationsC5': 152,
  //     'teamSize': 11,
  //     'institutionCount': 8,
  //     'tweetCount': 46,
  //     'nihCount': 49,
  //     'nsfCount': 19}},
  //   {'paper_mag_id': 'ccoc7tp4i67ips27',
  //     'demographicInfo': {'paperTitle': 'Somebody himself head real lose suddenly.',
  //     'paperYear': 2016,
  //     'authorsHash': '6jefx4ldxaw9lyjd',
  //     'authorsName': 'Elizabeth Wagner',
  //     'paperMagName': 'Mr Journal',
  //     'paperDoi': '10.93652399/jd322',
  //     'hyperlink': 'http://www.ferguson.com/explore/register.html'},
  //     'scisciIndex': {'scienceCitationsCall': 163,
  //     'scienceCitationsC5': 120,
  //     'predictionScore': 2.6427173087715694,
  //     'disruption': -1.6379078378590073,
  //     'atyp10pctZ': -0.006683846325389986,
  //     'patentCitationsCall': 93,
  //     'patentCitationsC5': 200,
  //     'teamSize': 15,
  //     'institutionCount': 1,
  //     'tweetCount': 198,
  //     'nihCount': 41,
  //     'nsfCount': 15}},
  //   {'paper_mag_id': '85x1nj3lzygppyl3',
  //     'demographicInfo': {'paperTitle': 'Behavior board present attention door.',
  //     'paperYear': 2010,
  //     'authorsHash': 'fyd2dwl3j38hxpo8',
  //     'authorsName': 'Danielle Sanders',
  //     'paperMagName': 'across Journal',
  //     'paperDoi': '10.29570179/abetg',
  //     'hyperlink': 'http://lane-moran.com/author.php'},
  //     'scisciIndex': {'scienceCitationsCall': 146,
  //     'scienceCitationsC5': 462,
  //     'predictionScore': 47.60097659815328,
  //     'disruption': 1.2630560333279321,
  //     'atyp10pctZ': 2.741848353229643,
  //     'patentCitationsCall': 137,
  //     'patentCitationsC5': 21,
  //     'teamSize': 1,
  //     'institutionCount': 4,
  //     'tweetCount': 84,
  //     'nihCount': 16,
  //     'nsfCount': 2}},
  //   {'paper_mag_id': 'sw7c9ra6nk9kfx0v',
  //     'demographicInfo': {'paperTitle': 'Eat stay security modern common traditional.',
  //     'paperYear': 2004,
  //     'authorsHash': 'ta8p05849wv621r6',
  //     'authorsName': 'Melissa Sandoval',
  //     'paperMagName': 'thus Journal',
  //     'paperDoi': '10.87817811/2adrx',
  //     'hyperlink': 'http://www.schmidt.com/main.jsp'},
  //     'scisciIndex': {'scienceCitationsCall': 131,
  //     'scienceCitationsC5': 290,
  //     'predictionScore': 54.62361916082574,
  //     'disruption': 0.38916001803493705,
  //     'atyp10pctZ': 1.1836872806522063,
  //     'patentCitationsCall': 210,
  //     'patentCitationsC5': 179,
  //     'teamSize': 5,
  //     'institutionCount': 8,
  //     'tweetCount': 72,
  //     'nihCount': 10,
  //     'nsfCount': 12}},
  //   {'paper_mag_id': '37u7bl7cfkblf29e',
  //     'demographicInfo': {'paperTitle': 'Culture strategy foreign under.',
  //     'paperYear': 1997,
  //     'authorsHash': 'q2xqtl9pcq551pn4',
  //     'authorsName': 'Cory Livingston',
  //     'paperMagName': 'choice Journal',
  //     'paperDoi': '10.61033697/u36ge',
  //     'hyperlink': 'https://murray.info/home/'},
  //     'scisciIndex': {'scienceCitationsCall': 7,
  //     'scienceCitationsC5': 489,
  //     'predictionScore': 36.032358787051166,
  //     'disruption': -0.5424479323249214,
  //     'atyp10pctZ': 1.5463370689630827,
  //     'patentCitationsCall': 48,
  //     'patentCitationsC5': 297,
  //     'teamSize': 2,
  //     'institutionCount': 1,
  //     'tweetCount': 152,
  //     'nihCount': 11,
  //     'nsfCount': 11}},
  //   {'paper_mag_id': 'eo56fcqt3f6uj8y7',
  //     'demographicInfo': {'paperTitle': 'Nature someone think say democratic nation author even.',
  //     'paperYear': 2010,
  //     'authorsHash': '4dqt9iym6iykd6hx',
  //     'authorsName': 'Timothy Barnett',
  //     'paperMagName': 'police Journal',
  //     'paperDoi': '10.18722439/0yb0a',
  //     'hyperlink': 'https://burgess.net/categories/search.htm'},
  //     'scisciIndex': {'scienceCitationsCall': 315,
  //     'scienceCitationsC5': 1,
  //     'predictionScore': 86.42309583416188,
  //     'disruption': 1.1980631003825422,
  //     'atyp10pctZ': 1.038719026114574,
  //     'patentCitationsCall': 265,
  //     'patentCitationsC5': 102,
  //     'teamSize': 3,
  //     'institutionCount': 7,
  //     'tweetCount': 169,
  //     'nihCount': 40,
  //     'nsfCount': 16}},
  //   {'paper_mag_id': 'j9csmqjq79y5jz3m',
  //     'demographicInfo': {'paperTitle': 'Whether stage term manager push support.',
  //     'paperYear': 1995,
  //     'authorsHash': 'zjf2u49xfqahd599',
  //     'authorsName': 'Savannah Hernandez',
  //     'paperMagName': 'live Journal',
  //     'paperDoi': '10.30464672/1y827',
  //     'hyperlink': 'https://www.williams.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 163,
  //     'scienceCitationsC5': 161,
  //     'predictionScore': 9.076165925571333,
  //     'disruption': -1.40031815527532,
  //     'atyp10pctZ': -1.681903383208085,
  //     'patentCitationsCall': 127,
  //     'patentCitationsC5': 242,
  //     'teamSize': 8,
  //     'institutionCount': 1,
  //     'tweetCount': 10,
  //     'nihCount': 28,
  //     'nsfCount': 9}},
  //   {'paper_mag_id': '20nd4l17p3ibr3qj',
  //     'demographicInfo': {'paperTitle': 'Official real chair here whom yourself minute.',
  //     'paperYear': 1990,
  //     'authorsHash': '0q33q0oqj9971qzd',
  //     'authorsName': 'Joseph Robinson',
  //     'paperMagName': 'anyone Journal',
  //     'paperDoi': '10.55106191/wxe7g',
  //     'hyperlink': 'http://www.liu.org/blog/posts/search/privacy.html'},
  //     'scisciIndex': {'scienceCitationsCall': 229,
  //     'scienceCitationsC5': 150,
  //     'predictionScore': 6.786419738621952,
  //     'disruption': 1.9288944486020752,
  //     'atyp10pctZ': 1.4761951251522625,
  //     'patentCitationsCall': 7,
  //     'patentCitationsC5': 107,
  //     'teamSize': 14,
  //     'institutionCount': 1,
  //     'tweetCount': 9,
  //     'nihCount': 33,
  //     'nsfCount': 23}},
  //   {'paper_mag_id': 'bknramiio44xphen',
  //     'demographicInfo': {'paperTitle': 'Field can environment and approach coach.',
  //     'paperYear': 2005,
  //     'authorsHash': 'pooq2ywsadaegu6t',
  //     'authorsName': 'Michael Wagner',
  //     'paperMagName': 'perhaps Journal',
  //     'paperDoi': '10.66196873/8jz6r',
  //     'hyperlink': 'http://freeman.com/register/'},
  //     'scisciIndex': {'scienceCitationsCall': 286,
  //     'scienceCitationsC5': 215,
  //     'predictionScore': 12.227583764005988,
  //     'disruption': -0.521194636754164,
  //     'atyp10pctZ': -0.08161524111510143,
  //     'patentCitationsCall': 53,
  //     'patentCitationsC5': 142,
  //     'teamSize': 8,
  //     'institutionCount': 7,
  //     'tweetCount': 72,
  //     'nihCount': 34,
  //     'nsfCount': 28}},
  //   {'paper_mag_id': 'xrug7i0zbj65toj6',
  //     'demographicInfo': {'paperTitle': 'Surface reflect discover.',
  //     'paperYear': 2017,
  //     'authorsHash': 'fjdy4n9zv4sujrf1',
  //     'authorsName': 'Gail Ray',
  //     'paperMagName': 'behavior Journal',
  //     'paperDoi': '10.78196711/3v5rw',
  //     'hyperlink': 'http://butler.com/author/'},
  //     'scisciIndex': {'scienceCitationsCall': 305,
  //     'scienceCitationsC5': 470,
  //     'predictionScore': 42.29348471032283,
  //     'disruption': 1.7248759336910302,
  //     'atyp10pctZ': 1.5663216674669025,
  //     'patentCitationsCall': 104,
  //     'patentCitationsC5': 136,
  //     'teamSize': 11,
  //     'institutionCount': 7,
  //     'tweetCount': 61,
  //     'nihCount': 19,
  //     'nsfCount': 19}},
  //   {'paper_mag_id': 'mbpj3uw65x47nfgg',
  //     'demographicInfo': {'paperTitle': 'Sea because issue particularly off reduce.',
  //     'paperYear': 1996,
  //     'authorsHash': 'iwzzianpz5ahtydh',
  //     'authorsName': 'William Swanson',
  //     'paperMagName': 'serve Journal',
  //     'paperDoi': '10.69078562/7rbdh',
  //     'hyperlink': 'http://www.murray.com/search/'},
  //     'scisciIndex': {'scienceCitationsCall': 8,
  //     'scienceCitationsC5': 107,
  //     'predictionScore': 28.70329010954188,
  //     'disruption': 1.044060007297679,
  //     'atyp10pctZ': 1.794406098612539,
  //     'patentCitationsCall': 13,
  //     'patentCitationsC5': 64,
  //     'teamSize': 6,
  //     'institutionCount': 10,
  //     'tweetCount': 96,
  //     'nihCount': 31,
  //     'nsfCount': 8}},
  //   {'paper_mag_id': '72uqwmmz5qqynzqf',
  //     'demographicInfo': {'paperTitle': 'Explain tend region suddenly.',
  //     'paperYear': 2021,
  //     'authorsHash': 'diutgwzijvxy64q6',
  //     'authorsName': 'Mandy Nielsen',
  //     'paperMagName': 'pattern Journal',
  //     'paperDoi': '10.14950053/3z2pe',
  //     'hyperlink': 'https://flores.biz/blog/privacy.php'},
  //     'scisciIndex': {'scienceCitationsCall': 56,
  //     'scienceCitationsC5': 363,
  //     'predictionScore': 25.811136150130288,
  //     'disruption': -0.8351109237739522,
  //     'atyp10pctZ': 1.1101882650294383,
  //     'patentCitationsCall': 174,
  //     'patentCitationsC5': 88,
  //     'teamSize': 8,
  //     'institutionCount': 4,
  //     'tweetCount': 140,
  //     'nihCount': 37,
  //     'nsfCount': 46}},
  //   {'paper_mag_id': 'zpv7t5nnt8kn0svx',
  //     'demographicInfo': {'paperTitle': 'Training old research baby.',
  //     'paperYear': 2019,
  //     'authorsHash': 'ac0hqixc5un036n1',
  //     'authorsName': 'Daniel Burns',
  //     'paperMagName': 'describe Journal',
  //     'paperDoi': '10.80422446/fb2rr',
  //     'hyperlink': 'http://www.jones-stone.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 492,
  //     'scienceCitationsC5': 401,
  //     'predictionScore': 49.250097061175005,
  //     'disruption': -1.7542679690888887,
  //     'atyp10pctZ': -0.3936355254332353,
  //     'patentCitationsCall': 10,
  //     'patentCitationsC5': 29,
  //     'teamSize': 4,
  //     'institutionCount': 6,
  //     'tweetCount': 8,
  //     'nihCount': 42,
  //     'nsfCount': 4}},
  //   {'paper_mag_id': 'ncg2ph542hl47t75',
  //     'demographicInfo': {'paperTitle': 'East study other task for people study.',
  //     'paperYear': 2011,
  //     'authorsHash': '2w8618sjrhts5d3k',
  //     'authorsName': 'Haley Hampton',
  //     'paperMagName': 'bill Journal',
  //     'paperDoi': '10.59537786/506r3',
  //     'hyperlink': 'http://kennedy-campbell.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 32,
  //     'scienceCitationsC5': 380,
  //     'predictionScore': 20.337933709950264,
  //     'disruption': -1.1754913217088694,
  //     'atyp10pctZ': -0.3312573558111107,
  //     'patentCitationsCall': 166,
  //     'patentCitationsC5': 131,
  //     'teamSize': 13,
  //     'institutionCount': 8,
  //     'tweetCount': 101,
  //     'nihCount': 11,
  //     'nsfCount': 23}},
  //   {'paper_mag_id': '2ycpo4udnwhyvpub',
  //     'demographicInfo': {'paperTitle': 'Act third physical always between.',
  //     'paperYear': 2018,
  //     'authorsHash': 'qowxek6c7sj8v01c',
  //     'authorsName': 'Rita Austin',
  //     'paperMagName': 'home Journal',
  //     'paperDoi': '10.08183713/kcwry',
  //     'hyperlink': 'https://www.carson.com/register/'},
  //     'scisciIndex': {'scienceCitationsCall': 141,
  //     'scienceCitationsC5': 352,
  //     'predictionScore': 69.59035319839508,
  //     'disruption': 0.0592081555884012,
  //     'atyp10pctZ': -0.47622496281519666,
  //     'patentCitationsCall': 126,
  //     'patentCitationsC5': 38,
  //     'teamSize': 10,
  //     'institutionCount': 8,
  //     'tweetCount': 85,
  //     'nihCount': 18,
  //     'nsfCount': 29}},
  //   {'paper_mag_id': 'kly3mv48vwekcxle',
  //     'demographicInfo': {'paperTitle': 'General may produce good third listen miss.',
  //     'paperYear': 1999,
  //     'authorsHash': '3mo9a5kdnpb0x6y4',
  //     'authorsName': 'Joshua Proctor',
  //     'paperMagName': 'share Journal',
  //     'paperDoi': '10.64744802/ko22t',
  //     'hyperlink': 'https://adams-brandt.biz/category/'},
  //     'scisciIndex': {'scienceCitationsCall': 74,
  //     'scienceCitationsC5': 367,
  //     'predictionScore': 32.29330994437794,
  //     'disruption': 0.49949379351750256,
  //     'atyp10pctZ': -1.514255943040222,
  //     'patentCitationsCall': 130,
  //     'patentCitationsC5': 78,
  //     'teamSize': 16,
  //     'institutionCount': 8,
  //     'tweetCount': 151,
  //     'nihCount': 2,
  //     'nsfCount': 36}},
  //   {'paper_mag_id': 'whtbks47jjt73jxx',
  //     'demographicInfo': {'paperTitle': 'Here as young into first another.',
  //     'paperYear': 1999,
  //     'authorsHash': '46q4bl6bxu77m5te',
  //     'authorsName': 'Mr. Kevin Davis',
  //     'paperMagName': 'when Journal',
  //     'paperDoi': '10.15607767/jp7z2',
  //     'hyperlink': 'https://green.biz/'},
  //     'scisciIndex': {'scienceCitationsCall': 294,
  //     'scienceCitationsC5': 8,
  //     'predictionScore': 36.69948049787093,
  //     'disruption': -0.2908827640438254,
  //     'atyp10pctZ': -0.570203845447228,
  //     'patentCitationsCall': 198,
  //     'patentCitationsC5': 87,
  //     'teamSize': 17,
  //     'institutionCount': 10,
  //     'tweetCount': 160,
  //     'nihCount': 5,
  //     'nsfCount': 3}},
  //   {'paper_mag_id': 'im069str5mgmvuqq',
  //     'demographicInfo': {'paperTitle': 'Institution garden issue generation recent feeling first.',
  //     'paperYear': 2011,
  //     'authorsHash': 'dl1ulxqyto18srwh',
  //     'authorsName': 'Pamela Bartlett',
  //     'paperMagName': 'ahead Journal',
  //     'paperDoi': '10.37585846/zoc89',
  //     'hyperlink': 'http://kim.org/category/wp-content/search/terms/'},
  //     'scisciIndex': {'scienceCitationsCall': 266,
  //     'scienceCitationsC5': 382,
  //     'predictionScore': 10.470778801450875,
  //     'disruption': 1.938743931732703,
  //     'atyp10pctZ': 1.9593247069473785,
  //     'patentCitationsCall': 110,
  //     'patentCitationsC5': 207,
  //     'teamSize': 2,
  //     'institutionCount': 10,
  //     'tweetCount': 9,
  //     'nihCount': 40,
  //     'nsfCount': 20}},
  //   {'paper_mag_id': '8w6p928pfs3azwkd',
  //     'demographicInfo': {'paperTitle': 'Color it I miss scientist.',
  //     'paperYear': 2003,
  //     'authorsHash': 'vdqihuupi7yehi3n',
  //     'authorsName': 'Sydney Pierce',
  //     'paperMagName': 'factor Journal',
  //     'paperDoi': '10.09889210/b9yiw',
  //     'hyperlink': 'https://www.hernandez.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 221,
  //     'scienceCitationsC5': 399,
  //     'predictionScore': 66.75112070003722,
  //     'disruption': -0.1804795730000306,
  //     'atyp10pctZ': 2.663128615553183,
  //     'patentCitationsCall': 272,
  //     'patentCitationsC5': 66,
  //     'teamSize': 20,
  //     'institutionCount': 1,
  //     'tweetCount': 40,
  //     'nihCount': 34,
  //     'nsfCount': 0}},
  //   {'paper_mag_id': 'i6mkcaukbx8usc2a',
  //     'demographicInfo': {'paperTitle': 'Blue radio suddenly material collection government great.',
  //     'paperYear': 2010,
  //     'authorsHash': 'cg5y3khxz7wi26de',
  //     'authorsName': 'Sarah Nelson',
  //     'paperMagName': 'not Journal',
  //     'paperDoi': '10.15401432/2uvas',
  //     'hyperlink': 'http://fox.info/home/'},
  //     'scisciIndex': {'scienceCitationsCall': 222,
  //     'scienceCitationsC5': 415,
  //     'predictionScore': 72.85330368213427,
  //     'disruption': -1.9596020345742136,
  //     'atyp10pctZ': 1.196270087130487,
  //     'patentCitationsCall': 54,
  //     'patentCitationsC5': 84,
  //     'teamSize': 16,
  //     'institutionCount': 9,
  //     'tweetCount': 6,
  //     'nihCount': 29,
  //     'nsfCount': 38}},
  //   {'paper_mag_id': 'lduj6bedn92u04h4',
  //     'demographicInfo': {'paperTitle': 'Could author onto book color bill.',
  //     'paperYear': 1999,
  //     'authorsHash': '1ipeez0r9srahwle',
  //     'authorsName': 'Michael Allen',
  //     'paperMagName': 'exist Journal',
  //     'paperDoi': '10.68825037/5klsv',
  //     'hyperlink': 'http://hernandez.info/category/main/author/'},
  //     'scisciIndex': {'scienceCitationsCall': 144,
  //     'scienceCitationsC5': 239,
  //     'predictionScore': 46.400969476204935,
  //     'disruption': -0.31610796952702414,
  //     'atyp10pctZ': 1.2441848797547888,
  //     'patentCitationsCall': 128,
  //     'patentCitationsC5': 146,
  //     'teamSize': 9,
  //     'institutionCount': 5,
  //     'tweetCount': 43,
  //     'nihCount': 0,
  //     'nsfCount': 26}},
  //   {'paper_mag_id': 't0etsyap5hdlqp1t',
  //     'demographicInfo': {'paperTitle': 'So anyone main fire key they factor.',
  //     'paperYear': 1990,
  //     'authorsHash': '1ywklap308cna6o6',
  //     'authorsName': 'Lorraine Parker',
  //     'paperMagName': 'return Journal',
  //     'paperDoi': '10.51067635/7u4vp',
  //     'hyperlink': 'https://www.ortiz-wilcox.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 51,
  //     'scienceCitationsC5': 373,
  //     'predictionScore': 13.348868831729598,
  //     'disruption': 0.42501917153865154,
  //     'atyp10pctZ': 2.925930452490954,
  //     'patentCitationsCall': 52,
  //     'patentCitationsC5': 269,
  //     'teamSize': 7,
  //     'institutionCount': 7,
  //     'tweetCount': 159,
  //     'nihCount': 18,
  //     'nsfCount': 36}},
  //   {'paper_mag_id': 'w48sq6ub0n9qpetd',
  //     'demographicInfo': {'paperTitle': 'Area impact only position.',
  //     'paperYear': 2001,
  //     'authorsHash': '54bn0q2mi728mccj',
  //     'authorsName': 'Christopher Whitehead',
  //     'paperMagName': 'friend Journal',
  //     'paperDoi': '10.19181733/b2s9r',
  //     'hyperlink': 'http://garrett.net/'},
  //     'scisciIndex': {'scienceCitationsCall': 161,
  //     'scienceCitationsC5': 286,
  //     'predictionScore': 73.28539845142203,
  //     'disruption': -0.8983288932271605,
  //     'atyp10pctZ': 1.0797395075228824,
  //     'patentCitationsCall': 197,
  //     'patentCitationsC5': 285,
  //     'teamSize': 11,
  //     'institutionCount': 1,
  //     'tweetCount': 44,
  //     'nihCount': 13,
  //     'nsfCount': 19}},
  //   {'paper_mag_id': '1wdnfdefr2ptbm78',
  //     'demographicInfo': {'paperTitle': 'She woman book brother since game.',
  //     'paperYear': 2022,
  //     'authorsHash': 'stnarw0woth7v7px',
  //     'authorsName': 'Courtney Grimes',
  //     'paperMagName': 'position Journal',
  //     'paperDoi': '10.92781999/uu2m7',
  //     'hyperlink': 'http://www.chung.com/search/'},
  //     'scisciIndex': {'scienceCitationsCall': 227,
  //     'scienceCitationsC5': 58,
  //     'predictionScore': 55.30785940148418,
  //     'disruption': 0.9909424813973131,
  //     'atyp10pctZ': 2.5334175635782987,
  //     'patentCitationsCall': 75,
  //     'patentCitationsC5': 164,
  //     'teamSize': 6,
  //     'institutionCount': 3,
  //     'tweetCount': 180,
  //     'nihCount': 5,
  //     'nsfCount': 21}},
  //   {'paper_mag_id': 'mewmg2gjmjn04mof',
  //     'demographicInfo': {'paperTitle': 'Decade opportunity wrong none marriage resource late.',
  //     'paperYear': 1991,
  //     'authorsHash': 'vlw358q639v7cclp',
  //     'authorsName': 'Danielle Phillips',
  //     'paperMagName': 'seek Journal',
  //     'paperDoi': '10.04965804/6dcc3',
  //     'hyperlink': 'https://www.banks-webb.com/index.html'},
  //     'scisciIndex': {'scienceCitationsCall': 111,
  //     'scienceCitationsC5': 459,
  //     'predictionScore': 59.55914099183379,
  //     'disruption': 0.7626119850755804,
  //     'atyp10pctZ': -2.2240277537056183,
  //     'patentCitationsCall': 290,
  //     'patentCitationsC5': 245,
  //     'teamSize': 4,
  //     'institutionCount': 1,
  //     'tweetCount': 31,
  //     'nihCount': 50,
  //     'nsfCount': 38}}
  //   ],

  selectedResearcherId: null,
  selectedAuthorId: "",
  //selectedResearcherPaperList: [],
  selectedResearcherPaperList: [],
  selectedResearcherPaperPatentList: [],
    // selectedResearcherPaperList: [
  //   {'paper_mag_id': 'heiwc4kszj2tlgwl',
  //     'demographicInfo': {'paperTitle': 'Morning lawyer defense yourself health wind.',
  //     'paperYear': 2015,
  //     'authorsHash': '3jtfy2qez1k2gpqn',
  //     'authorsName': 'Caroline Walker',
  //     'paperMagName': 'skill Journal',
  //     'paperDoi': '10.77108306/h0ghg',
  //     'hyperlink': 'https://carrillo.com/main/'},
  //     'scisciIndex': {'scienceCitationsCall': 231,
  //     'scienceCitationsC5': 179,
  //     'predictionScore': 16.911471972070736,
  //     'disruption': -1.9196088085904224,
  //     'atyp10pctZ': 2.7225168527127446,
  //     'patentCitationsCall': 99,
  //     'patentCitationsC5': 79,
  //     'teamSize': 10,
  //     'institutionCount': 8,
  //     'tweetCount': 49,
  //     'nihCount': 11,
  //     'nsfCount': 11}},
  //   {'paper_mag_id': 'fzp1syu509lj2nqd',
  //     'demographicInfo': {'paperTitle': 'Movie more fear board car grow.',
  //     'paperYear': 2014,
  //     'authorsHash': 'd0gbbf56ug6a2f20',
  //     'authorsName': 'Sean Porter',
  //     'paperMagName': 'parent Journal',
  //     'paperDoi': '10.03696716/dde2s',
  //     'hyperlink': 'http://www.nichols.net/list/posts/about.php'},
  //     'scisciIndex': {'scienceCitationsCall': 440,
  //     'scienceCitationsC5': 64,
  //     'predictionScore': 12.308125038578554,
  //     'disruption': -1.1303132105043585,
  //     'atyp10pctZ': -2.570320621394364,
  //     'patentCitationsCall': 79,
  //     'patentCitationsC5': 5,
  //     'teamSize': 17,
  //     'institutionCount': 7,
  //     'tweetCount': 9,
  //     'nihCount': 29,
  //     'nsfCount': 32}},
  //   {'paper_mag_id': 'vp79sbpcl5sdh8bw',
  //     'demographicInfo': {'paperTitle': 'Sense these public weight nearly.',
  //     'paperYear': 2009,
  //     'authorsHash': '00gh6scuo0vutoqz',
  //     'authorsName': 'Robin Lopez',
  //     'paperMagName': 'cold Journal',
  //     'paperDoi': '10.67405643/cus6a',
  //     'hyperlink': 'http://www.foster.biz/wp-content/terms.html'},
  //     'scisciIndex': {'scienceCitationsCall': 74,
  //     'scienceCitationsC5': 272,
  //     'predictionScore': 37.611460243681194,
  //     'disruption': 1.6164275425470698,
  //     'atyp10pctZ': 2.8074848908413106,
  //     'patentCitationsCall': 119,
  //     'patentCitationsC5': 180,
  //     'teamSize': 2,
  //     'institutionCount': 9,
  //     'tweetCount': 49,
  //     'nihCount': 18,
  //     'nsfCount': 37}},
  //   {'paper_mag_id': '3f2wco841tvj5bgs',
  //     'demographicInfo': {'paperTitle': 'Central stay anything inside.',
  //     'paperYear': 2013,
  //     'authorsHash': 'hy92ii7gewlcjt59',
  //     'authorsName': 'Danielle Frost',
  //     'paperMagName': 'Mrs Journal',
  //     'paperDoi': '10.53398537/husr2',
  //     'hyperlink': 'https://nguyen-watson.com/list/register/'},
  //     'scisciIndex': {'scienceCitationsCall': 263,
  //     'scienceCitationsC5': 499,
  //     'predictionScore': 52.11653038164879,
  //     'disruption': -1.2114585812682779,
  //     'atyp10pctZ': 2.097333457319052,
  //     'patentCitationsCall': 165,
  //     'patentCitationsC5': 152,
  //     'teamSize': 11,
  //     'institutionCount': 8,
  //     'tweetCount': 46,
  //     'nihCount': 49,
  //     'nsfCount': 19}},
  //   {'paper_mag_id': 'ccoc7tp4i67ips27',
  //     'demographicInfo': {'paperTitle': 'Somebody himself head real lose suddenly.',
  //     'paperYear': 2016,
  //     'authorsHash': '6jefx4ldxaw9lyjd',
  //     'authorsName': 'Elizabeth Wagner',
  //     'paperMagName': 'Mr Journal',
  //     'paperDoi': '10.93652399/jd322',
  //     'hyperlink': 'http://www.ferguson.com/explore/register.html'},
  //     'scisciIndex': {'scienceCitationsCall': 163,
  //     'scienceCitationsC5': 120,
  //     'predictionScore': 2.6427173087715694,
  //     'disruption': -1.6379078378590073,
  //     'atyp10pctZ': -0.006683846325389986,
  //     'patentCitationsCall': 93,
  //     'patentCitationsC5': 200,
  //     'teamSize': 15,
  //     'institutionCount': 1,
  //     'tweetCount': 198,
  //     'nihCount': 41,
  //     'nsfCount': 15}},
  //   {'paper_mag_id': '85x1nj3lzygppyl3',
  //     'demographicInfo': {'paperTitle': 'Behavior board present attention door.',
  //     'paperYear': 2010,
  //     'authorsHash': 'fyd2dwl3j38hxpo8',
  //     'authorsName': 'Danielle Sanders',
  //     'paperMagName': 'across Journal',
  //     'paperDoi': '10.29570179/abetg',
  //     'hyperlink': 'http://lane-moran.com/author.php'},
  //     'scisciIndex': {'scienceCitationsCall': 146,
  //     'scienceCitationsC5': 462,
  //     'predictionScore': 47.60097659815328,
  //     'disruption': 1.2630560333279321,
  //     'atyp10pctZ': 2.741848353229643,
  //     'patentCitationsCall': 137,
  //     'patentCitationsC5': 21,
  //     'teamSize': 1,
  //     'institutionCount': 4,
  //     'tweetCount': 84,
  //     'nihCount': 16,
  //     'nsfCount': 2}},
  //   {'paper_mag_id': 'sw7c9ra6nk9kfx0v',
  //     'demographicInfo': {'paperTitle': 'Eat stay security modern common traditional.',
  //     'paperYear': 2004,
  //     'authorsHash': 'ta8p05849wv621r6',
  //     'authorsName': 'Melissa Sandoval',
  //     'paperMagName': 'thus Journal',
  //     'paperDoi': '10.87817811/2adrx',
  //     'hyperlink': 'http://www.schmidt.com/main.jsp'},
  //     'scisciIndex': {'scienceCitationsCall': 131,
  //     'scienceCitationsC5': 290,
  //     'predictionScore': 54.62361916082574,
  //     'disruption': 0.38916001803493705,
  //     'atyp10pctZ': 1.1836872806522063,
  //     'patentCitationsCall': 210,
  //     'patentCitationsC5': 179,
  //     'teamSize': 5,
  //     'institutionCount': 8,
  //     'tweetCount': 72,
  //     'nihCount': 10,
  //     'nsfCount': 12}},
  //     {'paper_mag_id': 'zpv7t5nnt8kn0svx',
  //     'demographicInfo': {'paperTitle': 'Training old research baby.',
  //     'paperYear': 2019,
  //     'authorsHash': 'ac0hqixc5un036n1',
  //     'authorsName': 'Daniel Burns',
  //     'paperMagName': 'describe Journal',
  //     'paperDoi': '10.80422446/fb2rr',
  //     'hyperlink': 'http://www.jones-stone.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 492,
  //     'scienceCitationsC5': 401,
  //     'predictionScore': 49.250097061175005,
  //     'disruption': -1.7542679690888887,
  //     'atyp10pctZ': -0.3936355254332353,
  //     'patentCitationsCall': 10,
  //     'patentCitationsC5': 29,
  //     'teamSize': 4,
  //     'institutionCount': 6,
  //     'tweetCount': 8,
  //     'nihCount': 42,
  //     'nsfCount': 4}},
  //   {'paper_mag_id': 'ncg2ph542hl47t75',
  //     'demographicInfo': {'paperTitle': 'East study other task for people study.',
  //     'paperYear': 2011,
  //     'authorsHash': '2w8618sjrhts5d3k',
  //     'authorsName': 'Haley Hampton',
  //     'paperMagName': 'bill Journal',
  //     'paperDoi': '10.59537786/506r3',
  //     'hyperlink': 'http://kennedy-campbell.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 32,
  //     'scienceCitationsC5': 380,
  //     'predictionScore': 20.337933709950264,
  //     'disruption': -1.1754913217088694,
  //     'atyp10pctZ': -0.3312573558111107,
  //     'patentCitationsCall': 166,
  //     'patentCitationsC5': 131,
  //     'teamSize': 13,
  //     'institutionCount': 8,
  //     'tweetCount': 101,
  //     'nihCount': 11,
  //     'nsfCount': 23}},
  //   {'paper_mag_id': '2ycpo4udnwhyvpub',
  //     'demographicInfo': {'paperTitle': 'Act third physical always between.',
  //     'paperYear': 2018,
  //     'authorsHash': 'qowxek6c7sj8v01c',
  //     'authorsName': 'Rita Austin',
  //     'paperMagName': 'home Journal',
  //     'paperDoi': '10.08183713/kcwry',
  //     'hyperlink': 'https://www.carson.com/register/'},
  //     'scisciIndex': {'scienceCitationsCall': 141,
  //     'scienceCitationsC5': 352,
  //     'predictionScore': 69.59035319839508,
  //     'disruption': 0.0592081555884012,
  //     'atyp10pctZ': -0.47622496281519666,
  //     'patentCitationsCall': 126,
  //     'patentCitationsC5': 38,
  //     'teamSize': 10,
  //     'institutionCount': 8,
  //     'tweetCount': 85,
  //     'nihCount': 18,
  //     'nsfCount': 29}},
  //   {'paper_mag_id': 'kly3mv48vwekcxle',
  //     'demographicInfo': {'paperTitle': 'General may produce good third listen miss.',
  //     'paperYear': 1999,
  //     'authorsHash': '3mo9a5kdnpb0x6y4',
  //     'authorsName': 'Joshua Proctor',
  //     'paperMagName': 'share Journal',
  //     'paperDoi': '10.64744802/ko22t',
  //     'hyperlink': 'https://adams-brandt.biz/category/'},
  //     'scisciIndex': {'scienceCitationsCall': 74,
  //     'scienceCitationsC5': 367,
  //     'predictionScore': 32.29330994437794,
  //     'disruption': 0.49949379351750256,
  //     'atyp10pctZ': -1.514255943040222,
  //     'patentCitationsCall': 130,
  //     'patentCitationsC5': 78,
  //     'teamSize': 16,
  //     'institutionCount': 8,
  //     'tweetCount': 151,
  //     'nihCount': 2,
  //     'nsfCount': 36}},
  //   {'paper_mag_id': 'whtbks47jjt73jxx',
  //     'demographicInfo': {'paperTitle': 'Here as young into first another.',
  //     'paperYear': 1999,
  //     'authorsHash': '46q4bl6bxu77m5te',
  //     'authorsName': 'Mr. Kevin Davis',
  //     'paperMagName': 'when Journal',
  //     'paperDoi': '10.15607767/jp7z2',
  //     'hyperlink': 'https://green.biz/'},
  //     'scisciIndex': {'scienceCitationsCall': 294,
  //     'scienceCitationsC5': 8,
  //     'predictionScore': 36.69948049787093,
  //     'disruption': -0.2908827640438254,
  //     'atyp10pctZ': -0.570203845447228,
  //     'patentCitationsCall': 198,
  //     'patentCitationsC5': 87,
  //     'teamSize': 17,
  //     'institutionCount': 10,
  //     'tweetCount': 160,
  //     'nihCount': 5,
  //     'nsfCount': 3}},
  //   {'paper_mag_id': 'im069str5mgmvuqq',
  //     'demographicInfo': {'paperTitle': 'Institution garden issue generation recent feeling first.',
  //     'paperYear': 2011,
  //     'authorsHash': 'dl1ulxqyto18srwh',
  //     'authorsName': 'Pamela Bartlett',
  //     'paperMagName': 'ahead Journal',
  //     'paperDoi': '10.37585846/zoc89',
  //     'hyperlink': 'http://kim.org/category/wp-content/search/terms/'},
  //     'scisciIndex': {'scienceCitationsCall': 266,
  //     'scienceCitationsC5': 382,
  //     'predictionScore': 10.470778801450875,
  //     'disruption': 1.938743931732703,
  //     'atyp10pctZ': 1.9593247069473785,
  //     'patentCitationsCall': 110,
  //     'patentCitationsC5': 207,
  //     'teamSize': 2,
  //     'institutionCount': 10,
  //     'tweetCount': 9,
  //     'nihCount': 40,
  //     'nsfCount': 20}},
  //   {'paper_mag_id': '8w6p928pfs3azwkd',
  //     'demographicInfo': {'paperTitle': 'Color it I miss scientist.',
  //     'paperYear': 2003,
  //     'authorsHash': 'vdqihuupi7yehi3n',
  //     'authorsName': 'Sydney Pierce',
  //     'paperMagName': 'factor Journal',
  //     'paperDoi': '10.09889210/b9yiw',
  //     'hyperlink': 'https://www.hernandez.com/'},
  //     'scisciIndex': {'scienceCitationsCall': 221,
  //     'scienceCitationsC5': 399,
  //     'predictionScore': 66.75112070003722,
  //     'disruption': -0.1804795730000306,
  //     'atyp10pctZ': 2.663128615553183,
  //     'patentCitationsCall': 272,
  //     'patentCitationsC5': 66,
  //     'teamSize': 20,
  //     'institutionCount': 1,
  //     'tweetCount': 40,
  //     'nihCount': 34,
  //     'nsfCount': 0}},
  //   {'paper_mag_id': 'i6mkcaukbx8usc2a',
  //     'demographicInfo': {'paperTitle': 'Blue radio suddenly material collection government great.',
  //     'paperYear': 2010,
  //     'authorsHash': 'cg5y3khxz7wi26de',
  //     'authorsName': 'Sarah Nelson',
  //     'paperMagName': 'not Journal',
  //     'paperDoi': '10.15401432/2uvas',
  //     'hyperlink': 'http://fox.info/home/'},
  //     'scisciIndex': {'scienceCitationsCall': 222,
  //     'scienceCitationsC5': 415,
  //     'predictionScore': 72.85330368213427,
  //     'disruption': -1.9596020345742136,
  //     'atyp10pctZ': 1.196270087130487,
  //     'patentCitationsCall': 54,
  //     'patentCitationsC5': 84,
  //     'teamSize': 16,
  //     'institutionCount': 9,
  //     'tweetCount': 6,
  //     'nihCount': 29,
  //     'nsfCount': 38}},
  //   {'paper_mag_id': 'lduj6bedn92u04h4',
  //     'demographicInfo': {'paperTitle': 'Could author onto book color bill.',
  //     'paperYear': 1999,
  //     'authorsHash': '1ipeez0r9srahwle',
  //     'authorsName': 'Michael Allen',
  //     'paperMagName': 'exist Journal',
  //     'paperDoi': '10.68825037/5klsv',
  //     'hyperlink': 'http://hernandez.info/category/main/author/'},
  //     'scisciIndex': {'scienceCitationsCall': 144,
  //     'scienceCitationsC5': 239,
  //     'predictionScore': 46.400969476204935,
  //     'disruption': -0.31610796952702414,
  //     'atyp10pctZ': 1.2441848797547888,
  //     'patentCitationsCall': 128,
  //     'patentCitationsC5': 146,
  //     'teamSize': 9,
  //     'institutionCount': 5,
  //     'tweetCount': 43,
  //     'nihCount': 0,
  //     'nsfCount': 26}},
  //   ],
  selectedResearcherBarplotData: {
    empid_hash: '',
    paper_list: [], 
  }, 
  selectedPaperID: "",
  selectedPaperPatentList: [],
  // selectedResearcherBarplotData: {
  //   "empid_hash":  "0026553a02e5363b5f793b4e24e0ffb4c69e12e6",
  //   "paper_list": [
  //     {
  //       "paper_id": "10.1681/asn.2014010060",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2014.8551912568305,
  //       "predicted_patentability": 0.19957843,
  //       "title": "An Analysis of Nephrology Trends in 2014"
  //     },
  //     {
  //       "paper_id": "10.1172/jci95545",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2017.844262295082,
  //       "predicted_patentability": 0.94797117,
  //       "title": "Leading Research in Clinical Investigation 2017"
  //     },
  //     {
  //       "paper_id": "10.1681/asn.2017090962",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2017.9453551912568,
  //       "predicted_patentability": 0.22465695,
  //       "title": "Nephrology Advances and Discoveries in 2017"
  //     },
  //     {
  //       "paper_id": "10.1038/s41598-017-18912-8",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2018.0273224043715,
  //       "predicted_patentability": 0.7552296,
  //       "title": "Scientific Reports on Various Biological Studies in 2018"
  //     },
  //     {
  //       "paper_id": "10.1073/pnas.1807982115",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2018.631147540984,
  //       "predicted_patentability": 0.8824687,
  //       "title": "Proceedings of the National Academy of Sciences: Notable Papers of 2018"
  //     },
  //     {
  //       "paper_id": "10.1084/jem.20180009",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2019.207650273224,
  //       "predicted_patentability": 0.96919674,
  //       "title": "Journal of Experimental Medicine Highlights of 2019"
  //     },
  //     {
  //       "paper_id": "10.7554/elife.48474",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2019.7896174863388,
  //       "predicted_patentability": 0.7256259,
  //       "title": "Leading eLife Research Papers of 2019"
  //     },
  //     {
  //       "paper_id": "10.7554/elife.50170",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2019.9371584699452,
  //       "predicted_patentability": 0.36848208,
  //       "title": "Key eLife Studies Published in 2019"
  //     },
  //     {
  //       "paper_id": "10.1167/tvst.9.4.16",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2020.2103825136608,
  //       "predicted_patentability": 0.46483636,
  //       "title": "Translational Vision Science & Technology Research of 2020"
  //     },
  //     {
  //       "paper_id": "10.1186/s12974-020-02010-0",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2020.8661202185792,
  //       "predicted_patentability": 0.5632294,
  //       "title": "Journal of Neuroinflammation: Significant Papers of 2020"
  //     },
  //     {
  //       "paper_id": "10.1152/ajprenal.00556.2020",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2021.3333333333333,
  //       "predicted_patentability": 0.09801622,
  //       "title": "American Journal of Physiology - Renal Physiology Highlights of 2021"
  //     },
  //     {
  //       "paper_id": "10.1038/s41467-021-26346-0",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2021.792349726776,
  //       "predicted_patentability": 0.92014563,
  //       "title": "Nature Communications: Prominent Papers of 2021"
  //     },
  //     {
  //       "paper_id": "10.1161/atvbaha.121.316692",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2022.032786885246,
  //       "predicted_patentability": 0.45092547,
  //       "title": "Arteriosclerosis, Thrombosis, and Vascular Biology Research Highlights of 2022"
  //     },
  //     {
  //       "paper_id": "10.21769/bioprotoc.4426",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2022.379781420765,
  //       "predicted_patentability": 0.731598,
  //       "title": "Bio-protocol Notable Papers of 2022"
  //     },
  //     {
  //       "paper_id": "10.1161/atvbaha.122.318151",
  //       "is_lead_author": 1,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2022.74043715847,
  //       "predicted_patentability": 0.8554275,
  //       "title": "Leading Research in Arteriosclerosis, Thrombosis, and Vascular Biology 2022"
  //     },
  //     {
  //       "paper_id": "10.1681/asn.0000000000000098",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2023.120218579235,
  //       "predicted_patentability": 0.87257594,
  //       "title": "American Society of Nephrology Research of 2023"
  //     },
  //     {
  //       "paper_id": "10.1172/jci.insight.172062",
  //       "is_lead_author": 0,
  //       "patent_citation_binary": 0,
  //       "paper_year_dim_adjusted": 2023.8333333333333,
  //       "predicted_patentability": 0.35483617,
  //       "title": "Journal of Clinical Investigation Insights of 2023"
  //     }
  //   ]
  // },

  testData: '',
  timelineData: {} as TimelineTypes.TimelineData,
  // papermetriceRangeDict: {},
  // papermetriceRangeDictBackend: {},
  matrixData: {
    paperMatrix: [],
    patentTreemap: [],
    paperPatentFlow: [],
  },
  // diversityData: {
  //   paperField: [],
  //   patentCPCSubsection: [],
  // },
  // scatterplotData: [],
  // researcherStatisticsData: [],
  selectedResearcherGroupList: [],
  selectedResearcherCategoryList: [],
  // patentInspectionData: {
  //   name: "",
  //   children: [],
  // },
  patentInspectionData: {
    sunburstData: {
      name: "",
      children: [],
    },
    wordleData: []
  },
  // TODO: selectedSunburstPatentData的初始值待修改
  selectedPatentAssigneeID: 'all',
  paperInspectionData: [],

  // selectedResearcherIdList: ['all'],
  leftViewSelectedResearcherIdList: ['all'],
  allResearcherIdList: [],
  scatterplotLassoMark: false,
  allSubviewPaperList: {
    field1PaperID: [],
    field2PaperID: [],
    field3PaperID: [],
    field4PaperID: [],
    field5PaperID: [],
    field6PaperID: [],
    field7PaperID: [],
    field8PaperID: [],
    field9PaperID: [],
    field10PaperID: [],
    field11PaperID: [],
  },
  brushedPaperList: [{
    paper_mag_id: "init",
    demographicInfo: {
      paperTitle: "",
      paperYear: 0,
      authorsHash: "",
      authorsName: "",
      paperMagName: "",
      paperDoi: "",
      hyperlink: "",
    },
    scisciIndex: {
      scienceCitationsCall: 0,
      scienceCitationsC5: 0,
      predictionScore: 0,
      disruption: 0,
      atyp10pctZ: 0,
      patentCitationsCall: 0,
      patentCitationsC5: 0,
      teamSize: 0,
      institutionCount: 0,
      tweetCount: 0,
      nihCount: 0,
      nsfCount: 0,
      numCompanyPatentCitations:0,
    },
    paper_abstract: "",
  }],
  brushedRange: {
    brushedRange1: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange2: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange3: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange4: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange5: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange6: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange7: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange8: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange9: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange10: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
    brushedRange11: [0, (svgSize.paperInspectionHistogram.width - svgSize.paperInspectionHistogram.margin * 2)],
  },

  // 1. Interplay View
  filteredColIdxDict: {}, // 对后端的数据第一次过滤+排序
  topKFlow: 1000,
  interplayViewData: {
    colName2ColIdx: {},
    colIdxDict: {},
    diversityData: {} as MatrixTypes2.DiversityData,
    papermetriceRangeDictBackend: {},
    papermetriceRangeDict: {},

    compondPatentTreemapData: [],
    compondFlowData: [],

    interplayViewFigureParam: {
      paperMatrix: {} as MatrixTypes2.PaperMatrixFigureParam,
      flowGroup: {} as MatrixTypes2.FlowGroupFigureParam,
      patentTreemap: {} as MatrixTypes2.PatentTreemapFigureParam,
    },
    reorderedPaperMatrixData: [],
  },
  selectedCPCFieldCode: '',
  selectedCPCFieldLevel: 'cpcRoot',
  selectedPaperCellList: [],
  selectedCPCCodeList: [{
    cpcFieldCode: 'all',
    cpcFieldType: 'historical'
  }],
  selectedPaperFieldList: [],
  cpcFieldLevel: CPCFieldLevelList.CPCSECTION,  // 最细粒度的flow单位
  // showedTreemapLevelList: ['cpcRoot', 'cpcSection'],
  // compondFlowData: [],
  // compondPatentTreemapData: [],
  predictionResult: {
    predictedFlowList: [],
    predictedPatentTreemap: [],
  },
  // loading related
  isResearcherOverviewLoading: false,
  isResearcherStatisticViewLoading: false,
  isTechnologyInspectionViewLoading: false,
  isScienceInspectionViewLoading: false,
  isMainviewLoading: false,
};

const reducer = produce((state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.GET_BACKEND_DATA:
      state.testData = action.payload
      break
    case ActionType.GET_TIMELINE_DATA:
      const timelineData = action.payload
      const filteredAndReorderedTimelineData = filterAndReorderTimelineData(timelineData, ignoredPaperField, current(state.interplayViewData.colIdxDict))
      state.timelineData = filteredAndReorderedTimelineData
      break
    case ActionType.GET_MATRIX_DATA:
      // version 3 (20230307)
      // 0. filter data
      /* 一共filter&reorder colIdx 2次：
        (1) 这里getOrderedFilteredPaperFieldData()：用global predefined的paper field order过滤+排序一次
        (2) calcInterplayViewData()里面做paperPair的优化，第二次对colIdx排序（仅排序，不过滤）
      */
      // console.log('before before, paperMatrix data: ', action.payload.paperFieldLevel, action.payload.data)
      // const filteredDataParam = getOrderedFilteredPaperFieldData(action.payload.data, ignoredPaperField, paperFieldL0Order, action.payload.paperFieldLevel)
      // const filteredData = filteredDataParam.data
      // const colIdxDict = filteredDataParam.colIdxDict
      // console.log('filteredData: ', filteredData)

      // state.filteredColIdxDict = colIdxDict

      // state.interplayViewData存储所有interplay view有关的数据。当触发右键某个cpcField rect时，需要重新filter这个数据，来expand data
      const papermetriceRangeDict = getViolinPlotXRange(action.payload.data.paperMatrix)
      const interplayViewData = calcInterplayViewData(action.payload.data, papermetriceRangeDict, state.cpcFieldLevel, state.topKFlow)
      state.interplayViewData = interplayViewData
      // console.log('after, paperMatrix: ', interplayViewData.reorderedPaperMatrixData)

      // update store data
      // 1. matrixData
      state.matrixData = {
        paperMatrix: action.payload.data.paperMatrix, // to draw paper matrix
        paperPatentFlow: action.payload.data.paperPatentFlow, // 后续没用到，just assign value here
        patentTreemap: action.payload.data.patentTreemap, // 后续没用到，just assign value here
      }
      // // 2. timeline (need to reorder paperField based on paperMatrix order)
      // const updatedTimelineData = filterAndReorderTimelineData(state.timelineData, ignoredPaperField, current(state.interplayViewData.colIdxDict))
      // state.timelineData = updatedTimelineData





      // // version 2 (20230306)
      // // 0. filter data
      // /* 一共filter&reorder colIdx 2次：
      //   (1) 这里getOrderedFilteredPaperFieldData()：用global predefined的paper field order过滤+排序一次
      //   (2) calcInterplayViewData()里面做paperPair的优化，第二次对colIdx排序（仅排序，不过滤）
      // */
      // // console.log('before before, paperMatrix data: ', action.payload.paperFieldLevel, action.payload.data)
      // const filteredDataParam = getOrderedFilteredPaperFieldData(action.payload.data, ignoredPaperField, paperFieldL0Order, action.payload.paperFieldLevel)
      // const filteredData = filteredDataParam.data
      // const colIdxDict = filteredDataParam.colIdxDict
      // state.filteredColIdxDict = colIdxDict

      // // state.interplayViewData存储所有interplay view有关的数据。当触发右键某个cpcField rect时，需要重新filter这个数据，来expand data
      // const papermetriceRangeDict = getViolinPlotXRange(filteredData.paperMatrix)
      // const interplayViewData = calcInterplayViewData(filteredData, papermetriceRangeDict, state.cpcFieldLevel, state.topKFlow)
      // state.interplayViewData = interplayViewData

      // // update store data
      // // 1. matrixData
      // state.matrixData = {
      //   paperMatrix: interplayViewData.reorderedPaperMatrixData, // to draw paper matrix
      //   paperPatentFlow: filteredData.paperPatentFlow, // 后续没用到，just assign value here
      //   patentTreemap: filteredData.patentTreemap, // 后续没用到，just assign value here
      // }
      // // // 2. timeline (need to reorder paperField based on paperMatrix order)
      // // const updatedTimelineData = filterAndReorderTimelineData(state.timelineData, ignoredPaperField, current(state.interplayViewData.colIdxDict))
      // // state.timelineData = updatedTimelineData






      // version 1
      // // 1. calculate paper matrix - fieldLabel diversity data
      // state.diversityData = state.interplayViewData.diversityData

      // // 2. calculate paper matrix - unfolded glyph scale
      // state.papermetriceRangeDictBackend = action.payload.paperMetriceRangeDict // 用的是数组的最大最小值作为range
      // // console.log('paperMetriceRangeDict (backend): ', action.payload.paperMetriceRangeDict)
      // state.papermetriceRangeDict = getViolinPlotXRange(filteredData.paperMatrix)
      // // console.log('paperMetriceRangeDict (frontend): ', state.papermetriceRangeDict)

      // // 3. combine historical & predicted result in (1) patentTreemap; (2) flow
      // // 3-1. (1) patentTreemap: transform historical flow data into compond treemap data (和prediction flow统一)
      // state.compondPatentTreemapData = state.interplayViewData.compondPatentTreemapData
      // // 3-2. (2) flow: compond
      // state.compondFlowData = state.interplayViewData.compondFlowData

      // // 4. preprocess Interplay View figure params
      // // 4-1. paper matrix figure params
      // // TODO: data reducer: sort paper field order (both paperMatrix & flowList)
      // state.interplayViewFigureParam.paperMatrix = state.interplayViewData.interplayViewFigureParam.paperMatrix
      // // 4-2. flow figure params
      // state.interplayViewFigureParam.flowGroup = state.interplayViewData.interplayViewFigureParam.flowGroup
      // state.interplayViewFigureParam.patentTreemap = state.interplayViewData.interplayViewFigureParam.patentTreemap







      // // 1. calculate paper matrix - fieldLabel diversity data
      // const diversityData = getPaperFieldPatentCitationDiversity(filteredData.paperPatentFlow)
      // state.diversityData = diversityData

      // // 2. calculate paper matrix - unfolded glyph scale
      // state.papermetriceRangeDictBackend = action.payload.paperMetriceRangeDict // 用的是数组的最大最小值作为range
      // // console.log('paperMetriceRangeDict (backend): ', action.payload.paperMetriceRangeDict)
      // state.papermetriceRangeDict = getViolinPlotXRange(filteredData.paperMatrix)
      // // console.log('paperMetriceRangeDict (frontend): ', state.papermetriceRangeDict)

      // // 3. combine historical & predicted result in (1) patentTreemap; (2) flow
      // // 3-1. (1) patentTreemap: transform historical flow data into compond treemap data (和prediction flow统一)
      // state.compondPatentTreemapData = getCompondPatentTreemapData(filteredData.patentTreemap, [])
      // // 3-2. (2) flow: compond
      // state.compondFlowData = getCompondFlowData(filteredData.paperPatentFlow, [])

      // // 4. preprocess Interplay View figure params
      // // 4-1. paper matrix figure params
      // // TODO: data reducer: sort paper field order (both paperMatrix & flowList)
      // state.interplayViewFigureParam.paperMatrix = getPaperMatrixFigureParam(filteredData.paperMatrix)
      // // 4-2. flow figure params
      //   // opt 1: for compond patent (historical + predicted)
      // state.interplayViewFigureParam.flowGroup = getFlowGroupFigureParam(state.compondFlowData, state.interplayViewFigureParam.paperMatrix.cellGapWidth, state.interplayViewFigureParam.paperMatrix.paperFieldOrderedList, state.cpcFieldLevel)
      //   // opt 2: only for historical patent
      // // state.interplayViewFigureParam.flowGroup = getFlowGroupFigureParam(filteredData.paperPatentFlow, state.interplayViewFigureParam.paperMatrix.cellGapWidth)
      // // 4-3. patent treemap figure params
      //   // opt 1: for compond patent (historical + predicted)
      // state.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(state.compondPatentTreemapData, state.interplayViewFigureParam.flowGroup.xScaleFlowWidth, state.cpcFieldLevel)
      //   // opt 2: only for historical patent
      // // state.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(filteredData.patentTreemap, state.interplayViewFigureParam.flowGroup.xScaleFlowWidth)
      break
    case ActionType.GET_SCATTERPLOT_DATA:
      state.scatterplotData = action.payload
      break
    case ActionType.GET_RESEARCHER_STATISTICS_DATA:
      state.researcherStatisticsData = action.payload
      break
    case ActionType.GET_PATENT_INSPECTION_DATA:
      state.patentInspectionData = action.payload;
      break;
    case ActionType.GET_PAPER_INSPECTION_DATA:
      state.paperInspectionData = action.payload;
      break;
    case ActionType.GET_ALL_RESEARCHER_ID_LIST:
      state.allResearcherIdList = action.allResearcherIdList;
      break
    case ActionType.UPDATE_SUNBURST_SELECTED_PATENT_LIST:
      state.selectedPatentAssigneeID = action.selectedPatentAssigneeID;
      // console.log('state.selectedPatentAssigneeID =', action.selectedPatentAssigneeID)
      break;
    case ActionType.UPDATE_SELECTED_RESEARCHER_ID_LIST:
      console.log('data reducer - udpate selected researcher id list: ', action.selectedResearcherIdList)
      state.selectedResearcherIdList = action.selectedResearcherIdList
      break
    case ActionType.UPDATE_LEFT_VIEW_SELECTED_RESEARCHER_ID_LIST:
      state.leftViewSelectedResearcherIdList = action.leftViewSelectedResearcherIdList
      break
    case ActionType.UPDATE_SCATTERPLOT_LASSO_MARK:
      state.scatterplotLassoMark = action.scatterplotLassoMark
      break
    case ActionType.UPDATE_BRUSHED_PAPER_LIST:
      state.brushedPaperList = action.brushedPaperList
      break
    case ActionType.UPDATE_All_SUBVIEW_PAPER_LIST:
      state.allSubviewPaperList = action.allSubviewPaperList
      break
    case ActionType.UPDATE_PAPER_BRUSHED_RANGE:
      state.brushedRange = action.brushedRange
      break
    case ActionType.UPDATE_SELECTED_RESEARCHER_GROUP_LIST:
      state.selectedResearcherGroupList = action.selectedResearcherGroupList
      break
    case ActionType.UPDATE_SELECTED_RESEARCHER_CATEGORY_LIST:
      state.selectedResearcherCategoryList = action.selectedResearcherCategoryList
      break
    case ActionType.GET_PREDICTION_RESULT:

      // version 3 (20230308)
      // console.log('action.predictionResult: ', action.predictionResult)
      state.predictionResult = action.predictionResult

      // 1. combine historical & predicted result in (1) patentTreemap; (2) flow
      // 1-1. (1) patentTreemap: transform historical flow data into compond treemap data (和prediction flow统一)
      const updatedCompondTreemapData = getCompondPatentTreemapData(action.historicalPatentTreemapData, action.predictionResult.predictedPatentTreemap)
      state.interplayViewData.compondPatentTreemapData = updatedCompondTreemapData

      // 1-2. (2) flow: compond
      // 1-2-1. for predicted flow, 要进行2次reorder of ColIdx
      // // ① 先进行第一次filter和reorder of colIdx
      // const filteredPredictionFlowData: MatrixTypes2.FlowUnit[] = action.predictionResult.predictedFlowList.filter((flowUnit) => !ignoredPaperField.includes(flowUnit.colName))
      // const filteredFlowData = getOrderedFilteredFlowData(action.predictionResult.predictedFlowList, ignoredPaperField, current(state.filteredColIdxDict))
      // // ② 再根据paperFieldPair进行reorder
      // const colName2ColIdx = current(state.interplayViewData.colName2ColIdx)
      // const reorderedPredictionFlowData: MatrixTypes2.FlowUnit[] = filteredPredictionFlowData.map((flowData: MatrixTypes2.FlowUnit) => {
      //   const newData = flowData
      //   flowData.colIdx = colName2ColIdx[flowData.colName]
      //   return newData
      // })
      // 1-2-2. for historical flow, 也需要排序（因为在getMatrixData的时候，只排序了compondFlow，原始的FlowUnit下的flow没有更新colIdx）
      const historicalFlowData = _.cloneDeep(current(state.matrixData.paperPatentFlow))
      // const reorderedFlowData: MatrixTypes2.FlowUnit[] = historicalFlowData.map((flowData: MatrixTypes2.FlowUnit) => {
      //   const newData = flowData
      //   flowData.colIdx = colName2ColIdx[flowData.colName]
      //   return newData
      // })
      // const updatedCompondFlowData = getCompondFlowData(reorderedFlowData, reorderedPredictionFlowData)
      const updatedCompondFlowData = getCompondFlowData(historicalFlowData, action.predictionResult.predictedFlowList)
      state.interplayViewData.compondFlowData = updatedCompondFlowData

      // 2. preprocess Interplay View figure params
      // 下面的state.xxx没加current的可能都有问题
      const updatedFlowFigureParam = getFlowGroupFigureParam(state.interplayViewData.compondFlowData, state.interplayViewData.interplayViewFigureParam.paperMatrix.cellGapWidth, current(state.interplayViewData.interplayViewFigureParam.paperMatrix.paperFieldOrderedList), state.cpcFieldLevel)
      state.interplayViewData.interplayViewFigureParam.flowGroup = updatedFlowFigureParam

      // console.log('prediction, xScaleFlowWidth: ', updatedFlowFigureParam.xScaleFlowWidth)
      state.interplayViewData.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(
        updatedCompondTreemapData,
        updatedFlowFigureParam.xScaleFlowWidth,
        state.cpcFieldLevel,
        // state.interplayViewData.compondPatentTreemapData,
        // state.interplayViewData.interplayViewFigureParam.flowGroup.xScaleFlowWidth,
        // state.cpcFieldLevel
      )
      break


      // // version 2 (20220307)
      // const colIdxDict2 = current(state.interplayViewData.colIdxDict)
      // state.predictionResult = action.predictionResult
      // // 1. combine historical & predicted result in (1) patentTreemap; (2) flow
      // // 1-1. (1) patentTreemap: transform historical flow data into compond treemap data (和prediction flow统一)
      // const updatedCompondTreemapData = getCompondPatentTreemapData(action.historicalPatentTreemapData, action.predictionResult.predictedPatentTreemap)
      // state.interplayViewData.compondPatentTreemapData = updatedCompondTreemapData

      // // 1-2. (2) flow: compond
      // // 1-2-1. for predicted flow, 要进行2次reorder of ColIdx
      // // ① 先进行第一次filter和reorder of colIdx
      // const filteredPredictionFlowData: MatrixTypes2.FlowUnit[] = action.predictionResult.predictedFlowList.filter((flowUnit) => !ignoredPaperField.includes(flowUnit.colName))
      // // const filteredFlowData = getOrderedFilteredFlowData(action.predictionResult.predictedFlowList, ignoredPaperField, current(state.filteredColIdxDict))
      // console.log('------> filteredPredictionFlowData: ', filteredPredictionFlowData)
      // // ② 再根据paperFieldPair进行reorder
      // const colName2ColIdx = current(state.interplayViewData.colName2ColIdx)
      // const reorderedPredictionFlowData: MatrixTypes2.FlowUnit[] = filteredPredictionFlowData.map((flowData: MatrixTypes2.FlowUnit) => {
      //   const newData = flowData
      //   flowData.colIdx = colName2ColIdx[flowData.colName]
      //   return newData
      // })
      // // 1-2-2. for historical flow, 也需要排序（因为在getMatrixData的时候，只排序了compondFlow，原始的FlowUnit下的flow没有更新colIdx）
      // const historicalFlowData = _.cloneDeep(current(state.matrixData.paperPatentFlow))
      // const reorderedFlowData: MatrixTypes2.FlowUnit[] = historicalFlowData.map((flowData: MatrixTypes2.FlowUnit) => {
      //   const newData = flowData
      //   flowData.colIdx = colName2ColIdx[flowData.colName]
      //   return newData
      // })
      // const updatedCompondFlowData = getCompondFlowData(reorderedFlowData, reorderedPredictionFlowData)
      // state.interplayViewData.compondFlowData = updatedCompondFlowData

      // // 2. preprocess Interplay View figure params
      // // 下面的state.xxx没加current的可能都有问题
      // const updatedFlowFigureParam = getFlowGroupFigureParam(state.interplayViewData.compondFlowData, state.interplayViewData.interplayViewFigureParam.paperMatrix.cellGapWidth, current(state.interplayViewData.interplayViewFigureParam.paperMatrix.paperFieldOrderedList), state.cpcFieldLevel)
      // state.interplayViewData.interplayViewFigureParam.flowGroup = updatedFlowFigureParam

      // console.log('prediction, xScaleFlowWidth: ', updatedFlowFigureParam.xScaleFlowWidth)
      // state.interplayViewData.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(
      //   updatedCompondTreemapData,
      //   updatedFlowFigureParam.xScaleFlowWidth,
      //   state.cpcFieldLevel,
      //   // state.interplayViewData.compondPatentTreemapData,
      //   // state.interplayViewData.interplayViewFigureParam.flowGroup.xScaleFlowWidth,
      //   // state.cpcFieldLevel
      // )
      // break













      // version 1
      // state.predictionResult = action.predictionResult
      // // 1. combine historical & predicted result in (1) patentTreemap; (2) flow
      // // 1-1. (1) patentTreemap: transform historical flow data into compond treemap data (和prediction flow统一)
      // state.compondPatentTreemapData = getCompondPatentTreemapData(action.historicalPatentTreemapData, action.predictionResult.predictedPatentTreemap)
      // // 1-2. (2) flow: compond
      // state.compondFlowData = getCompondFlowData(state.matrixData.paperPatentFlow, action.predictionResult.predictedFlowList)

      // // 2. preprocess Interplay View figure params
      // state.interplayViewFigureParam.flowGroup = getFlowGroupFigureParam(state.compondFlowData, state.interplayViewFigureParam.paperMatrix.cellGapWidth, state.interplayViewFigureParam.paperMatrix.paperFieldOrderedList, state.cpcFieldLevel)
      // state.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(state.compondPatentTreemapData, state.interplayViewFigureParam.flowGroup.xScaleFlowWidth, state.cpcFieldLevel)
      // break
    case ActionType.UPDATE_SHOWED_MIN_TREEMAP_LEVEL:
      state.cpcFieldLevel = action.showedMinTreemapLevel

      // update Interplay View figure params: flow的startingY (CPCRect的出发点) 要update一下
      state.interplayViewData.interplayViewFigureParam.flowGroup = getFlowGroupFigureParam(state.interplayViewData.compondFlowData, state.interplayViewData.interplayViewFigureParam.paperMatrix.cellGapWidth, state.interplayViewData.interplayViewFigureParam.paperMatrix.paperFieldOrderedList, action.showedMinTreemapLevel)
      state.interplayViewData.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(state.interplayViewData.compondPatentTreemapData, state.interplayViewData.interplayViewFigureParam.flowGroup.xScaleFlowWidth, action.showedMinTreemapLevel)
      break

      // state.cpcFieldLevel = action.showedMinTreemapLevel

      // // update Interplay View figure params: flow的startingY (CPCRect的出发点) 要update一下
      // state.interplayViewFigureParam.flowGroup = getFlowGroupFigureParam(state.compondFlowData, state.interplayViewFigureParam.paperMatrix.cellGapWidth, state.interplayViewFigureParam.paperMatrix.paperFieldOrderedList, action.showedMinTreemapLevel)
      // state.interplayViewFigureParam.patentTreemap = getPatentTreemapFigureParam(state.compondPatentTreemapData, state.interplayViewFigureParam.flowGroup.xScaleFlowWidth, action.showedMinTreemapLevel)
      // break
    case ActionType.UPDATE_SELECTED_CPC_CODE_LIST:
      state.selectedCPCCodeList = action.selectedCPCCodeList
      state.selectedPaperFieldList = getSelectedPaperFieldList(action.selectedCPCCodeList, current(state.interplayViewData.compondFlowData), state.topKFlow)
      break
    case ActionType.UPDATE_SELECTED_PAPERCELL_LIST:
      state.selectedPaperCellList = action.selectedPaperCellList
      break
    case ActionType.UPDATE_TOP_K_FLOW:
      state.topKFlow = action.topKFlow
      state.selectedPaperFieldList = getSelectedPaperFieldList(current(state.selectedCPCCodeList), current(state.interplayViewData.compondFlowData), action.topKFlow)
      break
    case ActionType.UPDATE_RESEARCHER_OVERVIEW_LOADING:
      state.isResearcherOverviewLoading = action.isResearcherOverviewLoading
      break
    case ActionType.UPDATE_RESEARCHER_STATISTIC_VIEW_LOADING:
      state.isResearcherStatisticViewLoading = action.isResearcherStatisticViewLoading
      break
    case ActionType.UPDATE_TECH_INSPECTION_VIEW_LOADING:
      state.isTechnologyInspectionViewLoading = action.isTechnologyInspectionViewLoading
      break
    case ActionType.UPDATE_SCIENCE_INSPECTION_VIEW_LOADING:
      state.isScienceInspectionViewLoading = action.isScienceInspectionViewLoading
      break
    case ActionType.UPDATE_MAINVIEW_LOADING:
      state.isMainviewLoading = action.isMainviewLoading
      break
    case ActionType.SET_RESEARCHER_RANK_BY_FIELD:
      state.researcherRankByField = action.researcherRankByField
      break
    case ActionType.SET_RESEARCHER_RANK_ORDER:
      state.researcherRankOrder = action.researcherRankOrder
      break
    case ActionType.SET_SEARCH_FACULTY_KEYWORD:
      state.searchFacultyKeyword = action.searchFacultyKeyword
      break
    case ActionType.GET_SELECTED_RESEARCHER_ID_LIST:
      console.log('data reducer - selected researcher id list: ', action.selectedResearcherIDList)
      state.selectedResearcherIdList = action.selectedResearcherIDList
      break
    case ActionType.GET_RESEARCHER_SCATTERPLOT_DATA:
      state.scatterplotData = action.researcherScatterplotData
      break
    case ActionType.GET_RESEARCHER_CARD_LIST_DATA:
      state.researcherStatisticsData = action.researcherCardStatisticsData.researcher_statistics_list
      state.researcherCardSize = action.researcherCardStatisticsData.researcher_cards_size
      break
    case ActionType.SET_PAPER_RANK_BY_FIELD:
      state.paperRankByField = action.paperRankByField
      break
    case ActionType.SET_PAPER_RANK_ORDER:
      state.paperRankOrder = action.paperRankOrder
      break
    case ActionType.SET_SEARCH_PAPER_KEYWORD:
      state.searchPaperKeyword = action.searchPaperKeyword
      break
    case ActionType.GET_SELECTED_PAPER_ID_LIST:
      state.selectedPaperIdList = action.selectedPaperIDList
      break
    case ActionType.GET_PAPER_CARD_LIST_DATA:
      state.paperCardListData = action.paperCardListData
      break
    case ActionType.GET_TOPK_PAPER_CARD_LIST_DATA:
      state.paperCardListData = action.paperCardInspectionData.paper_inspection_list
      state.paperCardSize = action.paperCardInspectionData.paper_cards_size
      break
    case ActionType.SET_SELECTED_RESEARCHER_ID:
      state.selectedResearcherId = action.selectedResearcherID
      break
    case ActionType.SET_SELECTED_AUTHOR_ID:
      state.selectedAuthorId = action.selectedAuthorID
      break
    case ActionType.GET_RESEARCHER_BARPLOT_DATA:
      state.selectedResearcherBarplotData = action.researcherBarplotData
      break
    // case ActionType.GET_RESEARCHER_PAPER_LIST_DATA:
    //   state.selectedResearcherPaperList = action.selectedResearherPaperList
    //   break
    case ActionType.GET_SELECTED_RESEARCHER_PAPER_PATENT_LIST_DATA:
      console.log('data reducer - selected researcher paper list: ', action.selectedResearcherPaperPatentList.paper_list)
      console.log('data reducer - selected researcher paper patent list: ', action.selectedResearcherPaperPatentList.patent_list)
      state.selectedResearcherPaperList = action.selectedResearcherPaperPatentList.paper_list
      state.selectedResearcherPaperPatentList = action.selectedResearcherPaperPatentList.patent_list
      break
    case ActionType.SET_SELECTED_PAPER_ID:
      state.selectedPaperID = action.selectedPaperID
      break
    case ActionType.SET_IDEA_FINDER_SELECTED_PAPER:
      state.ideaFinderSelectedPaper = action.ideaFinderSelectedPaper
      break
    case ActionType.GET_SELECTED_PAPER_PATENT_LIST:
      state.selectedPaperPatentList = action.selectedPaperPatentList
      break
    default:
      break
  }
  return state
  });

export default reducer;
