import { GlobalStyle } from "./style";

export const numCol: number = 18
export const caseMark: string = "case1"
// export const caseMark: string = "case2"
export const timelineStyle: { [attrName: string]: any } = {
  areaChart: {
    timelineTextHeight: 16,
    timelineDivMargin: 8,
    timelineDivPadding: 6,
    timelineDivWidth: 520,
    timelineDivHeight: 70,
  },
  horizonChart: {
    timelineTextHeight: 50,
    timelineTextWidth: 250,
    timelineDivMargin: 2,
    timelineDivPadding: 4,
    timelineDivWidth: 520,
    timelineDivHeight: 24,
    timelineSvgWidth: 370,
  }

}
export const matrixViewStyle: { [attrName: string]: any } = {
  paperMatrixLabel: {
    diversityScoreMaxWidthHeight: 12,
    timelineHeight: 24,
    timelineWidth: 42,
  },
  flowControlPointFraction: 0.9,
  paperMatrixFieldNameHeight: 60,
  fractionCellWidthHeight: 0.4, //0.3,
  fractionPatentTreemapWidth: 0.5,

  // 下面两个互为alternative。目前选第二个
  flowMaxWidthHardCodeTemp: 5, // flow最高宽5px。暂时hard code一下
  paperMatrixCellGapMargin: 6, // 6, 10, flow的左右margin 4px;
  paperMatrixCellGapMarginProportion: 0.2,

  // flow config
  flatHeight2: 2, // node[5] -> node[6]的地方
  flatHeight: 16, // node[1] -> node[2]的地方
  // flowLevel1MarginTop: 28, // level1 flow距离papermatrix最底层的距离
}
export const svgSize: { [chartName: string]: GlobalStyle.SVGSize } = {
  mainviewPatent: {
    width: 1088 - 16 * 2,
    height: 80,
    margin: 0,
  },
  mainviewFlow: {
    width: 1088 - 16 * 2,
    height: 160,
    margin: 0,
  },
  mainviewPaperMatrix: {
    width: 1088 - 16 * 2,
    height: 488,
    margin: 0,
  },
  mainviewMatrix: {
    // 三个部分的总长宽
    width: 1088 - 16 * 2,
    height: 760 - 16 * 2,
    margin: 16,
  },
  mainViewTimeline: {
    width: 1024,
    height: 200,
    margin: 16,
  },
  mainviewScatterplot: {
    width: 400 - 25 * 2,
    height: 420 - 45 * 2,
    margin: 30,
    // padding: 4,
  },
  mainviewSunburst: {
    // width: 400 - 30 * 2,
    width: 400 - 25 * 2,
    height: 420 - 45 * 2,
    margin: 15,
    // padding: 4,
  },
  paperInspectionHistogram: {
    width: 350,
    height: 80,
    margin: 20,
    // padding: 4,
  },
}
export const themeColor: { [theme: string]: { [elementName: string]: any } } = {
  default: {
    baseColor: '#98ACC0',
    chartFillColor: '#91A6B6', // '#A4ADB6', //'#C2CED6',
    chartHighLightColor: '#DCB06A', // '#ea8958', //'#DCB06A',
    chartTickColor: '#696969', // '#9d9d9d',
    chartBorderColor: '#e0e0e0',
    chartDebugColor: '#E0E0E0',
    hyperLink: '#f8e0b066',
    femaleColor: '#CC6594',
    maleColor: '#347DC1',
    wordleColor: '#5d5d5d', // '#808080',
    selectionColor: {
      highlight: '#d8b9864a', // '#98ACC066',
      default: 'white',
      defaultCard: '#E9E9E9',
      // highlight: '#f8e0b066',
      // default: 'white',
      // defaultCard: '#E9E9E9',
    },
    scatterPlot: {
      clusterColorList: ["#bab0ab", "#4e79a7","#f28e2c","#e15759","#76b7b2","#59a14f","#edc949","#af7aa1","#ff9da7","#9c755f"],
      lasso: {
        strokeColor: 'black',
        strokeWidth: 2,
        fill: 'black',
        fillOpacity: 0.3,
        selectedColor: '#d8b986', // "#d8b986",
        selectedArea: '#d8b986',// '#d8b986',
      },
      contour: {
        contourAreaOpacity: 0.03,
        contourAreaColor: "#808080",
      },
      lofAnomalyScoreLinear: ['#CED8DE', '#98ACC0'],
      // pIndexLinear: ['#653667', '#38673e'],
      // pIndexLinear: ['#FF0000', '#FFFF00'],
      pIndexLinear: ['#CED8DE', '#98ACC0'],

    },
    horizonChart: [
      // '#ab976d',
      // '#9e9475',
      // '#8f917e',
      // '#828f86',
      // '#648999',

      // '#7f6f4f',
      // '#656144',
      // '#667b5f',
      // '#415d53',
      // '#435c67',

      // '#e6c795',
      // '#d1c0a1',
      // '#c0baaa',
      // '#acb3b5',
      // '#98acc0',

      '#D2DBE1',
      '#C2CED6',
      '#A9BAC6',
      '#91A6B6',
      '#6C889D',
    ],
    researcherStatistic:{
      introText: {
        title: {
          meanCfL1: "xxx",
          numPapers: "xxx",
          // numInventions: "xxx",
          // numPapersCitedByPatents: "xxx",
          numInventions_2001_2023: "xxx",
          numPapersSince2001CitedByCompanyPatents: "xxx",
          numPatentsCitingAuthorPapers: "xxx",
          numPatentCitations: "xxx",
          pIndex: "xxx",
          gender: "xxx",
          jobTitle: "xxx",
          schoolName: "xxx",
          departmentName: "xxx",
          tenureStatus: "xxx",
          untapStatus: "xxx",
          affiliationName: "xxx",
          affiliationCountry: "xxx",
          affiliationType: "xxx",
        },
        content: {
          meanCfL1: "yyy",
          numPapers: "yyy",
          // numInventions: "yyy",
          // numPapersCitedByPatents: "yyy",
          numInventions_2001_2023: "yyy",
          numPapersSince2001CitedByCompanyPatents: "yyy",
          numPatentsCitingAuthorPapers: "yyy",
          numPatentCitations: "yyy",
          pIndex: "yyy",
          gender: "yyy",
          jobTitle: "yyy",
          schoolName: "yyy",
          departmentName: "yyy",
          tenureStatus: "yyy",
          untapStatus: "yyy",
          affiliationName: "yyy",
          affiliationCountry: "yyy",
          affiliationType: "yyy",
        }
      }
    },
    flowColor: {
      default: '#98ACC0',
      highlight: '#98ACC0',
      hidden: '#E0E0E0',
      predicted: '#c6d0d9',

      // default: '#51473D', // '#E0E0E0', '#4c4c4c'
      // highlight: '#51473D',// '#4c4c4c', '#4c4c4c'
      // hidden: '#E0E0E0',
      // predicted: '#a9927d',


      // default: '#7f5539', // '#E0E0E0', '#4c4c4c'
      // highlight: '#7f5539',// '#4c4c4c', '#4c4c4c'
      // hidden: '#E0E0E0',
      // predicted: '#98ACC0',
    },
    treemapColor: {
      // default: '#51473D',
      highlight: '#51473D',
      hidden: '#E0E0E0',
    },
    paperFieldLevel0ColorDict: {

      art: '#609e90',
      history: '#609e90',
      philosophy: '#609e90',

      business: '#609e90', // e76f51
      geography: '#609e90',
      economics: '#609e90',
      'political science': '#609e90',
      psychology: '#609e90',
      sociology: '#609e90',

      biology: '#609e90',
      'environmental science': '#609e90',
      medicine: '#609e90',

      geology: '#609e90',
      mathematics: '#609e90',
      physics: '#609e90',
      chemistry: '#609e90',

      'computer science': '#609e90',
      engineering: '#609e90',
      'materials science': '#609e90',

      // art: '#858F3D',
      // history: '#858F3D',
      // philosophy: '#858F3D',

      // business: '#858F3D', // e76f51
      // geography: '#858F3D',
      // economics: '#858F3D',
      // 'political science': '#858F3D',
      // psychology: '#858F3D',
      // sociology: '#858F3D',

      // biology: '#858F3D',
      // 'environmental science': '#858F3D',
      // medicine: '#858F3D',

      // geology: '#858F3D',
      // mathematics: '#858F3D',
      // physics: '#858F3D',
      // chemistry: '#858F3D',

      // 'computer science': '#858F3D',
      // engineering: '#858F3D',
      // 'materials science': '#858F3D',


      // opt 2
      // art: '#e9c46a',
      // history: '#e9c46a',
      // philosophy: '#e9c46a',

      // business: '#e9c46a', // e76f51
      // geography: '#e9c46a',
      // economics: '#e9c46a',
      // 'political science': '#e9c46a',
      // psychology: '#e9c46a',
      // sociology: '#e9c46a',

      // biology: '#9FAB49',
      // 'environmental science': '#9FAB49',
      // medicine: '#9FAB49',

      // geology: '#9A87AA', // '#BE668A',
      // mathematics: '#9A87AA',
      // physics: '#9A87AA',
      // chemistry: '#9A87AA',

      // 'computer science': '#457b9d',
      // engineering: '#457b9d',
      // 'materials science': '#457b9d',


      // opt 1
      // art: '#C99076',
      // history: '#C99076',
      // philosophy: '#C99076',

      // biology: '#9FAB49',
      // 'environmental science': '#9FAB49',
      // medicine: '#9FAB49',

      // geology: '#9A87AA', // '#BE668A',
      // mathematics: '#9A87AA',
      // physics: '#9A87AA',
      // chemistry: '#9A87AA',

      // 'computer science': '#76A8C9',
      // engineering: '#76A8C9',
      // 'materials science': '#76A8C9',

      // business: '#BE668A',
      // geography: '#BE668A',
      // economics: '#BE668A',
      // 'political science': '#BE668A',
      // psychology: '#BE668A',
      // sociology: '#BE668A',
    },
    sunburst: {
      // ['#0d3b66', '#faf0ca']
      // ['#2e4057', '#f6d8ae']
      // ['#424b54' '#e1ce7a']
      // ['#423e37', '#e3b23c']
      // ['#352208', '#e1bb80']
      // ['#8ab0ab', '#3e505b',]
      // ['#d4aa7d', '#272727']
      fillColor: {
        "Companies": '#9f7e69',
        "Universities": '#d2bba0',
        "Others": '#e0e0e0',
      },
      textColor: {
        "Companies": '#4a4a4a',
        "Universities": '#4a4a4a',
        "Others": '#4a4a4a',
      }
    },
    paperInspectionView: {
      paperHistogramStyle: {
        barColor: '#C2CED6',
        inactiveColor:"#D2DBE1",
        barHighLightColor: '#8889AA', // '#e1ce7a',
        inactiveHighLightColor: '#A7A8BD', //"#e3b23c",
        starIconColor: '#59a14f',
        // histogramField1: "science_citations_Call",
        // histogramField2: "predictionScore",
        // histogramField3: "disruption",
        // histogramField4: "atyp_median_z",
        histogramField1: "predictionScore",
        histogramField2: "science_citations_C5",
        histogramField3: "disruption",
        histogramField4: "atyp_median_z",
        histogramField5: "patent_citations",
        histogramField6: "paper_year",
        histogramField7: "team_size",
        histogramField8: "institution_count",
        histogramField9: "tweet_count",
        histogramField10: "nih_count",
        histogramField11: "nsf_count",
      },
      introText: {
        title: {
          patentabilityPrediction: "xxx",
          patentCitation: "xxx",
          scienceCitation: "xxx",
          distruption: "xxx",
          novelty: "xxx",
          paperYear: "xxx",
          teamSize: "xxx",
          institutionCount: "xxx",
          tweetCount: "xxx",
          nihCount: "xxx",
          nsfCount: "xxx",
        },
        content: {
          patentabilityPrediction: "yyy",
          patentCitation: "yyy",
          scienceCitation: "yyy",
          distruption: "yyy",
          novelty: "yyy",
          paperYear: "yyy",
          teamSize: "yyy",
          institutionCount: "yyy",
          tweetCount: "yyy",
          nihCount: "yyy",
          nsfCount: "yyy",
        }
      }
    }
  },
  northwestern: {
    baseColor: '#7E7E8B',
    chartFillColor: '#BBBBC2',
    chartHighLightColor: '#DCBD8B',
    chartTickColor: '#9d9d9d',
    chartBorderColor: '#e0e0e0',
    chartDebugColor: '#E0E0E0',
    hyperLink: '#A7A8BD66',
    selectionColor: {
      highlight: '#A7A8BD66',
      default: 'white',
      defaultCard: '#E9E9E9',
    },
    scatterPlot: {
      clusterColorList: ["#bab0ab", "#4e79a7","#f28e2c","#e15759","#76b7b2","#59a14f","#edc949","#af7aa1","#ff9da7","#9c755f"],
      lasso: {
        strokeColor: 'black',
        strokeWidth: 2,
        fill: 'black',
        fillOpacity: 0.3,
        selectedColor: "#DCBD8B"
      },
      contour: {
        contourAreaOpacity: 0.03,
        contourAreaColor: "#808080",
      },
      lofAnomalyScoreLinear: ['#C2CED6', '#6C889D'],
    },
    horizonChart: [
      '#ccbcb4',
      '#b8acac',
      '#a79ea5',
      '#898599',
      '#777792',
    ],
    flowColor: {
      default: '#51473D', // '#E0E0E0', '#4c4c4c'
      highlight: '#51473D',// '#4c4c4c', '#4c4c4c'
      hidden: '#E0E0E0',
      predicted: '#a9927d',
    },
    treemapColor: {
      highlight: '#51473D',
      hidden: '#E0E0E0',
    },
    paperFieldLevel0ColorDict: {
      art: '#e9c46a',
      history: '#e9c46a',
      philosophy: '#e9c46a',

      business: '#e9c46a', // e76f51
      geography: '#e9c46a',
      economics: '#e9c46a',
      'political science': '#e9c46a',
      psychology: '#e9c46a',
      sociology: '#e9c46a',

      biology: '#9FAB49',
      'environmental science': '#9FAB49',
      medicine: '#9FAB49',

      geology: '#9A87AA', // '#BE668A',
      mathematics: '#9A87AA',
      physics: '#9A87AA',
      chemistry: '#9A87AA',

      'computer science': '#457b9d',
      engineering: '#457b9d',
      'materials science': '#457b9d',

      // art: '#C99076',
      // history: '#C99076',
      // philosophy: '#C99076',

      // biology: '#9FAB49',
      // 'environmental science': '#9FAB49',
      // medicine: '#9FAB49',

      // geology: '#9A87AA', // '#BE668A',
      // mathematics: '#9A87AA',
      // physics: '#9A87AA',
      // chemistry: '#9A87AA',

      // 'computer science': '#76A8C9',
      // engineering: '#76A8C9',
      // 'materials science': '#76A8C9',

      // business: '#BE668A',
      // geography: '#BE668A',
      // economics: '#BE668A',
      // 'political science': '#BE668A',
      // psychology: '#BE668A',
      // sociology: '#BE668A',
    },
    sunburst: {
      // ['#0d3b66', '#faf0ca']
      // ['#2e4057', '#f6d8ae']
      // ['#424b54' '#e1ce7a']
      "Companies": '#423e37',
      "Universities": '#e3b23c',
      "Others": 'grey',
    },
    paperInspectionView: {
      paperHistogramStyle: {
        barColor: '#e0e0e0',
        inactiveColor:"#D2DBE1",
        barHighLightColor: '#DCBD8B',
        inactiveHighLightColor:"#DCBD8B",
        // histogramField1: "science_citations_Call",
        // histogramField2: "predictionScore",
        // histogramField3: "disruption",
        // histogramField4: "atyp_median_z",
        histogramField1: "predictionScore",
        histogramField2: "science_citations_C5",
        histogramField3: "disruption",
        histogramField4: "atyp_median_z",
        histogramField5: "patent_citations",
        histogramField6: "paper_year",
        histogramField7: "team_size",
        histogramField8: "institution_count",
        histogramField9: "tweet_count",
        histogramField10: "nih_count",
        histogramField11: "nsf_count",
      }
    }
  },
  dark: {},
};
export const viewSize: { [attrName: string]: GlobalStyle.ViewSize } = {
  mainViewTimeline: {
    width: 1088,
    height: 210,
    margin: 4,
    padding: 4,
  },
  mainviewMatrix: {
    width: 1088,
    height: 766,
    margin: 4,
    padding: 4,
  },
  systemSize: {
    width: 1920,
    height: 1080,
    margin: 0,
    padding: 0,
  },
  researcherOverviewView: {
    width: 400,
    height: 420,
    margin: 4,
    padding: 4,
  },
  researcherStatisticsView: {
    width: 400,
    height: 586,
    margin: 4,
    padding: 4,
  },
  researcherStatisticsViewCard: {
    width: 360,
    height: 220,
    margin: 20,
    padding: 4,
  },
  researcherStatisticsViewCardList: {
    width: 400,
    height: 525,
    margin: 4,
    padding: 4,
  },
  researcherStatisticsViewHistogram: {
    width: 390,
    height: 100,
    margin: 2,
    padding: 4,
  },
  researcherStatisticsHistogramSVG: {
    width: 270,
    height: 100,
    margin: 20,
    padding: 4,
  },
  mainView: {
    width: 1088,
    height: 1014,
    margin: 4,
    padding: 4,
  },
  technologyInspectionView: {
    width: 400,
    height: 460,
    margin: 4,
    padding: 4,
  },
  scienceInspectionView: {
    width: 400,
    height: 546,
    margin: 4,
    padding: 4,
  },
  paperInspectionViewCard: {
    width: 360,
    height: 184,
    margin: 20,
    padding: 4,
  },
  paperInspectionViewCardList: {
    width: 400,
    height: 500,
    margin: 4,
    padding: 4,
  },
};
export const panelStyle: GlobalStyle.PanelStyle = {
  panelHeaderHeight: 45,
};

// export const scatterplotStyle: { [attrName: string]: any } = {
//   contourAreaOpacity: 0.03,
//   contourAreaColor:"#808080",
// }

export const researcherBarchartStyle: { [attrName: string]: any } = {
  demographicTypeNum: 5,
  barWidth: 20,
  barInterval: 5,
}

// export const paperHistogramStyle: { [attrName: string]: any } = {
//   barColor: '#D2DBE1',
//   inactiveColor:"#0c6bc9",
//   histogramField1: "science_citations_Call",
//   histogramField2: "predictionScore",
//   histogramField3: "disruption",
//   histogramField4: "atyp_median_z",

// }
