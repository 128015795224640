import { MatrixTypes2 } from '../../../../types/data';
import _ from 'lodash';

const getCompondFlowData = (historicalFlowList: MatrixTypes2.FlowUnit[], predictedFlowList: MatrixTypes2.FlowUnit[]) => {
  // console.log('\n ------------ getCompondFlowData --------------')
  // console.log('historicalFlowList: ', historicalFlowList, _.sumBy(historicalFlowList, flowItem => flowItem.numPatent))
  // console.log('predictedFlowList: ', predictedFlowList, _.sumBy(predictedFlowList, flowItem => flowItem.numPatent))

  const predictedFlowListDuplicated = _.cloneDeep(predictedFlowList) // to get those flow unit only appear at predictedFlowList

  // console.log('before remove, len(predictedFlowListDuplicated)=', predictedFlowListDuplicated.length)

  // Note
  // historical flow: row 0是没有的
  // prediction flow: 只有row 0有
  // new version: 20230228
  let compondFlowList: MatrixTypes2.CompondFlowUnit[] = []
  const historicalFlowUnitTransformed: MatrixTypes2.CompondFlowUnit[] = historicalFlowList.map((flowUnit) => ({
    cpcGroup: flowUnit.cpcGroup,
    colIdx: flowUnit.colIdx,
    colName: flowUnit.colName,
    rowIdx: flowUnit.rowIdx,
    rowRange: flowUnit.rowRange,
    numPatent: flowUnit.numPatent,
    flowType: 'historical',

    cpcSubsection: flowUnit.cpcSubsection,
    cpcSection: flowUnit.cpcSection,
  }))
  const predictionFlowUnitTransformed: MatrixTypes2.CompondFlowUnit[] = predictedFlowList.map((flowUnit) => ({
    cpcGroup: flowUnit.cpcGroup,
    colIdx: flowUnit.colIdx,
    colName: flowUnit.colName,
    rowIdx: flowUnit.rowIdx,
    rowRange: flowUnit.rowRange,
    numPatent: flowUnit.numPatent,
    flowType: 'predicted',

    cpcSubsection: flowUnit.cpcSubsection,
    cpcSection: flowUnit.cpcSection,
  }))
  compondFlowList = historicalFlowUnitTransformed.concat(predictionFlowUnitTransformed)




  // old version: 20230227: 进行了不必要的遍历（match historical和predicted flow）
  // const compondFlowList: MatrixTypes2.CompondFlowUnit[] = []
  // _.forEach(historicalFlowList, (historicalFlowUnit: MatrixTypes2.FlowUnit) => {
  //   const cpcGroup: string = historicalFlowUnit.cpcGroup
  //   const cpcSubsection: string = historicalFlowUnit.cpcSubsection
  //   const cpcSection: string = historicalFlowUnit.cpcSection
  //   const colIdx: number = historicalFlowUnit.colIdx
  //   const colName: string = historicalFlowUnit.colName
  //   const rowIdx: number = historicalFlowUnit.rowIdx
  //   const rowRange: string = historicalFlowUnit.rowRange
  //   const historicalFlowNumPatent: number = historicalFlowUnit.numPatent
  //   const historicalFlowUnitTransformed: MatrixTypes2.CompondFlowUnit = {
  //     cpcGroup: cpcGroup,
  //     colIdx: colIdx,
  //     colName: colName,
  //     rowIdx: rowIdx,
  //     rowRange: rowRange,
  //     numPatent: historicalFlowNumPatent,
  //     flowType: 'historical',

  //     cpcSubsection: cpcSubsection,
  //     cpcSection: cpcSection,
  //   }

  //   // get predicted flow
  //   let predictedFlowNumPatent: number = Infinity
  //   let predictedFlowUnitTransformed: MatrixTypes2.CompondFlowUnit = {} as MatrixTypes2.CompondFlowUnit
  //   const predictedFlowUnit: MatrixTypes2.FlowUnit = _.find(predictedFlowList, (flowUnit) => (
  //     (flowUnit.cpcSubsection === cpcSubsection)
  //     && (flowUnit.colIdx === colIdx)
  //     && (flowUnit.colName === colName)
  //   )) as MatrixTypes2.FlowUnit
  //   if (!_.isEmpty(predictedFlowUnit)) {
  //     predictedFlowNumPatent = predictedFlowUnit.numPatent
  //     _.remove(predictedFlowListDuplicated, (flowUnit) => (
  //       (flowUnit.cpcSubsection === cpcSubsection)
  //       && (flowUnit.colIdx === colIdx)
  //       && (flowUnit.colName === colName)
  //     ))
  //   } else {
  //     predictedFlowNumPatent = 0
  //   }
  //   predictedFlowUnitTransformed = {
  //     cpcGroup: cpcGroup,
  //     colIdx: colIdx,
  //     colName: colName,
  //     rowIdx: rowIdx,
  //     rowRange: rowRange,
  //     numPatent: predictedFlowNumPatent,
  //     flowType: 'predicted',

  //     cpcSubsection: cpcSubsection,
  //     cpcSection: cpcSection,
  //   }

  //   // TODO: may need sorting
  //   compondFlowList.push(historicalFlowUnitTransformed)
  //   compondFlowList.push(predictedFlowUnitTransformed)
  // })
  // // add flow unit which only appear at predictedFlowList
  // console.log('after remove, len(predictedFlowListDuplicated)=', predictedFlowListDuplicated.length)
  // _.forEach(predictedFlowListDuplicated, (predictedFlowUnit) => {
  //   const predictedFlowUnitTransformed: MatrixTypes2.CompondFlowUnit = {
  //     cpcGroup: predictedFlowUnit.cpcGroup,
  //     colIdx: predictedFlowUnit.colIdx,
  //     colName: predictedFlowUnit.colName,
  //     rowIdx: predictedFlowUnit.rowIdx,
  //     rowRange: predictedFlowUnit.rowRange,
  //     numPatent: predictedFlowUnit.numPatent,
  //     flowType: 'predicted',

  //     cpcSubsection: predictedFlowUnit.cpcSubsection,
  //     cpcSection: predictedFlowUnit.cpcSection,
  //   }
  //   const historicalFlowUnitTransformed: MatrixTypes2.CompondFlowUnit = {
  //     cpcGroup: predictedFlowUnit.cpcGroup,
  //     colIdx: predictedFlowUnit.colIdx,
  //     colName: predictedFlowUnit.colName,
  //     rowIdx: predictedFlowUnit.rowIdx,
  //     rowRange: predictedFlowUnit.rowRange,
  //     numPatent: 0,
  //     flowType: 'historical',

  //     cpcSubsection: predictedFlowUnit.cpcSubsection,
  //     cpcSection: predictedFlowUnit.cpcSection,
  //   }
  //   // TODO: may need sorting
  //   compondFlowList.push(historicalFlowUnitTransformed)
  //   compondFlowList.push(predictedFlowUnitTransformed)
  // })

  // console.log('compondFlowList: ', compondFlowList, _.sumBy(compondFlowList, flowItem => flowItem.numPatent))

  return compondFlowList
}

export default getCompondFlowData;