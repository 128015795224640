import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid as Grid } from 'react-window';
import { Select, Slider, Form, Switch, Space, Spin, ConfigProvider, Button, Tooltip, Breadcrumb } from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import _ from 'lodash';

import { CSVLink, CSVDownload } from "react-csv";

import { PaperInspectionTypes, ResearcherStatisticsTypes } from '../../../../../types/data';
import { GlobalStyle } from '../../../../../types/style';
import ResearcherCard from '../../../common/Visualization/ResearcherCard';
import PaperCard from '../../../common/Visualization/PaperCard';
import LollipopChart from '../../../common/Visualization/LollipopChart';
import ResearcherDetailCard from '../../../common/Visualization/ResearcherDetailCard'

interface ResearcherDetailsProps {
  researcher: ResearcherStatisticsTypes.ResearcherStatisticsUnit;
}

const ResearcherDetails: FC<ResearcherDetailsProps> = (props) => {

  const {researcher} = props

  // console.log('ResearcherDetails researcher:', researcher);
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const {
    setideaFinderSelectedPaper,
    getResearcherBarplotData,
    getSelectedResearcherPaperPatentList,
  } = bindActionCreators(actionCreators, dispatch)

  const selectedResearcherId: ResearcherStatisticsTypes.ResearcherStatisticsUnit = useSelector((state: State) => state.dataReducer?.selectedResearcherId) as ResearcherStatisticsTypes.ResearcherStatisticsUnit
  const selectedResearcherPaperList: PaperInspectionTypes.PaperInspectionUnit[] = useSelector((state: State) => state.dataReducer?.selectedResearcherPaperList) as PaperInspectionTypes.PaperInspectionUnit[]
  const selectedResearcherPaperPatentList: PaperInspectionTypes.PaperPatentInspectionUnit[] = useSelector((state: State) => state.dataReducer?.selectedResearcherPaperPatentList) as PaperInspectionTypes.PaperPatentInspectionUnit[]
  const selectedResearcherBarplotData: PaperInspectionTypes.PaperBarplotData = useSelector((state: State) => state.dataReducer?.selectedResearcherBarplotData) as PaperInspectionTypes.PaperBarplotData
  
  const [isResearcherDetailsViewLoading, setIsResearcherDetailsViewLoading] = useState(false);
  const [paperRankByField, setPaperRankByField] = useState('predictionScore');
  const [paperRankOrder, setPaperRankOrder] = useState('descending');
  const [sortedResearcherPaperList, setSortedResearcherPaperList] = useState<PaperInspectionTypes.PaperInspectionUnit[]>([]);
  const [barplotWidth, setBarplotWidth] = useState(500);
  const [predictionScore, setPredictionScore] = useState<number[]>([0, 100]);
  const [recentYears, setRecentYears] = useState<number[]>([2001, 2024]);
  const [isLeadAuthor, setIsLeadAuthor] = useState(false);
  const [numCompanyPatentCitationRange, setNumCompanyPatentCitation] = useState<number[]>([0, 100]);
  const [onResetTrue, setOnResetTrue] = useState("false"); // State variable to manage the key


  // console.log('selectedResearcherPaperList:', selectedResearcherPaperList);
  
  const handlePaperSelect = (paper: PaperInspectionTypes.PaperInspectionUnit) => {
    // Navigate to IdeaFinderPage with the selected paper
    console.log("paper selected>>>>>>>")
    const updateIdeaFinderSelectedPaper = async () => {
      try {
        setideaFinderSelectedPaper(paper);   
      } catch (error) {
        console.error(error);    
      } finally {
        navigate('/idea-finder');
      }
    }
    updateIdeaFinderSelectedPaper();
  };

  const handlePaperRankByFieldChange = (value: string) => {
    console.log('handlePaperRankByFieldChange', value)
    setPaperRankByField(value)
    setOnResetTrue("false");
  }

  // const handlePaperRankOrderChange = (value: string) => {
  //   // console.log('handlePaperRankOrderChange', value)
  //   setPaperRankOrder(value)
  // }

  const handlePaperRankOrder = (value: boolean) => {
    console.log('--> test handlePaperRankOrder', value)
    if (value) {
      setPaperRankOrder('descending')
    } else {
      setPaperRankOrder('ascending')
    }
    setOnResetTrue("false");
  }

  const onPredictionScoreChange = (value: number | number[]) => {
    setPredictionScore(value as number[])
    setOnResetTrue("false");
  }

  // const onNumCompanyPatentCitationChange = (value: number | number[]) => {
  //   setNumCompanyPatentCitation(value as number[])
  // }

  const onRecentYearsChange = (value: number | number[]) => {
    setRecentYears(value as number[])
    setOnResetTrue("false");
  }

  // const handleLollipopClick = (value: string) => {
  //   console.log(`Lollipop clicked: ${value}`);
  //   setSelectedPaperId(value)
  // }
  const onHighPredScoreChange = (value: boolean) => {
    if (value==true){
      setPredictionScore([80,100])
    } else{
      setPredictionScore([0,100])
    }
    setOnResetTrue("false");
  };

  const onIsLeadAuthorChange = (value: boolean) => {
    setIsLeadAuthor(value);
    setOnResetTrue("false");
  };
  

  useEffect(() => {
      if(selectedResearcherId.empidHash!==''){
        const updateResearcherDetailsView = async () => {
          try {
            // console.log("==> in Researcher View getResearcherCardListData")
            setIsResearcherDetailsViewLoading(true)
            // TODO: 待补充
            // 监测到selectedResearcherId的变化后，调用后端api获得tResearcherBarplotData和ResearcherPaperIDList
            //console.log("RD selectedResearcherId: ", selectedResearcherId)
            await getResearcherBarplotData(selectedResearcherId.empidHash)
            await getSelectedResearcherPaperPatentList(selectedResearcherId.empidHash)
            // console.log('getResearcherPaperIDList:', selectedResearcherPaperList);           
          } catch (error) {
            console.error(error);
            setIsResearcherDetailsViewLoading(false)
          } finally {
            //console.log('Researcher View update finished');
            setIsResearcherDetailsViewLoading(false)            
          }
        }
        updateResearcherDetailsView();
      }
    // fetchData();
  }, [selectedResearcherId])


  useEffect(() => {
    const updateChartWidth = () => {
      const rightDiv = document.querySelector(".right-div") as HTMLElement;
      if (rightDiv) {
        const rightDivWidth = rightDiv.offsetWidth;
        const leftHalfWidth = researcherCardWidth + 20 * 2 + 30; // 360; // Fixed width of the left half
        const chartWidth = rightDivWidth - leftHalfWidth - 48; // Subtract margins
        // console.log('chartWidth:', chartWidth);
        setBarplotWidth(chartWidth);
      }
    };

    // 初始化时更新一次宽度
    updateChartWidth();

    // 当窗口大小变化时更新宽度
    window.addEventListener("resize", updateChartWidth);

    // 在组件卸载时移除resize事件监听器
    return () => {
      window.removeEventListener("resize", updateChartWidth);
    };
  }, []);
  
  const selectedResearcherPatentPaperData:PaperInspectionTypes.PaperPatentInspectionUnit[] = [
    {
      paper_id: "10.1021/cn200065b",
      paper_doi_weblink: "string",
      paper_year_dim: 1,
      paper_title_dim: "string",
      paper_journal_dim: "string",
      num_patent_citations: 1,
      num_university_patent_citations: 1,
      num_company_patent_citations: 1,
      num_other_patent_citations: 1,
      predicted_patentability: 1,
      patent_id: "string",
      applicantAdded_vs_examinerAdded: "string",
      wherefound: "string",
      patent_weblink: "string",
      patent_title: "string",
      patent_application_year: 1,
      patent_grantedyear: 1,
      patent_assignee_name: "string",
      patent_assignee_type: "string",
      patent_assignee_country: "string"
    },
    {
      paper_id: "10.1002/anie.201207603",
      paper_doi_weblink: "string",
      paper_year_dim: 1,
      paper_title_dim: "string",
      paper_journal_dim: "string",
      num_patent_citations: 1,
      num_university_patent_citations: 1,
      num_company_patent_citations: 1,
      num_other_patent_citations: 1,
      predicted_patentability: 1,
      patent_id: "string",
      applicantAdded_vs_examinerAdded: "string",
      wherefound: "string",
      patent_weblink: "string",
      patent_title: "string",
      patent_application_year: 1,
      patent_grantedyear: 1,
      patent_assignee_name: "string",
      patent_assignee_type: "string",
      patent_assignee_country: "string"
    },
    {
      paper_id: "10.1021/jo302230m",
      paper_doi_weblink: "string",
      paper_year_dim: 1,
      paper_title_dim: "string",
      paper_journal_dim: "string",
      num_patent_citations: 1,
      num_university_patent_citations: 1,
      num_company_patent_citations: 1,
      num_other_patent_citations: 1,
      predicted_patentability: 1,
      patent_id: "string",
      applicantAdded_vs_examinerAdded: "string",
      wherefound: "string",
      patent_weblink: "string",
      patent_title: "string",
      patent_application_year: 1,
      patent_grantedyear: 1,
      patent_assignee_name: "string",
      patent_assignee_type: "string",
      patent_assignee_country: "string"
    },
    {
      paper_id: "10.1021/acschembio.8b00661",
      paper_doi_weblink: "string",
      paper_year_dim: 1,
      paper_title_dim: "string",
      paper_journal_dim: "string",
      num_patent_citations: 1,
      num_university_patent_citations: 1,
      num_company_patent_citations: 1,
      num_other_patent_citations: 1,
      predicted_patentability: 1,
      patent_id: "string",
      applicantAdded_vs_examinerAdded: "string",
      wherefound: "string",
      patent_weblink: "string",
      patent_title: "string",
      patent_application_year: 1,
      patent_grantedyear: 1,
      patent_assignee_name: "string",
      patent_assignee_type: "string",
      patent_assignee_country: "string"
    }
  ]
  // console.log('barplotWidth:', barplotWidth);
  var sortedPaperList: PaperInspectionTypes.PaperInspectionUnit[] = selectedResearcherPaperList.slice()
  var sortedPaperPatentData: PaperInspectionTypes.PaperPatentInspectionUnit[]= selectedResearcherPaperPatentList.slice()
  const numCompanyPatentCitationList:number[] = sortedPaperList.map(paper => paper.scisciIndex.numCompanyPatentCitations);
  const numCompanyPatentCitationMax = Math.max(...numCompanyPatentCitationList); 
  // display only selected paper in timeplot
  // sortedPaperList = sortedPaperList.filter(paper =>{
  // const paperID = paper.demographicInfo.paperDoi;
  // return paperID == selectedPaperId;
  // });

  // filter by is_lead_author
  if (isLeadAuthor){
    const leadAuthorPaperIds = selectedResearcherBarplotData.paper_list
    .filter(paper => paper.is_lead_author === 1).map(paper => paper.paper_id);
    // convert to set for more efficient lookup 
    const leadAuthorPaperIdsSet = new Set(leadAuthorPaperIds);

    sortedPaperList =  sortedPaperList.filter(paper =>{
      return leadAuthorPaperIdsSet.has(paper.demographicInfo['paperDoi']);
    });
  }

  // filter by prediction score
  sortedPaperList =  sortedPaperList.filter(paper =>{
    const predScore = paper.scisciIndex.predictionScore;
    if(predictionScore[0] == predictionScore[1]){
      return predScore >= (predictionScore[0]/100) && predScore < ((predictionScore[0]+1)/100);
    }
    else{
      return predScore >= (predictionScore[0]/100) && predScore <= (predictionScore[1]/100);
    }
  });

  // console.log("numCitations")
  // console.log(sortedPaperList.map(paper => paper.scisciIndex.numCompanyPatentCitations))
  // filter by num company patent citation
  sortedPaperList =  sortedPaperList.filter(paper =>{
    const numCompanyPatentCitations = paper.scisciIndex.numCompanyPatentCitations;
    return numCompanyPatentCitations >= numCompanyPatentCitationRange[0] && numCompanyPatentCitations <= numCompanyPatentCitationRange[1];
  });
  // filter by recent years
  // const currDate = new Date();
  // //console.log("date: ", currDate.getMonth()+1, currDate.getFullYear())
  // const minYear = (2024+1)-recentYears;
  // const maxYear = 2024;
  // sortedPaperList =  sortedPaperList.filter(paper =>{
  //   const recYear = paper.demographicInfo.paperYear;
  //   return recYear >= minYear && recYear <= maxYear;
  // });
  sortedPaperList =  sortedPaperList.filter(paper =>{
    const paperYear = paper.demographicInfo.paperYear;
    return paperYear >= recentYears[0] && paperYear <= recentYears[1];
  });
 
  console.log('\n\n\npaperRankByField:', paperRankByField)
  console.log('sortedPaperList:', sortedPaperList) 

  // rank by prediction score
  if(paperRankByField === 'predictionScore'){
    sortedPaperList = sortedPaperList.sort(function(a, b){
      if(paperRankOrder === 'ascending'){
        return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
      }
      else{
        // descending
        return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
      }
    })
  }

  // rank by patent citations call
  else if(paperRankByField === 'patentCitationsCall'){
    console.log('-------paperRankByField')
    console.log('sortedPaperList:', sortedPaperList)
    sortedPaperList = sortedPaperList.sort(function(a, b){
      if(paperRankOrder === 'ascending'){
        return (b.scisciIndex.patentCitationsCall < a.scisciIndex.patentCitationsCall) ? 1 : (b.scisciIndex.patentCitationsCall > a.scisciIndex.patentCitationsCall)? -1 : 0;
      }
      else{
        // descending
        return (b.scisciIndex.patentCitationsCall > a.scisciIndex.patentCitationsCall) ? 1 : (b.scisciIndex.patentCitationsCall < a.scisciIndex.patentCitationsCall)? -1 : 0;
      }
    })
    // sortedPaperList = sortedPaperList.sort(function(a, b){
    //   if(paperRankOrder === 'ascending'){
    //     return (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall)? -1 : 0;
    //   }
    //   else{
    //     // descending
    //     return (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall)? -1 : 0;
    //   }
    // })
  } 
  
  // rank by prediction score
  if(paperRankByField === 'numCompanyPatentCitations'){
    sortedPaperList = sortedPaperList.sort(function(a, b){
      if(paperRankOrder === 'ascending'){
        return (b.scisciIndex.numCompanyPatentCitations < a.scisciIndex.numCompanyPatentCitations) ? 1 : (b.scisciIndex.numCompanyPatentCitations > a.scisciIndex.numCompanyPatentCitations)? -1 : 0;
      }
      else{
        // descending
        return (b.scisciIndex.numCompanyPatentCitations > a.scisciIndex.numCompanyPatentCitations) ? 1 : (b.scisciIndex.numCompanyPatentCitations < a.scisciIndex.numCompanyPatentCitations)? -1 : 0;
      }
    })
  }
  else {
    //paperRankByField === 'prediction score'
    sortedPaperList = sortedPaperList.sort(function(a, b){
      if(paperRankOrder === 'ascending'){
        return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
      }
      else{
        // descending
        return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
      }
    })
  }
  // setSortedResearcherPaperList(sortedPaperList)

  const sortedPaperIDList = sortedPaperList.map(paper => paper.paper_mag_id)
  const sortedPaperIdsSet = new Set(sortedPaperIDList)
  sortedPaperPatentData = sortedPaperPatentData.filter(paper => {
    return sortedPaperIdsSet.has(paper.paper_id);
  });

  // Download data
  const lead_author_paper = isLeadAuthor?"lead_author_paper":"all_paper";
  const csvFilename = `${researcher.empidHash}_${researcher.demographicInfo['fullName']}_${predictionScore[0]}_${predictionScore[1]}(${recentYears[0]}-${recentYears[1]})_${lead_author_paper}.csv`

  const handleResetFiltersClick = () => {
    setPaperRankByField('numCompanyPatentCitations');
    setPaperRankOrder('descending');
    setPredictionScore([0, 100]);
    setRecentYears([2001, 2024]);
    setIsLeadAuthor(false);
    setNumCompanyPatentCitation([0, 100]);
    setOnResetTrue("true");
  }
  // 12345_Amy Potter_0_100(2001-2023)_lead_author_paper.csv, 12345_Amy Potter_0_100(2001-2023)_all_paper.csv
  const handleDownloadClick = () => {
    const csvLink = document.getElementById('hidden-csv-link');
    if (csvLink) {
      csvLink.click();
    } else {
      console.error('The CSV link element was not found.');
    }
  };

  // const paperCardWidth = 440; // 固定卡片宽度
  const paperCardHeight = 180; // 固定卡片高度

  const researcherCardWidth = 480; // 固定卡片宽度
  const researcherCardHeight = 274; // 固定卡片高度
  const gap = 20; // 卡片之间的间隔

  const yearMarks = {
    2001: '2001', 
    // 2011: '2011',
    2024: '2024', 
  };
  const predictionScoreMarks = {
    0: '0',
    100: '100'
  }

  var numCompanyPatentCitationsMarks = {
    0: '0',
    // numCompanyPatentCitationMax: numCompanyPatentCitationMax.toString(),
    100: '100'
  }


  // set paper card width
  const paperCardParentDivRef = React.useRef<HTMLDivElement>(null);
  const [paperCardParentDivWidth, setPaperCardParentDivWidth] = React.useState(0);
  React.useEffect(() => {
    if (paperCardParentDivRef.current) {
      setPaperCardParentDivWidth(paperCardParentDivRef.current.offsetWidth);
    }
  }, []);
  const paperCardMargin = 16
  const numPaperCardsPerRowDict: { [windowWidth: number]: number} = {
    600: 1, 
    900: 2, 
    1240: 3, 
    1920: 4, 
    2560: 5, 
  }
  let numPaperCardsPerRow = 1; // Default value
  for (const width in numPaperCardsPerRowDict) {
    if (paperCardParentDivWidth >= parseInt(width)) {
      numPaperCardsPerRow = numPaperCardsPerRowDict[width];
      console.log('numPaperCardsPerRow: ', numPaperCardsPerRow);
    }
  }
  const paperCardWidth = (paperCardParentDivWidth - numPaperCardsPerRow * (paperCardMargin + 8) * 2) / numPaperCardsPerRow;
  console.log("paperCardPerRow")
  console.log(numPaperCardsPerRow)
  console.log('numPaperCardsPerRow:', numPaperCardsPerRow);
  console.log('paperCardParentDivWidth:', paperCardParentDivWidth);

  const paperCardList: JSX.Element[] = sortedPaperList.map((paperCard, index) => {
    // const numPaperCardsPerRow = Math.floor(paperCardParentDivWidth / (paperCardWidth_min + 2 * paperCardMargin));
    return (
      <PaperCard
        key={sortedPaperList[index].paper_mag_id}
        viewName='People-Finder'
        viewParam={{ width: paperCardWidth, height: paperCardHeight, margin: 20, padding: 4 }}
        PaperInspectionData={sortedPaperList[index]}
        onClick={() => handlePaperSelect(sortedPaperList[index])}
      />
    )
  })

  return (
    <div className="researcher-details-container">
    <ConfigProvider
          theme={{
            token:{
              colorPrimary: '#404040',
              borderRadius: 14,
              colorText: '#482476',
              fontSize: 12,
            },
            components: {
              Form: {
                labelFontSize: 16,
                itemMarginBottom: 6,
              },
              Slider: {
                handleColor: '#A495C3',
                handleActiveColor: '#A495C3',
                handleColorDisabled: '#A495C3',
                handleSize: 6,
                handleSizeHover: 8,
                railBg:  '#F7F7F7',
                railSize: 10,
                trackBg: '#A495C3',
                trackHoverBg: '#836EAA',
              },
              Switch: {
                handleBg: "#9380B6",
                trackMinWidthSM: 50
              },
            }
          }}
        >
    <Spin tip="Loading" spinning={isResearcherDetailsViewLoading} size='large' className='researcher-details-custom-spin'>
      {!isResearcherDetailsViewLoading ? (
        <>
          {/* <div className="basic-info-header">
            <h2>Basic Information</h2>
          </div> */}
          <div className='researcher-details-card'>
            <ResearcherDetailCard 
              viewParam={{ width: researcherCardWidth, height: researcherCardHeight, margin: 20, padding: 4 }}
              ResearcherStatisticsData={researcher}
              boxShadowStyle="0 4px 10px 0 rgba(0, 0, 0, 0.2)"
              divOutlineColor="#e9e7f2"
            />
          </div>
          {/* <div className="basic-info">
            <div className="info-card">
              <ResearcherCard
                viewParam={{ width: researcherCardWidth, height: researcherCardHeight, margin: 20, padding: 4 }}
                ResearcherStatisticsData={researcher}
                boxShadowStyle="0 4px 10px 0 rgba(0, 0, 0, 0.2)"
                divOutlineColor="#e9e7f2"
              />
            </div>
            <div className="bar-plot">
              <LollipopChart paperList={selectedResearcherBarplotData.paper_list} chartHeight={researcherCardHeight} chartWidth={barplotWidth}/>
            </div>
          </div> */}
          <div key={onResetTrue} className="papers-section">
            <div className="papers-header">
              Papers: {sortedPaperList.length}
            </div>
            {/* <div className="papers-header-filter"> */}
              <div className="paper-filter-part">
                <div className='paper-filter-part-top' style={{width: "100%"}}>
                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "30px", marginRight: 20}}>Year: </div>
                    <div className='filter-value' style={{width: "60%", display: 'flex', flexDirection: 'row'}}>
                      <Slider
                          range
                          defaultValue={recentYears}
                          min={2001}
                          // min = {2011}
                          max = {2024}
                          style={{ marginLeft: 0, marginTop: 0, width: "100%"}}
                          marks={yearMarks}
                          onChangeComplete={onRecentYearsChange}
                      />
                    </div>
                  </div>

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "100px", marginRight: 20}}>Prediction Score: </div>
                    <div className='filter-value' style={{width: "40%", display: 'flex', flexDirection: 'row'}}>
                      <Slider
                        range
                        defaultValue={predictionScore}
                        min = {0}
                        max = {100}
                        style={{ marginLeft:0, marginTop: 0, width: "100%" }}
                        marks={predictionScoreMarks}
                        onChangeComplete={onPredictionScoreChange}
                      />
                    </div>
                  </div>

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "140px", marginRight: 20}}>{`High Prediction Score (>= 80):`}</div>
                    <div className='filter-value' style={{width: "25%", marginTop: 5, display: 'flex', flexDirection: 'row'}}>
                      <Switch 
                        checkedChildren="yes" 
                        unCheckedChildren="no" 
                        style={{ width: "100%", marginLeft: 0, marginTop: 0}}
                        className="custom-switch"
                        onChange={onHighPredScoreChange}
                        />
                    </div>
                  </div>

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "80px", marginRight: 20}}>Lead Author: </div>
                    <div className='filter-value' style={{width: "25%", marginTop: 5, display: 'flex', flexDirection: 'row'}}>
                      <Switch 
                        checkedChildren="yes" 
                        unCheckedChildren="no" 
                        defaultChecked={false}
                        // defaultChecked
                        style={{ width: "100%", marginLeft: 0, marginTop: 0}}
                        className="custom-switch"
                        onChange={onIsLeadAuthorChange}
                        />
                    </div>
                  </div>
                </div>

                <div className='paper-filter-part-bottom' style={{width: "100%"}}>
                  {/* <div className='filter-div' style={{width: "55%", marginRight: "0px"}}>
                    <div className='filter-label' style={{width: "190px", marginRight: 20}}>Num Company Patent Citation: </div>
                    <div className='filter-value' style={{width: "53%", display: 'flex', flexDirection: 'row'}}>
                      <Slider
                        range
                        defaultValue={numCompanyPatentCitationRange}
                        min = {0}
                        max = {numCompanyPatentCitationMax}
                        style={{ marginLeft:0, marginTop: 0, width: "100%" }}
                        marks={numCompanyPatentCitationsMarks}
                        onChangeComplete={onNumCompanyPatentCitationChange}
                      />
                    </div>
                  </div> */}

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "60px", marginRight: 20}}>Rank By: </div>
                    <div className='filter-value' style={{width: "100%",  marginTop: 5, display: 'flex', flexDirection: 'row'}}>
                      <Select
                        style={{ marginLeft:0, marginTop: 0, width: "100%" }}
                        placeholder="RankBy"
                        onChange={handlePaperRankByFieldChange}
                        options={[
                          { value: 'numCompanyPatentCitations', label: 'numCompanyPatentCitations' }, 
                          { value: 'predictionScore', label: 'predictionScore' },
                          // { value: 'patentCitationsCall', label: 'patentCitationsCall' },
                        ]}
                        defaultValue={'predictionScore'}
                      />
                    </div>
                  </div>

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "80px", marginRight: 20}}>Rank Order: </div>
                    <div className='filter-value' style={{width: "25%", marginTop: 5, display: 'flex', flexDirection: 'row'}}>
                      <Switch 
                        checkedChildren="desc" 
                        unCheckedChildren="asc" 
                        // checkedChildren="asc" 
                        // unCheckedChildren="desc" 
                        defaultChecked
                        style={{ marginLeft:0, marginTop: 0, width: "100%" }}
                        className="custom-switch"
                        onChange={handlePaperRankOrder}
                      />
                    </div>
                  </div>
                        
                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "80px", marginRight: 20}}>Reset Filters: </div>
                    <div className='filter-value' style={{width: "25%", marginTop: 5, display: 'flex', flexDirection: 'row'}}>
                    <Button type="primary" 
                        style={{ marginLeft:0, marginTop: 0, width: "100%" }}
                        onClick={handleResetFiltersClick}
                        className='reset-button'
                        size = 'small'
                    >
                      Reset
                    </Button>
                    </div>
                  </div>

                  <div className='filter-div' style={{width: "25%", marginRight: "15px"}}>
                    <div className='filter-label' style={{width: "180px", marginRight: 0}}>Download Raw Data Table: </div>
                    <div className="download-value" style={{width: "25%", marginTop: 0, display: 'flex', flexDirection: 'row'}}>
                      <Button type="primary" 
                        style={{ marginLeft:0, marginTop: 5, width: "50%" }}
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadClick}
                        className='download-button'
                        size = 'small'
                        />
                      <CSVLink
                        data={sortedPaperPatentData}
                        filename={csvFilename}
                        className="csv-link"
                        id="hidden-csv-link"
                        target="_blank"
                      />
                    </div>  
                  </div>

                </div>
              </div>            
            <div className="papers-list" ref={paperCardParentDivRef}>
              {paperCardList}
            </div>
          </div>
        </>
      ) : null}
    </Spin>
    </ConfigProvider>
    </div>
  );
};

export default ResearcherDetails;