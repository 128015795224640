import { MatrixTypes2 } from '../../../../types/data';
import _ from 'lodash';

const getViolinPlotXRange = (matrixData: MatrixTypes2.CellUnit[]) => {
  const papermetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict = {}
  _.forEach(matrixData, (cellData) => {
    const glyphData = cellData.glyphData
    _.forEach(glyphData, (metriceData) => {
      const metriceName: string = metriceData.measureName
      const lineLower: number = metriceData.lineLower
      const lineUpper: number = metriceData.lineUpper
      if (_.has(papermetriceRangeDict, metriceName)) {
        if (lineLower < papermetriceRangeDict[metriceName][0]) papermetriceRangeDict[metriceName][0] = lineLower
        if (lineUpper > papermetriceRangeDict[metriceName][1]) papermetriceRangeDict[metriceName][1] = lineUpper
      } else {
        papermetriceRangeDict[metriceName] = [lineLower, lineUpper]
      }
    })
  })
  return papermetriceRangeDict
}

export default getViolinPlotXRange;