import { MatrixTypes2 } from '../../../../types/data';
import _ from 'lodash';

// const transformPatentTreemapData = (hierarchyData: MatrixTypes2.TreemapUnit[]) => {
//   const patentList: MatrixTypes2.PatentTreemapFlattenUnit[] = []
//   _.forEach(hierarchyData, (cpcSectionData: MatrixTypes2.TreemapUnit) => {
//     const cpcSection: string = cpcSectionData.cpcSection
//     const cpcSubsectionList = cpcSectionData.cpcSubsectionList
//     _.forEach(cpcSubsectionList, (cpcSubsectionData: MatrixTypes2.CPCSubsectionUnit) => {
//       patentList.push({
//         cpcSection: cpcSection,
//         cpcSubsection: cpcSubsectionData.cpcSubsection,
//         numPatent: cpcSubsectionData.numPatent,
//       })
//     })
//   })
//   return patentList
// }

const getCompondPatentTreemapData = (historicalTreemapData: MatrixTypes2.TreemapUnit[], predictedTreemapData: MatrixTypes2.TreemapUnit[]) => {

  // console.log('========= getCompondPatentTreemapData() ===========')
  // console.log('historicalTreemapData: ', historicalTreemapData)
  // console.log('predictedTreemapData: ', predictedTreemapData)

  // const historicalTreemapDataFlatten: MatrixTypes2.PatentTreemapFlattenUnit[] = transformPatentTreemapData(historicalTreemapData)
  // const predictedTreemapDataFlatten: MatrixTypes2.PatentTreemapFlattenUnit[] = transformPatentTreemapData(predictedTreemapData)

  const compondPatentTreemap: MatrixTypes2.CompondTreemapUnit[] = []
  _.forEach(historicalTreemapData, (historicalDataUnit: MatrixTypes2.TreemapUnit) => {
    const cpcGroup: string = historicalDataUnit.cpcGroup
    const cpcSection: string = historicalDataUnit.cpcSection
    const cpcSubsection: string = historicalDataUnit.cpcSubsection
    const historicalNumPatent: number = historicalDataUnit.numPatentCitation

    const predictedDataUnit: MatrixTypes2.TreemapUnit = _.find(predictedTreemapData, (dataUnit) => (
      (dataUnit.cpcGroup === cpcGroup)
      // && (dataUnit.cpcSection === cpcSection)
      // && (dataUnit.cpcSubsection === cpcSubsection)
      )
    ) as MatrixTypes2.TreemapUnit
    if (!_.isEmpty(predictedDataUnit)) {
      const predictionNumPatent: number = predictedDataUnit.numPatentCitation
      const numPatentTotal: number = historicalNumPatent + predictionNumPatent
      compondPatentTreemap.push({
        cpcGroup: cpcGroup,
        cpcSection: cpcSection,
        cpcSubsection: cpcSubsection,
        numPatentCitation: numPatentTotal,
        numPatentCitationDict: {
          historicalNumPatentCitation: historicalNumPatent,
          predictedNumPatentCitation: predictionNumPatent,
        }
      })
    } else {
      compondPatentTreemap.push({
        cpcGroup: cpcGroup,
        cpcSection: cpcSection,
        cpcSubsection: cpcSubsection,
        numPatentCitation: historicalNumPatent,
        numPatentCitationDict: {
          historicalNumPatentCitation: historicalNumPatent,
          predictedNumPatentCitation: 0,
        }
      })
    }
  })

  // console.log('compondPatentTreemap: ', compondPatentTreemap)

  return compondPatentTreemap
}

export default getCompondPatentTreemapData;