// These are functions that dispatch actions

import { ActionType } from "../action-types";
import { Dispatch } from "redux";
import { Action } from "../actions/index";

import { MatrixTypes2, PaperInspectionTypes, PatentInspectionTypes, InteractionTypes, ResearcherStatisticsTypes, TooltipTypes, QueryViewTypes, UserManagementTypes } from '../../types/data';
import { message } from 'antd';

import {
  getTimeLineDataAPI,
  getMatrixDataAPI,
  getScatterPlotDataAPI,
  getResearcherStatisticsDataAPI,
  getPatentDataAPI,
  getPaperInspectionDataAPI,
  getPredictionResultAPI,
  updateSelectedPatentAssigneeAPI,
  getSelectedResearcherIDListAPI,
  getResearcherScatterplotDataAPI,
  getResearcherCardListDataAPI,
  // getSelectedResearcherIdListAPI,
  // getSelectedCPCFieldMatrixDataAPI,
  getSelectedPaperPatentDataAPI,

  // loadMsgAPI,
  getBackendDataAPI,
  getPaperCardListDataAPI,
  getTopKPaperCardListDataAPI,
  getSelectedPaperIDListAPI,
  getSelectedResearcherPaperListDataAPI,
  getSelectedResearcherBarplotDataAPI, // test

  // userAPI
  getLoginResultAPI,
  getRegisterResultAPI,
  getDeleteAccountResultAPI
  // getNameAPI,
} from "../../router/api";

// test
export const getBackendData = (params: number) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_BACKEND_DATA,
      payload: await getBackendDataAPI(params),
    });
  };
};


export const performLogin = (params: UserManagementTypes.UserLoginParams) => {  return async function (dispatch: Dispatch<Action>) {
  const result = await getLoginResultAPI(params);
  if (result.access_token) {
    sessionStorage.setItem('token', result.access_token);
    if (params.remember) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('token', result.access_token)
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('token');
    }
  }
  if (result.msg) {
    message.error(result.msg);
  }
  
  dispatch({
    type: ActionType.PERFORM_LOGIN,
    error: result.msg,
    isAuthenticated: result.is_authenticated,
    token: result.access_token,
  })
}}

export const performLogout = () => { 
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.PERFORM_LOGOUT,
      error: null,
      isAuthenticated: false,
      token: null
    });
  };
}

export const performRegister = (params: UserManagementTypes.UserRegisterParams) => {return async function (dispatch: Dispatch<Action>){
  const result = await getRegisterResultAPI(params);
  if (result.msg) {
    message.error(result.msg);
  }
  dispatch({
    type:ActionType.PERFORM_REGISTER,
    error: result.msg,
    registerStatus: result.status,
  })
}}

export const performDeleteAccount = (params: UserManagementTypes.UserLoginParams) => {return async function (dispatch: Dispatch<Action>){
  const result = await getDeleteAccountResultAPI(params);
  if (result.msg) {
    message.error(result.msg);
  }
  dispatch({
    type:ActionType.PERFORM_DELETE_ACCOUNT,
    error: result.msg,
    deleteStatus: result.status,
  })
}}


// 1. data
// export const updateInterplayViewDataByTreemapSelection = (
//   selectedCPCFieldCode: string,
//   selectedCPCFieldLevel: string,
//   paperYearRange: number[],
//   patentYearRange: number[],
//   paperFieldLevel: string,
//   fieldL0Name: string,
//   researcherIdList: string[],
//   ) => {
//   return async function (dispatch: Dispatch<Action>) {
//     dispatch({
//       type: ActionType.UPDATE_INTERPLAY_VIEW_DATA_BY_TREEMAP_SELECTION,
//       selectedCPCFieldCode: selectedCPCFieldCode,
//       selectedCPCFieldLevel: selectedCPCFieldLevel,
//       selectedCPCFieldMatrixData: await getMatrixDataAPI(
//         selectedCPCFieldCode,
//         selectedCPCFieldLevel,
//         paperYearRange,
//         patentYearRange,
//         paperFieldLevel,
//         fieldL0Name,
//         researcherIdList,
//         ),
//       paperFieldLevel: paperFieldLevel,
//     })
//   }
// }

export const updateSelectedPaperCellList = (data: InteractionTypes.SelectedPaperCellInfo[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SELECTED_PAPERCELL_LIST,
      selectedPaperCellList: data,
    })
  }
}
export const updateSelectedCPCCodeList = (data: InteractionTypes.SelectedCPCCodeInfo[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SELECTED_CPC_CODE_LIST,
      selectedCPCCodeList: data,
    })
  }
}
export const getPredictionResult = (
  selectedCPCFieldCode: string,
  selectedCPCFieldLevel: string,
  paperYearRange : number[],
  patentYearRange: number[],
  paperFieldLevel: string,
  fieldL0Name: string,
  researcherIdList: string[],
  predictionScoreRange: number[],
  matrixCellPaperIdList: MatrixTypes2.MatrixCellPaperIdListUnit[],
  historicalPatentTreemapData: MatrixTypes2.TreemapUnit[],
  ) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_PREDICTION_RESULT,
      predictionResult: await getPredictionResultAPI(
        selectedCPCFieldCode,
        selectedCPCFieldLevel,
        paperYearRange,
        patentYearRange,
        paperFieldLevel,
        fieldL0Name,
        researcherIdList,
        predictionScoreRange,
        matrixCellPaperIdList
      ),
      historicalPatentTreemapData: historicalPatentTreemapData,
    })
  }
}
export const updateSelectedResearcherIdList = (param: string[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SELECTED_RESEARCHER_ID_LIST,
      selectedResearcherIdList: param,
    });
  };
};
export const updateLeftViewSelectedResearcherIdList = (param: string[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LEFT_VIEW_SELECTED_RESEARCHER_ID_LIST,
      leftViewSelectedResearcherIdList: param,
    });
  };
};
export const updateScatterplotLassoMark = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SCATTERPLOT_LASSO_MARK,
      scatterplotLassoMark: param,
    });
  };
};
export const getMatrixData = (
  selectedCPCFieldCode: string,
  selectedCPCFieldLevel: string,
  paperYearRange : number[],
  patentYearRange: number[],
  paperFieldLevel: string,
  fieldL0Name: string,
  researcherIdList: string[],
  selectedPatentAssigneeID: string,
) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_MATRIX_DATA,
      payload: await getMatrixDataAPI(
        selectedCPCFieldCode,
        selectedCPCFieldLevel,
        paperYearRange,
        patentYearRange,
        paperFieldLevel,
        fieldL0Name,
        researcherIdList,
        selectedPatentAssigneeID,
      ),
    });
  };
};
export const getTimeLineData = (
  selectedCPCFieldCode: string,
  selectedCPCFieldLevel: string,
  paperYearRange : number[],
  patentYearRange: number[],
  paperFieldLevel: string,
  fieldL0Name: string,
  researcherIdList: string[],
  selectedPatentAssigneeID: string,
  ) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_TIMELINE_DATA,
      payload: await getTimeLineDataAPI(
        selectedCPCFieldCode,
        selectedCPCFieldLevel,
        paperYearRange,
        patentYearRange,
        paperFieldLevel,
        fieldL0Name,
        researcherIdList,
        selectedPatentAssigneeID,
        ),
    })
  }
};
export const getScatterplotData = (params: string[]) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_SCATTERPLOT_DATA,
      payload: await getScatterPlotDataAPI(params),
    });
  };
};
export const getResearcherStatisticsData = (params: string[]) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_RESEARCHER_STATISTICS_DATA,
      payload: await getResearcherStatisticsDataAPI(params),
    });
  };
};

export const getPatentInspectionData = (params: PatentInspectionTypes.PatentApiParams) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_PATENT_INSPECTION_DATA,
      payload: await getPatentDataAPI(params),
    });
  };
};

export const updateSunburstSelectedPatentData = (params: string) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.UPDATE_SUNBURST_SELECTED_PATENT_LIST,
      selectedPatentAssigneeID: params,
    });
  };
};
export const getAllResearcherIdList = (params: string[]) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_ALL_RESEARCHER_ID_LIST,
      allResearcherIdList: params,
    });
  };
};
// export const updateSunburstSelectedPatentData = (params: string) => {
//   return async function (dispatch: Dispatch<Action>) {
//     dispatch({
//       type: ActionType.UPDATE_SUNBURST_SELECTED_PATENT_LIST,
//       payload: await updateSelectedPatentAssigneeAPI(params),
//     });
//   };
// };

export const getPaperInspectionData = (params: PaperInspectionTypes.PaperApiParams) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_PAPER_INSPECTION_DATA,
      payload: await getPaperInspectionDataAPI(params),
    });
  };
};
export const updateBrushedPaperList = (param: PaperInspectionTypes.PaperInspectionUnit[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_BRUSHED_PAPER_LIST,
      brushedPaperList: param,
    });
  };
};
export const updateAllSubviewPaperList = (param: PaperInspectionTypes.SubViewPaperData) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_All_SUBVIEW_PAPER_LIST,
      allSubviewPaperList: param,
    });
  };
};
export const updatePaperBrushedRange = (param: PaperInspectionTypes.histogramBrushedRange) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PAPER_BRUSHED_RANGE,
      brushedRange: param,
    });
  };
};
export const updateSelectedResearcherGroupList = (param: ResearcherStatisticsTypes.ResearcherStatisticsUnit[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SELECTED_RESEARCHER_GROUP_LIST,
      selectedResearcherGroupList: param,
    });
  };
};
export const updateSelectedResearcherCategoryList = (param: ResearcherStatisticsTypes.SelectedCategory[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SELECTED_RESEARCHER_CATEGORY_LIST,
      selectedResearcherCategoryList: param,
    });
  };
};


// loading related
export const updateResearcherOverviewLoading = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_RESEARCHER_OVERVIEW_LOADING,
      isResearcherOverviewLoading: param,
    });
  };
};
export const updateResearcherStatisticViewLoading = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_RESEARCHER_STATISTIC_VIEW_LOADING,
      isResearcherStatisticViewLoading: param,
    });
  };
};
export const updateTechnologyInspectionViewLoading = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TECH_INSPECTION_VIEW_LOADING,
      isTechnologyInspectionViewLoading: param,
    });
  };
};
export const updateScienceInspectionViewLoading = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SCIENCE_INSPECTION_VIEW_LOADING,
      isScienceInspectionViewLoading: param,
    });
  };
};
export const updateMainviewLoading = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_MAINVIEW_LOADING,
      isMainviewLoading: param,
    });
  };
};


// 2. ui
export const updateTreemapTooltipData = (param: {
  tooltipData: TooltipTypes.TooltipData,
  tooltipPosition: TooltipTypes.TooltipPosition,
  tooltipOpacity: number;
}) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TREEMAP_TOOLTIP_DATA,
      tooltipData: param,
    })
  }
}
export const updateTopKFlow = (param: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TOP_K_FLOW,
      topKFlow: param,
    })
  }
}
export const updateShowedMinTreemapLevel = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SHOWED_MIN_TREEMAP_LEVEL,
      showedMinTreemapLevel: param
    })
  }
}
export const updateShowPredictionResult = (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SHOW_PREDICTION_RESULT,
      showPredictionResult: param
    })
  }
}
export const updateHighlightedPaperCellRowZeroList = (param: MatrixTypes2.HighlightedPaperMatrixRowZeroCellUnit[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_HIGHLIGHTED_PAPER_CELL_ROW_ZERO_LIST,
      selectedPaperMatrixRowZeroCellList: param
    })
  }
}
export const updatePredictionScoreRange = (param: number[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PREDICTION_SCORE_RANGE,
      predictionScoreRange: param
    })
  }
}
export const updatePatentTimeRange = (param: number[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PATENT_TIMERANGE,
      timeRange: param
    })
  }
}
export const updatePaperTimeRange = (param: number[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PAPER_TIMERANGE,
      timeRange: param
    })
  }
}

export const updateMatrixCellPositionData = (param: MatrixTypes2.MatrixPosition) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_MATRIX_CELL_POSITION_DATA,
      matrixPositionData: param,
    });
  };
};
export const updateMatrixDataRange = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_MATRIX_DATA_RANGE,
      matrixDataRange: param
    })
  }
}
export const updatePaperFieldLevel = (fieldLevel: string, fieldL0Name: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PAPER_FIELD_LEVEL,
      paperFieldLevel: fieldLevel,
      paperFieldL0Name: fieldL0Name,
    })
  }
}
export const updateTopKPatentAssignee = (param: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TOP_K_PATENT_ASSIGNEE,
      topKPatentAssignee: param,
    })
  }
}

// beta2024

export const setIsLoginPage= (param: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_IS_LOGIN_PAGE,
      isLoginPage: param,
    });
  };
};

export const setResearcherRankByField = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_RESEARCHER_RANK_BY_FIELD,
      researcherRankByField: param,
    });
  };
};

export const setResearcherRankOrder = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_RESEARCHER_RANK_ORDER,
      researcherRankOrder: param,
    });
  };
};

export const setSearchFacultyKeyword = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SEARCH_FACULTY_KEYWORD,
      searchFacultyKeyword: param,
    });
  };
};

export const getSelectedResearcherIDList = (param: QueryViewTypes.QueryConfirmParams) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_SELECTED_RESEARCHER_ID_LIST,
      selectedResearcherIDList: await getSelectedResearcherIDListAPI(param),
    })
  }
};

export const getResearcherScatterplotData = (param: string[]) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_RESEARCHER_SCATTERPLOT_DATA,
      researcherScatterplotData: await getResearcherScatterplotDataAPI(param),
    })
  }
};

export const getResearcherCardListData = (param: QueryViewTypes.ResearcherCardListRetrieveParams) => {  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_RESEARCHER_CARD_LIST_DATA,
      researcherCardStatisticsData: await getResearcherCardListDataAPI(param),
    })
  }
};

export const setPaperRankByField = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PAPER_RANK_BY_FIELD,
      paperRankByField: param,
    });
  };
};

export const setPaperRankOrder = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PAPER_RANK_ORDER,
      paperRankOrder: param,
    });
  };
};

export const setSearchPaperKeyword = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SEARCH_PAPER_KEYWORD,
      searchPaperKeyword: param,
    });
  };
};

export const getSelectedPaperIDList = (param: QueryViewTypes.PaperQueryConfirmParams) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_SELECTED_PAPER_ID_LIST,
      selectedPaperIDList: await getSelectedPaperIDListAPI(param),
    })
  }
};

export const getPaperCardListData = (param: string[]) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_PAPER_CARD_LIST_DATA,
      paperCardListData: await getPaperCardListDataAPI(param),
    })
  }
};

export const getTopKPaperCardListData = (param:  PaperInspectionTypes.PaperCardListRetrieveParams) => {
  console.log("getTopKPaperCardListData: ", param)
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_TOPK_PAPER_CARD_LIST_DATA,
      paperCardInspectionData: await getTopKPaperCardListDataAPI(param),
    })
  }
};

export const setSelectedResearcherID = (param: ResearcherStatisticsTypes.ResearcherStatisticsUnit | null) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_RESEARCHER_ID,
      selectedResearcherID: param,
    });
  };
};

export const setSelectedAuthorID = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_AUTHOR_ID,
      selectedAuthorID: param,
    });
  };
};

// export const getResearcherPaperIDList = (param: string) => {
//   return async function (dispatch: Dispatch<Action>) {
//     dispatch({
//       type: ActionType.GET_RESEARCHER_PAPER_LIST_DATA,
//       selectedResearherPaperList: await getSelectedResearcherPaperListDataAPI(param),
//     })
//   }
// };

export const getSelectedResearcherPaperPatentList = (param: string) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_SELECTED_RESEARCHER_PAPER_PATENT_LIST_DATA,
      selectedResearcherPaperPatentList: await getSelectedResearcherPaperListDataAPI(param),
    });
  }
};

export const getResearcherBarplotData = (param: string) => {
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_RESEARCHER_BARPLOT_DATA,
      researcherBarplotData: await getSelectedResearcherBarplotDataAPI(param),
    })
  }
};

export const updateResearcherCardListFilter = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_RESEARCHER_CARD_LIST_FILTER,
      untapFilter: param
    })
  }
}

export const setSelectedPaperID = (param: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_PAPER_ID,
      selectedPaperID: param,
    });
  };
};

export const setideaFinderSelectedPaper = (param: PaperInspectionTypes.PaperInspectionUnit | null) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_IDEA_FINDER_SELECTED_PAPER,
      ideaFinderSelectedPaper: param,
    });
  };
};

export const getSelectedPaperPatentList = (param: string) => {
  console.log("getSelectedPaperPatentList: ", param)
  return async function (dispatch: Dispatch<Action>) {
    dispatch({
      type: ActionType.GET_SELECTED_PAPER_PATENT_LIST,
      selectedPaperPatentList: await getSelectedPaperPatentDataAPI(param),
    })
  }
};


