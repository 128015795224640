import axios, { AxiosResponse } from "axios";
import { ROOT_URL, DEV_MODE } from "./env";
import { MatrixTypes, TimelineTypes, ResearcherOverviewTypes, ResearcherStatisticsTypes, MatrixTypes2, PatentInspectionTypes, PaperInspectionTypes, QueryViewTypes, UserManagementTypes } from '../types/data';

interface MatrixRes {
  matrixRes: MatrixTypes2.InputData;
  paperMetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict;
}

const API = ROOT_URL;

// 1. check response
function checkResponse<T>(response: AxiosResponse<T>, fallback: T): T {
  if (response.status === 200) return response.data;
  console.error(`Data fetching error: ${response.status}`);
  if (DEV_MODE) {
    console.error(response);
    throw response;
  }
  return fallback;
}

// test
export async function getBackendDataAPI(params: number): Promise<string> {
  const url = `${API}/test`
  console.log('api.ts, url: ', url)
  const response = await axios.post(url, params)
  const data: string = checkResponse(response, [])
  // console.log('api.ts, data: ', data)
  return data
}

// 2. all api functions
// export async function getSelectedCPCFieldMatrixDataAPI( // 点击一个cpcFieldCode以后，expand这个code
//   selectedCPCFieldCode: string,
//   selectedCPCFieldLevel: string,
//   paperYearRange: number[],
//   patentYearRange: number[],
//   paperFieldLevel: string,
//   fieldL0Name: string,
//   researcherIdList: string[]
//   ): Promise<MatrixTypes2.CellUnit[]> {
//   const url = `${API}/get_paper_matrix_result`;
//   const param = {
//     selected_cpc_field_code: selectedCPCFieldCode,
//     selected_cpc_field_level: selectedCPCFieldLevel,
//     paper_year_range: paperYearRange,
//     patent_year_range: patentYearRange,
//     paper_field_level: paperFieldLevel,
//     field_l0_name: fieldL0Name,
//     researcher_id_list: researcherIdList,
//   }

//   // TODO:
//   // 2. 前段右键context menu以后触发的现在这个function要去后端新建一个function来filter和重新计算paperMatrix的返回值: selectedCPCFieldCode, paperYearRange , researcherIdList, paper_field_level = parameter['paper_field_level']
//   // field_l0_name = parameter['field_l0_name']

//   const response = await axios.post(url, param);
//   const data: MatrixTypes2.CellUnit[] = checkResponse(response, [])

//   console.log('getSelectedCPCFieldMatrixDataAPI API, data: ', data)

//   return (data)
// }

export async function getPredictionResultAPI(
  selectedCPCFieldCode: string,
  selectedCPCFieldLevel: string,
  paperYearRange: number[],
  patentYearRange: number[],
  paperFieldLevel: string,
  fieldL0Name: string,
  researcherIdList: string[],
  predictionScoreRange: number[],
  matrixCellPaperIdList: MatrixTypes2.MatrixCellPaperIdListUnit[]
  ): Promise<MatrixTypes2.PredictionResult> {
  const url = `${API}/get_prediction_result`;
  const param = {
    selected_cpc_field_code: selectedCPCFieldCode,
    selected_cpc_field_level: selectedCPCFieldLevel,
    paper_year_range: paperYearRange,
    patent_year_range: patentYearRange,
    paper_field_level: paperFieldLevel,
    field_l0_name: fieldL0Name,
    researcher_id_list: researcherIdList,

    prediction_score_range: predictionScoreRange,
    matrix_cell_paper_id_list: matrixCellPaperIdList,
  }
  console.log('==> get_prediction_result(), param: ', param)
  const response = await axios.post(url, param);
  const data: MatrixTypes2.PredictionResult = checkResponse(response, []);

  console.log('prediction API, data: ', data)

  return {
    predictedFlowList: data.predictedFlowList,
    predictedPatentTreemap: data.predictedPatentTreemap,
  }
}
export async function getMatrixDataAPI(
  selectedCPCFieldCode: string,
  selectedCPCFieldLevel: string,
  paperYearRange: number[],
  patentYearRange: number[],
  paperFieldLevel: string,
  fieldL0Name: string,
  researcherIdList: string[],
  selectedPatentAssigneeID: string,
  ): Promise<{
  data: MatrixTypes2.InputData,
  paperFieldLevel: string,
  paperMetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict,
}> {
  const url = `${API}/get_matrix_data`
  const param = {
    selected_cpc_field_code: selectedCPCFieldCode,
    selected_cpc_field_level: selectedCPCFieldLevel,
    paper_year_range: paperYearRange,
    patent_year_range: patentYearRange,
    paper_field_level: paperFieldLevel,
    field_l0_name: fieldL0Name,
    researcher_id_list: researcherIdList,
    selected_patent_assignee_id: selectedPatentAssigneeID,
  }
  console.log("===> getMatrixDataAPI param", param)
  const response = await axios.post(url, param)
  const data: any = checkResponse(response, [])

  console.log('======= getMatrixDataAPI =======')
  console.log('param: ', param)
  console.log('data: ', data)

  const matrixData: MatrixTypes2.InputData = data.matrixRes
  const paperMetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict = data.paperMetriceRangeDict

  return {
    paperFieldLevel: paperFieldLevel,
    data: matrixData,
    paperMetriceRangeDict: paperMetriceRangeDict,
  }
}

export async function getTimeLineDataAPI(
    selectedCPCFieldCode: string,
    selectedCPCFieldLevel: string,
    paperYearRange: number[],
    patentYearRange: number[],
    paperFieldLevel: string,
    fieldL0Name: string,
    researcherIdList: string[],
    selectedPatentAssigneeID: string,
  ): Promise<TimelineTypes.TimelineData> {
  const url = `${API}/get_timeline_data`;
  const param = {
    selected_cpc_field_code: selectedCPCFieldCode,
    selected_cpc_field_level: selectedCPCFieldLevel,
    paper_year_range: paperYearRange,
    patent_year_range: patentYearRange,
    paper_field_level: paperFieldLevel,
    field_l0_name: fieldL0Name,
    researcher_id_list: researcherIdList,
    selected_patent_assignee_id: selectedPatentAssigneeID,
  }
  const response = await axios.post(url, param);
  const data: TimelineTypes.TimelineData = checkResponse(response, []);

  // console.log('getTimeLineDataAPI, data: ', data)
  return data
}

export async function getScatterPlotDataAPI(params: string[]): Promise<ResearcherOverviewTypes.ScatterPlotUnit[]> {
  const url = `${API}/get_scatterplot_data`;
  const response = await axios.post(url, params);
  const data: ResearcherOverviewTypes.ScatterPlotUnit[] = checkResponse(response, []);
  return data
}

export async function getResearcherStatisticsDataAPI(params: string[]): Promise<ResearcherStatisticsTypes.ResearcherStatisticsUnit[]> {
  const url = `${API}/get_researcher_statistics_data`;
  const response = await axios.post(url, params);
  // console.log('getResearcherStatisticsDataAPI, params: ', params)
  const data: ResearcherStatisticsTypes.ResearcherStatisticsUnit[] = checkResponse(response, []);
  return data
}

export async function getPatentDataAPI(params: PatentInspectionTypes.PatentApiParams): Promise<PatentInspectionTypes.PatentData> {
  const url = `${API}/get_patent_data`;
  const param = {
    cpc_code_list: params.cpcCodeList,
    cpc_field_level: params.cpcFieldLevel,
    patent_year_range: params.patentYearRange,
    researcher_id_list: params.selectedResearcherIdList,
    top_k_org: params.topK
  }

  // console.log('getPatentDataAPI(), params: ', param)

  const response = await axios.post(url, param);
  const data: PatentInspectionTypes.PatentData = checkResponse(response, []);
  return data
}

// TODO: 返回值的type待修改
export async function updateSelectedPatentAssigneeAPI(params: string): Promise<any> {
  const url = `${API}/update_selected_patent_assignee`;

  // console.log("updateSelectedPatentAssigneeAPI", params)

  const response = await axios.post(url, params);
  const data: any = checkResponse(response, []);
  // console.log('getTimeLineDataAPI: ', data)
  return data
}

export async function getPaperInspectionDataAPI(params: PaperInspectionTypes.PaperApiParams): Promise<PaperInspectionTypes.PaperInspectionUnit[]> {
  const url = `${API}/get_paper_inspection_data`;
  const param = {
    researcher_id_list: params.selectedResearcherIdList,
    paper_id_list: params.paperIDList
  }
  // console.log('getPaperInspectionDataAPI: ', param)
  const response = await axios.post(url, param);
  const data: PaperInspectionTypes.PaperInspectionUnit[] = checkResponse(response, []);
  return data
}

export async function getSelectedResearcherIDListAPI(params: QueryViewTypes.QueryConfirmParams): Promise<string[]> {
  const url = `${API}/get_selected_researcher_id_list`;
  const param = {
    popular_filter: params.popularFilter,
    gender: params.gender,
    tenure_status: params.tenureStatus,
    untap_status: params.untapStatus,
    school: params.school,
    department: params.department, 
    keyword: params.keyword,
    innovation_potential: params.innPotential,
    engagement: params.engagement,
    num_inventions_2001_2023_range: params.numInventions2001to2023Range,
    num_inventions_2019_2023_range: params.numInventions2019to2023Range,
  }

  // console.log('getSelectedResearcherIDList(), params: ', param)

  const response = await axios.post(url, param);
  const data: string[] = checkResponse(response, []);
  return data
}

export async function getResearcherScatterplotDataAPI(params: string[]): Promise<QueryViewTypes.ScatterPlotUnit[]> {
  const url = `${API}/get_researcher_scatterplot_data`;
  const param = {
    researcher_id_list: params,
  }
  console.log('getResearcherScatterplotDataAPI: ', param)
  const response = await axios.post(url, param);
  const data: QueryViewTypes.ScatterPlotUnit[] = checkResponse(response, []);
  return data
}

export async function getResearcherCardListDataAPI(params: QueryViewTypes.ResearcherCardListRetrieveParams): Promise<ResearcherStatisticsTypes.ResearcherStatisticsData> {
  const url = `${API}/get_researcher_card_list_data`;
  console.log('api.ts, url: ', url)
  const param = {
    researcher_id_list: params.researcherIdList,
    untap_filter: params.untapFilter,
    rank_metric: params.rankingMetric,
    rank_order: params.rankingOrder,
    page_number: params.pageNumber,
  }
  console.log('getResearcherCardListDataAPI: ', param)
  const response = await axios.post(url, param);
  const data: ResearcherStatisticsTypes.ResearcherStatisticsData = checkResponse(response, []);

  // console.log('getResearcherCardListDataAPI: ', data)
  return data
}

export async function getSelectedPaperIDListAPI(params: QueryViewTypes.PaperQueryConfirmParams): Promise<string[]> {
  const url = `${API}/get_selected_paper_id_list`;
  const param = {
    popular_filter: params.popularFilter,
    publication_year: params.publicationYear,
    publication_month: params.publicationMonth,
    keyword: params.keyword,
  }

  // console.log('getSelectedPaperIDListAPI(), params: ', param)

  const response = await axios.post(url, param);
  const data: string[] = checkResponse(response, []);
  return data
}

export async function getPaperCardListDataAPI(params: string[]): Promise<PaperInspectionTypes.PaperInspectionUnit[]> {
  const url = `${API}/get_paper_card_list_data`;
  const param = {
    paper_id_list: params,
  }
  
  const response = await axios.post(url, param);
  const data: PaperInspectionTypes.PaperInspectionUnit[] = checkResponse(response, []);

  // console.log('getPaperCardListDataAPI: ', data)
  return data
}

export async function getTopKPaperCardListDataAPI(params:  PaperInspectionTypes.PaperCardListRetrieveParams): Promise<PaperInspectionTypes.PaperCardsInspectionData> {
  const url = `${API}/get_topk_paper_card_list_data`;
  const param = {
    paper_id_list: params.paperIdList,
    rank_metric: params.rankingMetric,
    rank_order: params.rankingOrder,
    page_number: params.pageNumber,
  }
  
  const response = await axios.post(url, param);
  const data: PaperInspectionTypes.PaperCardsInspectionData = checkResponse(response, []);

  //console.log('getPaperCardListDataAPI: ', data.paper_inspection_list)
  return data
}

export async function getSelectedResearcherPaperListDataAPI(params: string): Promise<PaperInspectionTypes.PaperPatentInspectionData> {
  const url = `${API}/get_selected_researcher_paper_list_data`;
  const param = {
    researcher_id: params,
  }
  
  const response = await axios.post(url, param);
  const data: PaperInspectionTypes.PaperPatentInspectionData = checkResponse(response, []);

  console.log('getSelectedResearcherPaperListDataAPI: ', typeof(data))
  console.log('getSelectedResearcherPaperListDataAPI: ', data)
  return data;
}

export async function getSelectedResearcherBarplotDataAPI(params: string): Promise<PaperInspectionTypes.PaperBarplotData> {
  const url = `${API}/get_selected_researcher_barplot_data`;
  const param = {
    researcher_id: params,
  }
  
  const response = await axios.post(url, param);
  const data: PaperInspectionTypes.PaperBarplotData = checkResponse(response, []);

  // console.log('getSelectedResearcherBarplotDataAPI: ', data)
  return data
}

export async function getSelectedPaperPatentDataAPI(params: string): Promise<PatentInspectionTypes.PatentInspectionUnit[]> {
  const url = `${API}/get_selected_paper_patent_list_data`;
  const param = {
    paper_id: params,
  }
  
  const response = await axios.post(url, param);
  const data: PatentInspectionTypes.PatentInspectionUnit[] = checkResponse(response, []);

  // console.log('getSelectedResearcherPaperListDataAPI: ', data)
  return data
}

export async function getLoginResultAPI(params: UserManagementTypes.UserLoginParams): Promise<UserManagementTypes.LoginResponse> {
  const url = `${API}/login`;
  const param = {
    username: params.username,
    password: params.password
  }
  const response = await axios.post(url, param);
  return {
    access_token: response.data.access_token,
    msg: response.data.msg,
    is_authenticated: response.data.is_authenticated
  }

}

export async function getRegisterResultAPI(params: UserManagementTypes.UserRegisterParams): Promise<UserManagementTypes.AccountChangeResponse> {
  const url = `${API}/register`;
  const param = {
    username: params.username,
    password: params.password,
    email: params.email
  }
  const response = await axios.post(url, param);
  return {
    msg: response.data.msg,
    status: response.data.register_status
  }
}

export async function getDeleteAccountResultAPI(params: UserManagementTypes.UserLoginParams): Promise<UserManagementTypes.AccountChangeResponse> {
  const url = `${API}/delete_account`;
  const param = {
    username: params.username,
    password: params.password
  }
  const response = await axios.post(url, param);
  return {
    msg: response.data.msg,
    status: response.data.delete_status
  }
}


// export async function getGroupCareerAndLifeFlowAPI(params: DescriptiveStatisticsTypes.DatasetMetaInfo): Promise<DescriptiveStatisticsTypes.BackendReturnedFlowData> {
//   if (params.datasetName !== "Select" && params.groupingCriteria !== "Select") {
//     const paramsBackend = {
//       dataset_name: params.datasetName,
//       grouping_criteria: params.groupingCriteria,
//     }
//     const url = `${API}/descriptive_statistics_result/flow_result`;
//     const response = await axios.post(url, paramsBackend);
//     const data = checkResponse(response, []);
//     const result: DescriptiveStatisticsTypes.BackendReturnedFlowData = data
//     return result
//   } else {
//     return {
//       group_list: []
//     }
//   }
// }

// export async function getCareerflowDataAPI(params: MainViewTypes.EventPopulationProportionAPI): Promise<MainViewTypes.CareerFlowData> {
//   if (params.life_event_list.length > 0) {
//     let eventList: string[] = params.life_event_list
//     eventList = eventList.map((eventName) => _.replace(eventName, 'education_level', 'education'))
//     eventList = eventList.map((eventName) => _.replace(eventName, 'first_job_income_quantile', 'first_job'))
//     params.life_event_list = eventList
//     console.log('getCareerflowDataAPI, params: ', params)

//     const url = `${API}/career_flow_result`
//     const response = await axios.post(url, params)
//     const data = checkResponse(response, [])
//     const result: MainViewTypes.CareerFlowData = data
//     return result
//   }
//   return {}
// }
// export async function getEventPopulationProportionDataAPI(params: MainViewTypes.EventPopulationProportionAPI): Promise<MainViewTypes.EventPopulationProportion> {
//   const url = `${API}/selected_event_population_proportion`
//   const response = await axios.post(url, params)
//   const data = checkResponse(response, [])
//   const result: MainViewTypes.EventPopulationProportion = data
//   return result
// }

// export async function getSeqClusterLabelDataAPI(params: MainViewTypes.SeqClusterAPI): Promise<GeneralTypes.SequenceClusterLabel> {
//   const url = `${API}/seq_cluster_labels`
//   const response = await axios.post(url, params)
//   const data = checkResponse(response, [])
//   const result: GeneralTypes.SequenceClusterLabel = data
//   return result
// }

// export async function getRegressionResultAPI(params: MainViewTypes.FrontendParam2Backend): Promise<MainViewTypes.BackendReturnedData> {
//   // console.log('getRegressionResultAPI, params: ', params)
//   const quantile: number = parseInt(params.model_param.refGroups.firstJob[1])
//   let paramsBackend = {}
//   if (params.dataset_name === 'nlsy') {
//     paramsBackend = {
//       dataset_name: params.dataset_name,
//       grouping_criteria: params.grouping_criteria,
//       group_name: params.group_name,
//       model_param: {
//         cluster_param: {
//           window_size: params.model_param.clusterParam.stageSize,
//           cluster_range:  params.model_param.clusterParam.clusterRange,
//         },
//         ref_groups: {
//           education_level: params.model_param.refGroups.education,
//           first_job_income_quantile: quantile,
//           prev_stage: params.model_param.refGroups.prevStage,
//           dv_stage: params.model_param.refGroups.dvStage,
//         },
//         event_weight: {
//           single_couple: params.model_param.eventWeights.single_couple,
//           couple_single: params.model_param.eventWeights.couple_single,
//           single_single: params.model_param.eventWeights.single_single,
//           childbearing_no_yes: params.model_param.eventWeights.childbearing_no_yes,
//           childbearing_yes_no: params.model_param.eventWeights.childbearing_yes_no,
//           has_migration: params.model_param.eventWeights.has_migration,
//           noncentral_central: params.model_param.eventWeights.noncentral_central,
//           central_noncentral: params.model_param.eventWeights.central_noncentral,
//           education: params.model_param.eventWeights.education_level,
//           first_job: params.model_param.eventWeights.first_job_income_quantile,
//         }
//       }
//     }
//   } else if (params.dataset_name === 'psid') {
//     paramsBackend = {
//       dataset_name: params.dataset_name,
//       grouping_criteria: params.grouping_criteria,
//       group_name: params.group_name,
//       model_param: {
//         cluster_param: {
//           window_size: params.model_param.clusterParam.stageSize,
//           cluster_range:  params.model_param.clusterParam.clusterRange,
//         },
//         ref_groups: {
//           education_level: params.model_param.refGroups.education,
//           first_job_income_quantile: quantile,
//           prev_stage: params.model_param.refGroups.prevStage,
//           dv_stage: params.model_param.refGroups.dvStage,
//         },
//         event_weight: {
//           single_couple: params.model_param.eventWeights.single_couple,
//           couple_single: params.model_param.eventWeights.couple_single,
//           single_single: params.model_param.eventWeights.single_single,
//           childbearing_no_yes: params.model_param.eventWeights.childbearing_no_yes,
//           childbearing_yes_no: params.model_param.eventWeights.childbearing_yes_no,
//           education: params.model_param.eventWeights.education_level,
//           first_job: params.model_param.eventWeights.first_job_income_quantile,
//         }
//       }
//     }
//   } else {
//     console.log('getRegressionResultAPI(): no such a dataset!')
//     return ({} as MainViewTypes.BackendReturnedData)
//   }

//   console.log('getRegressionResultAPI, paramsBackend: ', paramsBackend)
//   const url = `${API}/regression_result`;
//   const response = await axios.post(url, paramsBackend);
//   const data = checkResponse(response, []);
//   const regressionResult: MainViewTypes.BackendReturnedData = data
//   return regressionResult
// }

// export async function getDesctiptiveStatisticsAPI(params: DescriptiveStatisticsTypes.DatasetMetaInfo): Promise<DescriptiveStatisticsTypes.BackendReturnedData> {
//   if (params.datasetName !== "Select" && params.groupingCriteria !== "Select") {
//     const paramsBackend = {
//       dataset_name: params.datasetName,
//       grouping_criteria: params.groupingCriteria,
//     }

//     console.log('paramsBackend: ', paramsBackend)
//     const url = `${API}/descriptive_statistics_result/table_result`;
//     const response = await axios.post(url, paramsBackend);
//     const data = checkResponse(response, []);
//     const desctiptiveStatisticsResult: DescriptiveStatisticsTypes.BackendReturnedData = data
//     return desctiptiveStatisticsResult
//   } else {
//     return {
//       dataset_size: 0,
//       group_statistics: []
//     }
//   }
// }


// // tests
// export async function loadMsgAPI(): Promise<string> {
//   const url = `${API}/members`;
//   const response = await axios.get(url);
//   const data: string = checkResponse(response, []);
//   return data
// }
// export async function getListAPI(): Promise<TestTypes.NameObj[]> {
//   const url = `${API}/list`;
//   const response = await axios.get(url);
//   const data: TestTypes.NameObj[] = checkResponse(response, []);
//   return data
// }
// export async function getNameAPI(params: { index : number }): Promise<string> {
//   const url = `${API}/get_name`;
//   const response = await axios.post(url, params);
//   const data: string = checkResponse(response, []);
//   console.log('async func: ', data)
//   // const dataset = d3.csvParseRows(data);
//   // return dataset;
//   return data
// }

// export async function getData(params: { dataId: string }): Promise<string[][]> {
//   const url = `${API}/data`;
//   const response = await axios.get(url, { params });
//   const data = checkResponse(response, []);
//   // const dataset = d3.csvParseRows(data);
//   // return dataset;
//   return data
// }