import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/index";
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

// const middleware = [thunk]

// if(process.env.NODE_ENV === 'development') {
//   middleware.push(logger)
// }

export const store = createStore(
  reducers, // all the reducers
  {}, // initial state
  applyMiddleware(thunk) // ??
)