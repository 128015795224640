import { TimelineTypes } from '../../../../types/data';
import _ from 'lodash';

const filterAndReorderTimelineData = (
  timelineData: TimelineTypes.TimelineData,
  ignoredPaperField: string[],
  colIdxDict: { [rawColIdx: number]: number; },
) => {

  // 1. filter paper field
  const filteredPaperTimelineData = timelineData.paper.filter((paperFieldUnit) => !ignoredPaperField.includes(paperFieldUnit.fieldName))

  // 2. reorder paper field
  let reorderedPaperTimelineData: TimelineTypes.PaperFieldUnit[] = []
  /* opt 1: order by paperMatrix col order */
  // temp: do nothing

  /* opt 2: order by difference of [last - first] */
  reorderedPaperTimelineData = _.orderBy(filteredPaperTimelineData, (paperFieldUnit) => {
    const timelineLength: number = paperFieldUnit.temporalTrend.length
    const firstYearValue: number = paperFieldUnit.temporalTrend[0].numPaperCallPercent
    const lastYearValue: number = paperFieldUnit.temporalTrend[timelineLength - 1].numPaperCallPercent
    return (lastYearValue - firstYearValue) / firstYearValue
  }, ['desc'])

  // 3. reorder patent field
  let reorderedPatentTimelineData: TimelineTypes.PatentTimelineType = {} as TimelineTypes.PatentTimelineType
  _.forEach(timelineData.patent, (timelineData, cpcFieldLevel) => {
    const currCPCLevelTiemlineData = _.orderBy(timelineData, (patentFieldUnit) => {
      const timelineLength: number = patentFieldUnit.temporalTrend.length
      return (patentFieldUnit.temporalTrend[timelineLength - 1].numPatentPercent - patentFieldUnit.temporalTrend[0].numPatentPercent)
    }, ['desc'])
    reorderedPatentTimelineData[cpcFieldLevel as ('cpcRoot' | 'cpcSection' | 'cpcSubsection' | 'cpcGroup')] = currCPCLevelTiemlineData
  })

  return {
    paper: reorderedPaperTimelineData,
    patent: reorderedPatentTimelineData,
  }
}

export default filterAndReorderTimelineData;