import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';
import { Select, Slider, Form, Switch, Space, Spin, Button, Tooltip, Breadcrumb } from 'antd';
import _ from 'lodash';

import { PaperInspectionTypes, PatentInspectionTypes } from '../../../../../types/data';
import PatentCard from '../../../common/Visualization/PatentCard';
import PaperDetailCard from './PaperDetailCard';



interface PaperDetailsProps {
  paper: PaperInspectionTypes.PaperInspectionUnit;
}

const PaperDetails: FC<PaperDetailsProps> = (props) => {

  const {paper} = props

  // console.log('ResearcherDetails researcher:', researcher);

  const dispatch = useDispatch()
  const {
    getSelectedPaperPatentList
  } = bindActionCreators(actionCreators, dispatch)

  // const selectedResearcherId: string = useSelector((state: State) => state.dataReducer?.selectedResearcherId) as string
  // const selectedResearcherPaperList: PaperInspectionTypes.PaperInspectionUnit[] = useSelector((state: State) => state.dataReducer?.selectedResearcherPaperList) as PaperInspectionTypes.PaperInspectionUnit[]
  // const selectedResearcherBarplotData: PaperInspectionTypes.PaperBarplotData = useSelector((state: State) => state.dataReducer?.selectedResearcherBarplotData) as PaperInspectionTypes.PaperBarplotData
  const selectedPaperId: string = useSelector((state: State) => state.dataReducer?.selectedPaperID) as string

  const [isPaperDetailsViewLoading, setIsPaperDetailsViewLoading] = useState(false);
  // const [paperRankByField, setPaperRankByField] = useState('predictionScore');
  // const [paperRankOrder, setPaperRankOrder] = useState('ascending');
  // const [sortedResearcherPaperList, setSortedResearcherPaperList] = useState<PaperInspectionTypes.PaperInspectionUnit[]>([]);
  // const [barplotWidth, setBarplotWidth] = useState(500);
  // const [predictionScore, setPredictionScore] = useState<number[]>([0, 100]);
  // const [recentYears, setRecentYears] = useState<number[]>([2011, 2023]);
  // const [numCompanyPatentCitation, setNumCompanyPatentCitation] = useState<number[]>([1, 100]);
  // const [isLeadAuthor, setIsLeadAuthor] = useState(true);
  // // console.log('selectedResearcherPaperList:', selectedResearcherPaperList);

  const selectedPaperPatentList: PatentInspectionTypes.PatentInspectionUnit[] = useSelector((state: State) => state.dataReducer?.selectedPaperPatentList) as PatentInspectionTypes.PatentInspectionUnit[]
  
  //console.log("PAPER DETAILS PAGE>>>")
  useEffect(() => {
      if(selectedPaperId!==''){
        const updatePaperDetailsView = async () => {
          try {
            // console.log("==> in Researcher View getResearcherCardListData")
            setIsPaperDetailsViewLoading(true)
            // TODO: 待补充
            // 监测到selectedResearcherId的变化后，调用后端api获得tResearcherBarplotData和ResearcherPaperIDList
            //console.log("RD selectedResearcherId: ", selectedResearcherId)
            console.log("selectedPaperId: ", selectedPaperId)
            await getSelectedPaperPatentList(selectedPaperId);
            // console.log('getResearcherPaperIDList:', selectedResearcherPaperList);           
          } catch (error) {
            console.error(error);
            setIsPaperDetailsViewLoading(false)
          } finally {
            //console.log('Researcher View update finished');
            setIsPaperDetailsViewLoading(false)            
          }
        }
        updatePaperDetailsView();
      }
    // fetchData();
  }, [selectedPaperId])


  // console.log('barplotWidth:', barplotWidth);
  //var sortedPaperList: PaperInspectionTypes.PaperInspectionUnit[] = selectedResearcherPaperList.slice()

  // display only selected paper in timeplot
  // sortedPaperList = sortedPaperList.filter(paper =>{
  // const paperID = paper.demographicInfo.paperDoi;
  // return paperID == selectedPaperId;
  // });

  
  // sortedPaperList =  sortedPaperList.filter(paper =>{
  //   const paperYear = paper.demographicInfo.paperYear;
  //   return paperYear >= recentYears[0] && paperYear <= recentYears[1];
  // });
 
  // // rank by prediction score
  // if(paperRankByField === 'predictionScore'){
  //   sortedPaperList = sortedPaperList.sort(function(a, b){
  //     if(paperRankOrder === 'ascending'){
  //       return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
  //     }
  //     else{
  //       // descending
  //       return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
  //     }
  //   })
  // }

  // // rank by patent citations call
  // else if(paperRankByField === 'patentCitationsCall'){
  //   sortedPaperList = sortedPaperList.sort(function(a, b){
  //     if(paperRankOrder === 'ascending'){
  //       return (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall)? -1 : 0;
  //     }
  //     else{
  //       // descending
  //       return (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall)? -1 : 0;
  //     }
  //   })
  // }
  // else{
  //   //paperRankByField === 'prediction score'
  //   sortedPaperList = sortedPaperList.sort(function(a, b){
  //     if(paperRankOrder === 'ascending'){
  //       return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
  //     }
  //     else{
  //       // descending
  //       return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
  //     }
  //   })
  // }
  // setSortedResearcherPaperList(sortedPaperList)

  // const paperCardWidth = 440; // 固定卡片宽度
  const patentCardHeight = 180; // 固定卡片高度

  const researcherCardWidth = 480; // 固定卡片宽度
  const researcherCardHeight = 274; // 固定卡片高度
  const gap = 20; // 卡片之间的间隔

  const marks = {
    2011: '2011',
    2023: '2023', 
    0: '0',
    100: '100'
  };

  // set paper card width
  const patentCardParentDivRef = React.useRef<HTMLDivElement>(null);
  const [patentCardParentDivWidth, setPatentCardParentDivWidth] = React.useState(0);
  React.useEffect(() => {
    if (patentCardParentDivRef.current) {
      setPatentCardParentDivWidth(patentCardParentDivRef.current.offsetWidth);
    }
  }, []);
  const paperCardMargin = 16
  const numPatentCardsPerRowDict: { [windowWidth: number]: number} = {
    600: 1, 
    900: 2, 
    1240: 3, 
    1920: 4, 
    2560: 5, 
  }
  let numPatentCardsPerRow = 1; // Default value
  for (const width in numPatentCardsPerRowDict) {
    if (patentCardParentDivWidth >= parseInt(width)) {
      numPatentCardsPerRow = numPatentCardsPerRowDict[width];
      //console.log('numPaperCardsPerRow: ', numPaperCardsPerRow);
    }
  }
  const patentCardWidth = (patentCardParentDivWidth - numPatentCardsPerRow * (paperCardMargin + 8) * 2) / numPatentCardsPerRow;

  //console.log('numPaperCardsPerRow:', numPaperCardsPerRow);
  //console.log('paperCardParentDivWidth:', paperCardParentDivWidth);
  //var patentList: PatentInspectionTypes.PatentInspectionUnit[] = selectedPaperPatentList.slice()
  //console.log("selectedPaperPatentList>>>>", selectedPaperPatentList)
  const groupedPatents = selectedPaperPatentList.reduce((acc, patent) => {
    console.log('patent:', patent);
    if (!acc[patent.patent_id]) {
      acc[patent.patent_id] = {
        ...patent,
        companyAssignees: [] as string[],
        universityAssignees: [] as string[],
        otherAssignees: [] as string[],
      };
    }

    // Separate assignees by type
    if (patent.patent_assignee_type === 'Companies') {
      acc[patent.patent_id].companyAssignees.push(
        `${patent.patent_assignee_name} (${patent.patent_assignee_country})`
      );
    } else if (patent.patent_assignee_type === 'Universities'){
      acc[patent.patent_id].universityAssignees.push(
        `${patent.patent_assignee_name} (${patent.patent_assignee_country})`
      );
    } else {
      acc[patent.patent_id].otherAssignees.push(
        `${patent.patent_assignee_name} (${patent.patent_assignee_country})`
      );
    }

    return acc;
  }, {} as Record<string, PatentInspectionTypes.PatentInspectionUnit & { companyAssignees: string[]; universityAssignees: string[]; otherAssignees: string[] }>);

  // Convert the grouped patents object into an array
  const processedPatents = Object.values(groupedPatents);
  //console.log("processedPatents: ", processedPatents);
  const patentCardList: JSX.Element[] = processedPatents.map((patent, index) => {
    // const numPaperCardsPerRow = Math.floor(paperCardParentDivWidth / (paperCardWidth_min + 2 * paperCardMargin));
    return (
      <PatentCard
        key={patent.patent_id}
        viewName='Idea-Finder'
        viewParam={{ width: patentCardWidth, height: patentCardHeight, margin: 20, padding: 4 }}
        PatentInspectionData={patent}
      />
    )
  })

  return (
    <div className="paper-details-container">
    <Spin tip="Loading" spinning={isPaperDetailsViewLoading} size='large' className='paper-details-custom-spin'>
      {!isPaperDetailsViewLoading ? (
        <>
          <div className="basic-info">
            <div className="info-card">
              <PaperDetailCard
                PaperInspectionData={paper}
              />
            </div>
          </div>
          <div className="papers-section">
            <div className="papers-header">
              <div className='papers-header-title'>Number of Patents Citing This Paper: </div>
              <div className='papers-header-value'>{paper.scisciIndex['patentCitationsCall']}</div>
            </div>
            <div className="papers-list" ref={patentCardParentDivRef}>
              {patentCardList}
            </div>
            
          </div>
        </>
      ) : null}
    </Spin>
    </div>
  );
};

export default PaperDetails;