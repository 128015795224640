import React, { FunctionComponent as FC, useState, useEffect  } from 'react';
import './index.scss';
import { actionCreators } from '../../../../state';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { State } from '../../../../state';



import PagePanel from '../PagePanel';
import QueryView from './QueryView';
import PaperView from './PaperView';


import { GlobalStyle } from '../../../../types/style.d';
import { PaperInspectionTypes } from '../../../../types/data';



interface IdeaFinderPageProps {

}

const IdeaFinderPage: FC<IdeaFinderPageProps> = (props) => {

  //const location = useLocation();

  const {
  } = props

  // store
  const dispatch = useDispatch()
  const {
    // TODO: 添加获取researcher card list data的action
    // getPaperInspectionData,
    // getPatentInspectionData,
    setSelectedPaperID
  } = bindActionCreators(actionCreators, dispatch)

  // const selectedPaper = location.state?.selectedPaper || null;
  // console.log("location: ", location)
  // useEffect(() => {
  //   console.log("IF selected paper: ", selectedPaper)
  //   // If navigating directly to IdeaFinderPage, reset selectedPaper state
  //   if (!selectedPaper) {
  //     setSelectedPaperID("");
  //   } 
  // }, [location.state, selectedPaper]);
  const ideaFinderSelectedPaper: PaperInspectionTypes.PaperInspectionUnit = useSelector((state: State) => state.dataReducer?.ideaFinderSelectedPaper) as PaperInspectionTypes.PaperInspectionUnit
  const [selectedPaper, setSelectedPaper] = useState<PaperInspectionTypes.PaperInspectionUnit | null>(null);

  useEffect(() => {
    console.log("ideaFinderSelectedPaper: ", ideaFinderSelectedPaper);
    // if coming from RD (click on paper card)
    if (ideaFinderSelectedPaper){
      setSelectedPaper(ideaFinderSelectedPaper);
    } 
  }, [ideaFinderSelectedPaper])

  return (
    <div className='idea-finder-page-div'>
      <div className='left-div'>
        <PagePanel viewTitle={''} panelHeaderHeight={0}>
          <QueryView />
        </PagePanel>
      </div>
      <div className='right-div'>
        <PagePanel viewTitle={'Paper View'} panelHeaderHeight={40}>
          <PaperView selectedPaperFromIF = {selectedPaper}/>
        </PagePanel>
      </div>
    </div>

  )
}

export default IdeaFinderPage