// To combine all the reducers
import { combineReducers } from 'redux';
import dataReducer from './dataReducer';
import uiReducer from './uiReducer';

const reducers = combineReducers({
  dataReducer: dataReducer,
  uiReducer: uiReducer,
})

export default reducers
export type State = ReturnType<typeof reducers>