import React, { FunctionComponent as FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

import peopleFinderImgUrl from '../../../../assets/1-media/people-finder.JPG';
import ideaFinderImgUrl from '../../../../assets/1-media/idea-finder.JPG';
import partnerFinderImgUrl from '../../../../assets/1-media/partner-finder.JPG';


import { GlobalStyle } from '../../../../types/style.d';


interface ThreeFinderPageProps {

}

const ThreeFinderPage: FC<ThreeFinderPageProps> = (props) => {

  const {
  } = props

  // router
  const navigate = useNavigate();

  const threeFinderInfoList = [
    {
      name: 'People Finder', 
      imgFileName: peopleFinderImgUrl,
      description: 'Locating faculty with untapped commercialization potential.', 
      path: '/people-finder', 
    }, 
    {
      name: 'Idea Finder', 
      imgFileName: ideaFinderImgUrl,
      description: 'Pinpointing specific research in the university with high commercialization potential.', 
      path: '/idea-finder', 
    }, 
    // {
    //   name: 'Partner Finder', 
    //   imgFileName: partnerFinderImgUrl,
    //   description: 'Recommending firms and potential partners in supporting research or commercializing it.', 
    //   path: '/partner-finder', 
    // }, 
  ]

  const handleClickButton = (path: string) => {
    console.log(path); 
    navigate(path);
  }

  const threeFinderElementList: JSX.Element[] = threeFinderInfoList.map((finderInfo, index) => {
    return (
      <div className='finder-card-div' key={index}>
        <div className='background-div'>
          <img src={finderInfo.imgFileName} alt='teaser'/>
        </div>
        <div className='foreground-div'>
          <div className='finder-name-div'>
            {finderInfo.name}
          </div>
          <div className='finder-description-div'>
            {finderInfo.description}
          </div>
          <div className='button-div'>
            <div className='button' id={finderInfo.name} onClick={() => handleClickButton(finderInfo.path)}>Explore</div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className='three-finder-page-div' style={{marginBottom: '60px'}}>
      {threeFinderElementList}
    </div>

  )
}

export default ThreeFinderPage