import React, { FunctionComponent as FC } from 'react';
import {  useSearchParams } from 'react-router-dom';
import './index.scss';

import PagePanel from '../PagePanel';
import QueryView from './QueryView';
import ResearcherView from './ResearcherView';


import { GlobalStyle } from '../../../../types/style.d';



interface PeopleFinderPageProps {

}

const PeopleFinderPage: FC<PeopleFinderPageProps> = (props) => {

  const {
  } = props
  
  const [searchParams] = useSearchParams();
  const viewName = searchParams.get('viewName') || 'People-Finder';

  return (
    <div className='people-finder-page-div' style={{ marginBottom: 36 }}>
      <div className='left-div'>
        <PagePanel viewTitle={''} panelHeaderHeight={0}>
          <QueryView />
        </PagePanel>
      </div>
      <div className='right-div'>
        <PagePanel viewTitle={'Researcher View'} panelHeaderHeight={40}>
          <ResearcherView viewName={viewName}/>
        </PagePanel>
      </div>
    </div>

  )
}

export default PeopleFinderPage