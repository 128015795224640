import React, {
  FunctionComponent as FC,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';

import SubTitle from "../../../common/SubTitle";
import './index.scss';
import { Spin, Slider, Switch, Form, ConfigProvider, Select, Input, Button } from "antd";
import {
  SearchOutlined, 
} from '@ant-design/icons';

import { GlobalStyle } from '../../../../../types/style.d'; 
import { schoolDepartmentMappingNU, schoolDepartmentMappingVB } from '../../../../../types/data.global';
import Scatterplot from "../../../common/Visualization/Scatterplot";
import { getScatterplotData } from "../../../../../state/action-creators";
import { select } from "d3-selection";

interface QueryViewProps {

}

const QueryView: FC<QueryViewProps> = (props) => {

  const {
  } = props

   // store
   const dispatch = useDispatch()
   const {
      getSelectedResearcherIDList,
      getResearcherScatterplotData,
      setSearchFacultyKeyword,
      getScatterplotData,
      updateResearcherCardListFilter,
   } = bindActionCreators(actionCreators, dispatch)

  // const selectedResearcherIdList: string[] = useSelector((state: State) => state.dataReducer?.selectedResearcherIdList) as string[]
  const { Search } = Input;
  const [popularFilterValue, setPopularFilterValue] = useState<string>('--');
  const [gender, setGender] = useState<string>('all');
  const [jobTitle, setJobTitle] = useState<string>('all');
  const [school, setSchool] = useState<string>('all');
  const [department, setDepartment] = useState<string>('all');
  const [keyword, setKeyword] = useState<string>('');
  const [innPotential, setInnPotential] = useState<number>(100)
  const [engagement, setEngagement] = useState<number>(100)
  const [tenureStatus, setTenureStatus] = useState<string>('all');
  const [untapStatus, setUntapStatus] = useState<string>('all');


  const [departmentList, setDepartmentList] = useState<string[]>(['all'])
  const [numInventions2001to2023Range, setNumInventions2001to2023Range] = useState<number[]>([0, 3])
  const [numInventions2019to2023Range, setNumInventions2019to2023Range] = useState<number[]>([0, 0])

  // const handleSearchFacultyKeywordChange = (value: string) => {
  //   console.log('handleSearchFacultyKeywordChange', value)
  //   setSearchFacultyKeyword(value)
  // }

  
  
  
  const getDepartmentsBySchool = (school: string) => {
    // Implement this function based on your data source
    const departmentList = schoolDepartmentMappingVB[school];

    return departmentList
  };

  // useEffect(() => {
  //   const fetchScatterPlotData = async () => {
  //     try {
  //       var params = ["all"];
  //       getScatterplotData(params);
  //     } catch (error) {
  //       console.error('Error fetching scatter plot data:', error);
  //     }
  //   };

  //   fetchScatterPlotData();
  // }, []);

  useEffect(() => {
    if (school === 'all') {
      // pass
    } else if (school) {
      setDepartmentList(["all", ...getDepartmentsBySchool(school)]);
    }
  }, [school]);

  const updateSelectedResearcherList = async (params:any) => {
    try {
      console.log('----> Query View updateAllViews params: ', params);
      
      const result = await getSelectedResearcherIDList(params);
      //console.log('Query View updateAllViews result: ', result);
      // TODO: Check the update cycle here to see if the updated selectedResearcherIdList can be obtained here after getSelectedResearcherIDList has just updated the selectedResearcherIdList.
      //console.log('selected research id list: ', selectedResearcherIdList);
      // const scatterplotResult = await getResearcherScatterplotData(selectedResearcherIdList);
      // console.log('Query View updateAllViews result: ', scatterplotResult);

    } catch (error) {
      console.error(error);
    } finally {
      // console.log('Query View updateAllViews finished');
    }
  }

  const handleNumInvention2001to2023Change = (value: number | number[]) => {
    console.log('handleNumInvention2001to2023Change: ', value);
    setNumInventions2001to2023Range(value as number[]);
    var params = {
      popularFilter: popularFilterValue,
      gender: gender,
      tenureStatus: tenureStatus,
      untapStatus: untapStatus,
      school: school,
      department: department,
      keyword: keyword,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: value,
      numInventions2019to2023Range: numInventions2019to2023Range,
    }
    updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
  };

  const handleNumInvention2019to2023Change = (value: number | number[]) => {
    console.log('handleNumInvention2019to2023Change: ', value);
    setNumInventions2019to2023Range(value as number[]);
    var params = {
      popularFilter: popularFilterValue,
      gender: gender,
      tenureStatus: tenureStatus,
      untapStatus: untapStatus,
      school: school,
      department: department,
      keyword: keyword,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: value,
    }
    updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
  };



  // const handlePopularFilterChange = (value: string) => {
  //   console.log("QueryView => popular filter value: ", value)
  //   setPopularFilterValue(value)
  // }

  const handleGenderChange = (value: string) => {
    console.log("QueryView => gender value: ", value)
    
    const params = {
      popularFilter: popularFilterValue,
      gender: value,
      tenureStatus: tenureStatus,
      untapStatus: untapStatus,
      school: school,
      department: department,
      keyword: keyword,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
    }
    console.log('=====> handleGenderChange, params: ', params);
    // updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
    setGender(value)
  }


  // const handleUntapStatusChange = (value: string) => {
  //   console.log("QueryView => Untap Status value: ", value)
  //   setUntapStatus(value)
  //   var params = {
  //     popularFilter: popularFilterValue,
  //     gender: gender,
  //     tenureStatus: tenureStatus,
  //     untapStatus: value,
  //     school: school,
  //     department: department,
  //     keyword: keyword,
  //     innPotential: innPotential,
  //     engagement: engagement,
  //     numInventions2001to2023Range: numInventions2001to2023Range,
  //     numInventions2019to2023Range: numInventions2019to2023Range,
  //   }
  //   updateResearcherCardListFilter(params.untapStatus)
  //   updateSelectedResearcherList(params);
  // }

  const handleTenureStatusChange = (value: string) => {
    console.log("QueryView => Tenure Status value: ", value)
    setTenureStatus(value)
    var params = {
      popularFilter: popularFilterValue,
      gender: gender,
      tenureStatus: value,
      untapStatus: untapStatus,
      school: school,
      department: department,
      keyword: keyword,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
    }
    updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
  }

  const handleSchoolChange = (value: string) => {
    console.log("QueryView => school value: ", value)
    setSchool(value)
    if (value == 'all'){
      setDepartment('all');
      var params = {
        popularFilter: popularFilterValue,
        gender: gender,
        tenureStatus: tenureStatus,
        untapStatus: untapStatus,
        school: value,
        department: department,
        keyword: keyword,
        innPotential: innPotential,
        engagement: engagement,
        numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
      }
      updateSelectedResearcherList(params);
    }
    else{
      var params = {
        popularFilter: popularFilterValue,
        gender: gender,
        tenureStatus: tenureStatus,
        untapStatus: untapStatus,
        school: value,
        department: department,
        keyword: keyword,
        innPotential: innPotential,
        engagement: engagement,
        numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
      }
      updateResearcherCardListFilter(params.untapStatus)
      updateSelectedResearcherList(params);      
    }

  }

  const handleDepartmentChange = (value: string) => {
    console.log("QueryView => department value: ", value)
    setDepartment(value)
    var params = {
      popularFilter: popularFilterValue,
      gender: gender,
      tenureStatus: tenureStatus,
      untapStatus: untapStatus,
      school: school,
      department: value,
      keyword: keyword,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
    }
    updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
  }

  const handleKeywordChange = (value: string) => {
    console.log("QueryView => keyword value: ", value)
    setKeyword(value)
    var params = {
      popularFilter: popularFilterValue,
      gender: gender,
      tenureStatus: tenureStatus,
      untapStatus: untapStatus,
      school: school,
      department: department,
      keyword: value,
      innPotential: innPotential,
      engagement: engagement,
      numInventions2001to2023Range: numInventions2001to2023Range,
      numInventions2019to2023Range: numInventions2019to2023Range,
    }
    updateResearcherCardListFilter(params.untapStatus)
    updateSelectedResearcherList(params);
  }

  const onInnPotentialChangeComplete = (value: number) => {
    console.log('QueryView => onInnPotentialChangeComplete: ', value);
    setInnPotential(value);
  };

  const onEngagementChangeComplete = (value: number) => {
    console.log('QueryView => onEngagementChangeComplete: ', value);
    setEngagement(value);
  };

  // const handleConfirmButton = () => {
  //   // setSearchFacultyKeyword(''); 

  //   console.log('Confirm button clicked');
  //   var params = {
  //     popularFilter: popularFilterValue,
  //     gender: gender,
  //     tenureStatus: tenureStatus,
  //     school: school,
  //     department: department,
  //     keyword: keyword,
  //     innPotential: innPotential,
  //     engagement: engagement,
  //   }
  //   // console.log('Query View updateAllViews params: ', params);
  //   updateSelectedResearcherList(params);
  // }

  const numInventionsMarks = {
    0: '0',
    3: '3',
    // 100: '100'
  }

  let searchFacultyKeywordElement: JSX.Element = <></>
  searchFacultyKeywordElement = (
    <Search placeholder="input faculty name" size="middle" allowClear onSearch={handleKeywordChange} style={{ width: "100%" }} />
  )

  

  return (
    <div className='query-view-div'>
      <ConfigProvider
            theme={{
              token:{
                colorPrimary: '#404040',
                borderRadius: 14,
                colorText: '#482476'
              },
              components: {
                Form: {
                  labelFontSize: 16,
                  itemMarginBottom: 6,
                },
                Slider: {
                  // dotActiveBorderColor: '#836EAA',
                  // dotBorderColor: '#A495C3',
                  handleColor: '#A495C3',
                  handleActiveColor: '#A495C3',
                  handleColorDisabled: '#A495C3',
                  handleSize: 6,
                  handleSizeHover: 8,
                  railBg:  '#F7F7F7',
                  railSize: 10,
                  trackBg: '#A495C3',
                  trackHoverBg: '#836EAA',
                }             
              }
            }}
          >
              <div className="query-view" >
                {/* <div className="popular-filter-part">
                  <SubTitle title="Popular Filter for Untapped Innovation" />
                  <div className="popular-filter-part-content">
                    <Select
                      value={popularFilterValue}
                      style={{ width: "75%", height: 25 }}
                      onChange={handlePopularFilterChange}
                      options={[
                        {
                          value: 'Top 10 Females',
                          label: 'Top 10 Females',
                        },
                        {
                          value: 'test',
                          label: 'test',
                        }, 
                        {
                          value: 'test',
                          label: 'test',
                        }
                      ]}
                    />
                  </div>
                </div> */}
                <div className="search-faculty-part">
                  <div className="search-faculty-text">
                    <div className="search-faculty-bullet">⋅</div>
                    <div className="search-faculty-name">Search Faculty</div>
                  </div>
                  <div className="search-faculty-field" style={{width: "100%"}}>{searchFacultyKeywordElement}</div>
                </div>

                {/* <div className="split">
                  <svg className="split-line" xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 299 2" fill="none">
                    <path d="M0 1H299" stroke="#B6ACD1"/>
                  </svg>
                </div> */}
                {/* <div className="demographic-filter-part">
                  <div className="demographic-filter-text">
                    <div className="demographic-filter-bullet">⋅</div>
                    <div className="demographic-filter-name">Demographic Filter</div>
                  </div>
                  <div className="demographic-filter-part-content"> */}
                    {/* <Form
                      size="small"
                      labelAlign="right"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      layout="horizontal"
                      style={{ maxWidth: "100%" }}
                    > */}
                      {/* <Form.Item label="Gender">
                        <Select
                          value={gender}
                          style={{ width: "100%", height: 20, marginLeft: 4 }}
                          onChange={handleGenderChange}
                          options={[
                            {
                              value: 'all',
                              label: 'all',
                            },
                            {
                              value: 'Female',
                              label: 'Female',
                            },
                            {
                              value: 'Male',
                              label: 'Male',
                            }
                          ]}
                        />
                      </Form.Item>
                      <Form.Item label="Tenure">
                        <Select
                          value={tenureStatus}
                          style={{ width: "100%", height: 20, marginLeft: 4 }}
                          onChange={handleTenureStatusChange}
                          options={[
                            {
                              value: 'all',
                              label: 'all',
                            },
                            {
                              value: 'Attained',
                              label: 'Attained',
                            },
                            {
                              value: 'Tenure Eligible',
                              label: 'Tenure Eligible',
                            }, 
                          ]}
                        />
                      </Form.Item> */}
                      {/* <Form.Item label="School">
                        <Select
                          value={school}
                          style={{width: "100%", height: 20, marginLeft: 4 }}
                          onChange={handleSchoolChange}
                          options={[
                            {
                              value: 'all',
                              label: 'all',
                            },
                            // NU
                            // {
                            //   value: 'McCormick Eng. & Applied Scien',
                            //   label: 'McCormick Eng. & Applied Scien',
                            // },
                            // {
                            //   value: 'Feinberg School of Medicine',
                            //   label: 'Feinberg School of Medicine',
                            // }, 
                            // {
                            //   value: 'College of Arts and Sciences',
                            //   label: 'College of Arts and Sciences',
                            // }

                            // VB
                            {
                              value: 'School of Medicine: Basic Sciences',
                              label: 'School of Medicine: Basic Sciences',
                            },
                            {
                              value: 'School of Engineering',
                              label: 'School of Engineering',
                            },
                            {
                              value: 'College of Arts and Science',
                              label: 'College of Arts and Science',
                            },
                            {
                              value: 'Peabody College',
                              label: 'Peabody College',
                            },
                            {
                              value: 'School of Nursing',
                              label: 'School of Nursing',
                            },
                            {
                              value: 'School of Medicine: Office of Health Sciences Education',
                              label: 'School of Medicine: Office of Health Sciences Education',
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item label="Department">
                        <Select
                          value={department}
                          style={{ width: "100%", height: 20, marginLeft: 4 }}
                          onChange={handleDepartmentChange}
                          options={departmentList.map(dept => ({ value: dept, label: dept }))}
                          // options={[
                          //   {
                          //     value: 'MCC Civil & Environmental Engg',
                          //     label: 'McCormick Eng. & Applied Scien',
                          //   },
                          //   {
                          //     value: 'Feinberg School of Medicine',
                          //     label: 'Feinberg School of Medicine',
                          //   }, 
                          //   {
                          //     value: 'College of Arts and Sciences',
                          //     label: 'College of Arts and Sciences',
                          //   }
                          // ]}
                        />
                      </Form.Item> */}
                      {/* <Form.Item label="Keyword">
                        <Select
                          disabled // Add this line
                          value={keyword}
                          style={{ width: "100%", height: 25, marginLeft: 4 }}
                          onChange={handleKeywordChange}
                          options={[
                            {
                              value: 'Cancer',
                              label: 'Cancer',
                            },
                            {
                              value: 'test',
                              label: 'test',
                            }, 
                            {
                              value: 'test',
                              label: 'test',
                            }
                          ]}
                        />
                      </Form.Item> */}
                    {/* </Form> */}
                  {/* </div>
                </div> */}
                {/* <div className="innovation-filter-part">
                  <SubTitle title="Innovation Filter" />
                  <div className="innovation-filter-part-content">
                    <Form
                        size="middle"
                        labelAlign="left"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 11 }}
                        layout="horizontal"
                        style={{ maxWidth: "100%" }}
                      >
                        <Form.Item label="Innovation Potential">
                          <Slider
                              // range
                              defaultValue={innPotential}
                              min = {0}
                              max = {100}
                              style={{ marginLeft: 16, width: "80%" }}
                              onChangeComplete={onInnPotentialChangeComplete}
                            />
                        </Form.Item>
                        <Form.Item label="Engagement with TTO">
                          <Slider
                              // range
                              defaultValue={engagement}
                              min = {0}
                              max = {100}
                              style={{ marginLeft: 16, width: "80%" }}
                              onChangeComplete={onEngagementChangeComplete}
                            />
                        </Form.Item>
                      </Form>
                  </div>
                </div> */}
                {/* <div className="confirm-button-container">
                  <div className="confirm-button">
                    <Button size={"small"}  icon={<SearchOutlined />} onClick={handleConfirmButton}>Confirm</Button>
                  </div>
                </div> */}

                {/* <div className="split">
                  <svg className="split-line" xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 299 2" fill="none">
                    <path d="M0 1H299" stroke="#B6ACD1"/>
                  </svg>
                </div> */}

                {/* <div className="graphic-filter-part">
                  <div className="graphic-filter-text">
                    <div className="graphic-filter-bullet">⋅</div>
                    <div className="graphic-filter-name">Untapped Potential Filter</div>
                  </div>
                  <div className="graphic-filter-part-content">
                    <Form
                        size="small"
                        labelAlign="right"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        style={{ maxWidth: "100%"}}
                      >
                        <Form.Item label="Potential">
                          <Select 
                            value={untapStatus}
                            style={{ width: "100%", height: 20, marginLeft: 4 }}
                            onChange={handleUntapStatusChange}
                            options={[
                              {
                                value: 'all',
                                label: 'all',
                              },
                              {
                                value: 'Untapped',
                                label: 'Untapped Potential',
                              },
                              {
                                value: 'Tapped',
                                label: 'Tapped Potential',
                              }
                            ]}
                          />
                        </Form.Item>
                      </Form>
                  </div>
                </div> */}
                {/* <div className="innovation-indicator-filter-part">
                  <div className="innovation-indicator-filter-text">
                    <div className="innovation-indicator-filter-bullet">⋅</div>
                    <div className="innovation-indicator-filter-name">Innovation Indicator</div>
                  </div>
                  <div className="innovation-indicator-filter-part-content">
                    <Form
                        size="small"
                        labelAlign="right"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        style={{ maxWidth: "100%"}}
                      >
                        <Form.Item label="Num Inventions 2001-2023">
                        <div className="innovation-indicator-filter" style={{width: "100%"}}>
                          <div className="innovation-indicator-label" style={{width: "100px", marginRight: "10px"}}>Num Inventions 2001-2023</div>
                          <div className="innovation-indicator-value" style={{width: "55%", marginRight: "10px"}}>
                            <Slider
                                range
                                defaultValue={numInventions2001to2023Range}
                                min = {0}
                                max = {3}
                                marks={numInventionsMarks}
                                style={{ marginLeft: 8, marginTop: 0, width: "100%" }}
                                onChangeComplete={handleNumInvention2001to2023Change}
                            />
                          </div>
                        </div>
                        </Form.Item>
                        <Form.Item label="Num Inventions 2019-2023">
                        <div className="innovation-indicator-filter" style={{width: "100%"}}>
                          <div className="innovation-indicator-label" style={{width: "100px", marginRight: "10px"}}>Num Inventions 2019-2023:</div>
                          <div className="innovation-indicator-value" style={{width: "55%", marginRight: "10px"}}>
                            0
                            <Slider
                                range
                                defaultValue={numInventions2019to2023Range}
                                min = {0}
                                max = {100}
                                marks={numInventionsMarks}
                                style={{ marginLeft: 8, marginTop: 0, width: "100%" }}
                                onChangeComplete={handleNumInvention2019to2023Change}
                            />
                          </div>
                        </div>
                        </Form.Item>
                      </Form>
                  </div>
                </div> */}
              </div>
      </ConfigProvider>
    </div>
  )
}

export default QueryView