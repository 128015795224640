import { MatrixTypes2 } from '../../../../types/data';
import { PaperFieldLevel } from '../../../../types/data.global';
import _ from 'lodash';
import * as dataForge from 'data-forge';

import { getReorderedPaperMatrixAndFlowCompondMode } from './getReorderedPaperMatrixAndFlow';
import calcColIdx from './calculator/calcColIdx';
import getFocusedCPCFieldFlowList from './getFocusedCPCFieldFlowList';
import getPaperFieldPatentCitationDiversity from './getPaperFieldPatentCitationDiversity';
import getCompondPatentTreemapData from './getCompondPatentTreemapData';
import getCompondFlowData from './getCompondFlowData';
import getViolinPlotXRange from './getViolinPlotXRange';
import { getPaperMatrixFigureParam, getPatentTreemapFigureParam, getFlowGroupFigureParam } from './getInterplayViewFigureParam';


const calcInterplayViewData = (
  filteredData: {
    paperMatrix: MatrixTypes2.CellUnit[];
    patentTreemap: MatrixTypes2.TreemapUnit[];
    paperPatentFlow: MatrixTypes2.FlowUnit[];
  },
  paperMetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict,
  cpcFieldLevel: string,
  topKFlow: number,
) => {
  // 1. calc diversity data for each paper field
  const diversityData = getPaperFieldPatentCitationDiversity(filteredData.paperPatentFlow)

  // 2. get compond flow & treemap
  const compondPatentTreemapData = getCompondPatentTreemapData(filteredData.patentTreemap, [])
  const compondFlowData: MatrixTypes2.CompondFlowUnit[] = getCompondFlowData(filteredData.paperPatentFlow, [])

  // // 3. based on compondFlow & compondTreemap, re-order [colIdx] in paperMatrix & flowPosition
  // const reorderOption: string = 'cosineSimilarity' // hard code, 'absoluteValue', 'percentageValue'
  // // let reorderedPaperMatrixData: MatrixTypes2.CellUnit[] = filteredData.paperMatrix
  // // let reorderedCompondFlowData: MatrixTypes2.CompondFlowUnit[] = compondFlowData
  // let reorderedPaperMatrixData: MatrixTypes2.CellUnit[] = _.cloneDeep(filteredData.paperMatrix)
  // let reorderedCompondFlowData: MatrixTypes2.CompondFlowUnit[] = _.cloneDeep(compondFlowData)
  // let colIdxDict: { [rawColIdx: number]: number } = {}
  // let colName2ColIdx: { [colName: string]: number } = {}
  // if (reorderOption === 'default') {
  //   /* Opt 1: by default - colIdx按照paperField种类排序 (paperFieldL0Order in data.global.ts)*/
  //   // do nothing
  // } else {
  //   // group by colName (paperField)

  //   // console.log('cpcFieldLevel: ', cpcFieldLevel)

  //   const focusedCPCFieldFlowList = getFocusedCPCFieldFlowList(compondFlowData, cpcFieldLevel, topKFlow)
  //   const focusedCPCFieldFlowDF: dataForge.DataFrame<number, MatrixTypes2.FocusedCPCFieldCompondFlowUnit> = new dataForge.DataFrame(focusedCPCFieldFlowList)

  //   // get global cpcFieldList for computing cosine similarity of paperFieldPair
  //   const globalCPCFieldList = focusedCPCFieldFlowDF
  //     .groupBy(item => item.cpcFieldCode)
  //     .select(group => {
  //       return group.first().cpcFieldCode
  //     }).inflate().toArray()

  //   let paperFieldGroupList: MatrixTypes2.PaperFieldFlowGroup2[] = focusedCPCFieldFlowDF
  //     .groupBy(item => item.colName)
  //     .select(group => {
  //       return {
  //         colIdx: group.first().colIdx,
  //         colName: group.first().colName,
  //         numPatent: group.deflate(row => row.numPatent).sum(), // for filtering 过细的flow
  //         paperFieldFlowList: group.toArray(),
  //       }
  //     }).inflate().toArray()

  //   const colIdxNameData = calcColIdx(paperFieldGroupList, reorderOption, globalCPCFieldList)
  //   colIdxDict = colIdxNameData.colIdxDict
  //   colName2ColIdx = colIdxNameData.colName2ColIdx
  //   const reorderedData = getReorderedPaperMatrixAndFlowCompondMode(colIdxDict, filteredData.paperMatrix, compondFlowData)
  //   reorderedPaperMatrixData = reorderedData.paperMatrixData
  //   reorderedCompondFlowData = reorderedData.flowData
  // }

  // 4. after reorder, calc figure position params
  const paperMatrix = getPaperMatrixFigureParam(filteredData.paperMatrix)
  const flowGroup = getFlowGroupFigureParam(compondFlowData, paperMatrix.cellGapWidth, paperMatrix.paperFieldOrderedList, cpcFieldLevel)
  const patentTreemap = getPatentTreemapFigureParam(compondPatentTreemapData, flowGroup.xScaleFlowWidth, cpcFieldLevel)

  return ({
    colIdxDict: {},
    colName2ColIdx: {},
    diversityData: diversityData,

    papermetriceRangeDictBackend: paperMetriceRangeDict,
    papermetriceRangeDict: getViolinPlotXRange(filteredData.paperMatrix),

    // compondPatentTreemapData: compondPatentTreemapData,
    // compondFlowData: compondFlowData,

    compondPatentTreemapData: compondPatentTreemapData,
    compondFlowData: compondFlowData,

    // historicalData: {
      reorderedPaperMatrixData: [],
      // reorderedFlowData:
    // },

    interplayViewFigureParam: {
      paperMatrix: paperMatrix,
      flowGroup: flowGroup,
      patentTreemap: patentTreemap,
    }
  })
}

const calcInterplayViewData_old_20230306 = (
  filteredData: {
    paperMatrix: MatrixTypes2.CellUnit[];
    patentTreemap: MatrixTypes2.TreemapUnit[];
    paperPatentFlow: MatrixTypes2.FlowUnit[];
  },
  paperMetriceRangeDict: MatrixTypes2.PaperMetriceRangeDict,
  cpcFieldLevel: string,
  topKFlow: number,
) => {
  // 1. calc diversity data for each paper field
  const diversityData = getPaperFieldPatentCitationDiversity(filteredData.paperPatentFlow)

  // 2. get compond flow & treemap
  const compondPatentTreemapData = getCompondPatentTreemapData(filteredData.patentTreemap, [])
  const compondFlowData: MatrixTypes2.CompondFlowUnit[] = getCompondFlowData(filteredData.paperPatentFlow, [])

  // 3. based on compondFlow & compondTreemap, re-order [colIdx] in paperMatrix & flowPosition
  const reorderOption: string = 'cosineSimilarity' // hard code, 'absoluteValue', 'percentageValue'
  // let reorderedPaperMatrixData: MatrixTypes2.CellUnit[] = filteredData.paperMatrix
  // let reorderedCompondFlowData: MatrixTypes2.CompondFlowUnit[] = compondFlowData
  let reorderedPaperMatrixData: MatrixTypes2.CellUnit[] = _.cloneDeep(filteredData.paperMatrix)
  let reorderedCompondFlowData: MatrixTypes2.CompondFlowUnit[] = _.cloneDeep(compondFlowData)
  let colIdxDict: { [rawColIdx: number]: number } = {}
  let colName2ColIdx: { [colName: string]: number } = {}
  if (reorderOption === 'default') {
    /* Opt 1: by default - colIdx按照paperField种类排序 (paperFieldL0Order in data.global.ts)*/
    // do nothing
  } else {
    // group by colName (paperField)

    // console.log('cpcFieldLevel: ', cpcFieldLevel)

    const focusedCPCFieldFlowList = getFocusedCPCFieldFlowList(compondFlowData, cpcFieldLevel, topKFlow)
    const focusedCPCFieldFlowDF: dataForge.DataFrame<number, MatrixTypes2.FocusedCPCFieldCompondFlowUnit> = new dataForge.DataFrame(focusedCPCFieldFlowList)

    // get global cpcFieldList for computing cosine similarity of paperFieldPair
    const globalCPCFieldList = focusedCPCFieldFlowDF
      .groupBy(item => item.cpcFieldCode)
      .select(group => {
        return group.first().cpcFieldCode
      }).inflate().toArray()

    let paperFieldGroupList: MatrixTypes2.PaperFieldFlowGroup2[] = focusedCPCFieldFlowDF
      .groupBy(item => item.colName)
      .select(group => {
        return {
          colIdx: group.first().colIdx,
          colName: group.first().colName,
          numPatent: group.deflate(row => row.numPatent).sum(), // for filtering 过细的flow
          paperFieldFlowList: group.toArray(),
        }
      }).inflate().toArray()

    /* Opt 2: 'absoluteValue', 按照每个paperFieldPair的共享的相同的patentCitation数量（绝对数值）ranking */
    // Problem: flow会过细。patentCitation最大的两个领域往往会被group到一起
    /* Opt 3: 'percentageValue', 按照每个paperFieldPair的共享的相同的patentCitation数量（百分比，相同的citation/全部citation数量）ranking */
    /* Opt 4: cosine similairty. 目前采用这个 */

    const colIdxNameData = calcColIdx(paperFieldGroupList, reorderOption, globalCPCFieldList)
    colIdxDict = colIdxNameData.colIdxDict
    colName2ColIdx = colIdxNameData.colName2ColIdx
    const reorderedData = getReorderedPaperMatrixAndFlowCompondMode(colIdxDict, filteredData.paperMatrix, compondFlowData)
    reorderedPaperMatrixData = reorderedData.paperMatrixData
    reorderedCompondFlowData = reorderedData.flowData
  }

  // 4. after reorder, calc figure position params
  const paperMatrix = getPaperMatrixFigureParam(reorderedPaperMatrixData)
  const flowGroup = getFlowGroupFigureParam(reorderedCompondFlowData, paperMatrix.cellGapWidth, paperMatrix.paperFieldOrderedList, cpcFieldLevel)
  const patentTreemap = getPatentTreemapFigureParam(compondPatentTreemapData, flowGroup.xScaleFlowWidth, cpcFieldLevel)

  return ({
    colIdxDict: colIdxDict,
    colName2ColIdx: colName2ColIdx,
    diversityData: diversityData,

    papermetriceRangeDictBackend: paperMetriceRangeDict,
    papermetriceRangeDict: getViolinPlotXRange(filteredData.paperMatrix),

    // compondPatentTreemapData: compondPatentTreemapData,
    // compondFlowData: compondFlowData,

    compondPatentTreemapData: compondPatentTreemapData,
    compondFlowData: reorderedCompondFlowData,

    // historicalData: {
      reorderedPaperMatrixData: reorderedPaperMatrixData,
      // reorderedFlowData:
    // },

    interplayViewFigureParam: {
      paperMatrix: paperMatrix,
      flowGroup: flowGroup,
      patentTreemap: patentTreemap,
    }
  })
}

export default calcInterplayViewData;