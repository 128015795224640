import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux"; // To call Action Creators
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { State } from "../state";
import "./App.scss";
import _ from 'lodash';

import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet } from 'react-router-dom';


// import customized components
import MyHeader from './components/HomePage/MyHeader';
import HomePage from "./components/HomePage";
// import TeaserPage from './components/HomePage/TeaserPage';
// import ThreeFinderPage from "./components/HomePage/ThreeFinderPage";

import PeopleFinderPage from './components/ThreePages/PeopleFinderPage';
import IdeaFinderPage from "./components/ThreePages/IdeaFinderPage";
import LoginPage from "./components/UserPages/Login";
import { checkToken } from './components/UserPages/Login/LoginForm';
import RegisterPage from "./components/UserPages/Register";
import DeleteAccountPage from "./components/UserPages/DeleteAccount";

// import BackgroundPage from './components/HomePage/BackgroundPage';
// import InnovationInsightsPage from './components/InnovationInsightsPage';
// import FrameworkPage from './components/FrameworkPage';
// import ExamplePage from './components/ExamplePage';
// import AboutPage from './components/HomePage/AboutPage';
// import AckPage from './components/HomePage/AckPage';
// import MaterialPage from './components/MaterialPage';

import ResearcherOverviewView from "./components/VIS2023Components/ResearcherOverviewView";
import ResearcherStatisticsView from "./components/VIS2023Components/ResearcherStatisticsView";
import { viewSize, panelStyle } from "../types/style.global";
import logoUrl from "../assets/logo.png";
import cssiImgUrl from '../assets/8-footer/cssi-horiz.png';
import innovationinsightsImgUrl from '../assets/8-footer/logo-horiz.svg';

import { MatrixDataRange } from "../types/data.global";
import { InteractionTypes } from '../types/data';

import * as d3 from  "d3";
import { flatGroup, select as d3Select } from 'd3';
import { ConfigProvider } from 'antd';
import { Select, Layout, Drawer } from "antd";
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import "antd/dist/antd.min.css";
const { Option } = Select;
const { Header, Content, Footer } = Layout;


function App() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>("Select");
  const [universityName, setUniversityName] = useState<string>("Select");
  const [scienceField, setScienceField] = useState<string>("Select");

  // store
  const dispatch = useDispatch();
  const {
    setIsLoginPage,
    getBackendData, // test
    getTimeLineData,
    getMatrixData,
    getScatterplotData,
    getResearcherStatisticsData,
    getPatentInspectionData,
    getPaperInspectionData,

    updateMatrixDataRange,
    //loading related
    updateResearcherOverviewLoading,
    updateResearcherStatisticViewLoading,
    updateTechnologyInspectionViewLoading,
    updateScienceInspectionViewLoading,
    updateMainviewLoading,
    // updateDatasetMetaInfo,
    // getDesctiptiveStatistics,
    // getGroupCareerAndLifeFlow,

    // setIsLoadingDSViewTableData,
    // setIsLoadingDSViewFlowData,
  } = bindActionCreators(actionCreators, dispatch);

  
  const testDataFromBackend: string = useSelector(
    (state: State) => state.dataReducer?.testData
  ) as string

  // data reducer
  const cpcFieldLevel: string = useSelector((state: State) => state.dataReducer?.cpcFieldLevel) as string
  const selectedCPCCodeList: InteractionTypes.SelectedCPCCodeInfo[] = useSelector((state: State) => state.dataReducer?.selectedCPCCodeList) as InteractionTypes.SelectedCPCCodeInfo[]
  const selectedCPCFieldCode: string = useSelector((state: State) => state.dataReducer?.selectedCPCFieldCode) as string
  const selectedCPCFieldLevel: string = useSelector((state: State) => state.dataReducer?.selectedCPCFieldLevel) as string
  const selectedResearcherIdList: string[] = useSelector((state: State) => state.dataReducer?.selectedResearcherIdList) as string[]
  const selectedPatentAssigneeID: string = useSelector((state: State) => state.dataReducer?.selectedPatentAssigneeID) as string

  // ui reducer
  const paperYearRange: number[] = useSelector((state: State) => state.uiReducer?.paperYearRange) as number[]
  const patentYearRange: number[] = useSelector((state: State) => state.uiReducer?.patentYearRange) as number[]
  const paperFieldLevel: string = useSelector((state: State) => state.uiReducer?.paperFieldLevel) as string
  const paperFieldL0Name: string = useSelector((state: State) => state.uiReducer?.paperFieldL0Name) as string
  const predictionScoreRange: number[] = useSelector((state: State) => state.uiReducer?.predictionResultParam.predictionScoreRange) as number[]
  const topKPatentAssignee: number = useSelector((state: State) => state.uiReducer?.topKPatentAssignee) as number
  const isLoginPage: boolean = useSelector((state: State) => state.uiReducer?.isLoginPage) as boolean
 
  const PrivateRoute: React.FC = () => {
    // If not authenticated, redirect to the login page
    // if (!isAuthenticated) {
    //   return <Navigate to="/login" />;
    // }
  
    // If authenticated, render children routes defined within this component
    if (checkToken()){
      setIsLoginPage(false);
      return <Outlet />
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  // 1. styles
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const onCloseDrawer = () => {
    setShowDrawer(false);
  };
  const onChangeDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  // 2. data
  const handleScienceFieldChange = (value: string) => {
    setScienceField(value)
  }
  const handleStateChange = (value: string) => {
    setStateName(value)
  }
  const handleUniversityChange = (value: string) => {
    // console.log(`change university to: ${value}`)
    setUniversityName(value)

    // test
    getBackendData(100)
    // add loading
    const initialMainview = async () => {
      try {
        updateMainviewLoading(true)
        const resultTimeline = await getTimeLineData( // [2001, 2020], PaperFieldLevel.L0, '', ['all']
          selectedCPCFieldCode,
          selectedCPCFieldLevel,
          paperYearRange,
          patentYearRange,
          paperFieldLevel,
          paperFieldL0Name,
          selectedResearcherIdList,
          selectedPatentAssigneeID,
        )
        const result2Matrix = await getMatrixData( // [2001, 2020], PaperFieldLevel.L0, '', ['all']
        selectedCPCFieldCode,
        selectedCPCFieldLevel,
        paperYearRange,
        patentYearRange,
        paperFieldLevel,
        paperFieldL0Name,
        selectedResearcherIdList,
        selectedPatentAssigneeID,
      )
      } catch (error) {
        console.error(error);
      } finally {
        updateMainviewLoading(false)
      }
    }

    const initialResearcherOverview = async () => {
      try {
        updateResearcherOverviewLoading(true)
        const result1 = await getScatterplotData(['state', 'university']);
      } catch (error) {
        console.error(error);
      } finally {
        updateResearcherOverviewLoading(false)
      }
    }
    const initialResearcherStatisticView = async () => {
      try {
        updateResearcherStatisticViewLoading(true)
        const result2 = await getResearcherStatisticsData(["all"]);
      } catch (error) {
        console.error(error);
      } finally {
        updateResearcherStatisticViewLoading(false)
      }
    }
    const initialTechnologyInspectionView = async () => {
      try {
        updateTechnologyInspectionViewLoading(true)
        const result3 = await getPatentInspectionData({
          cpcFieldLevel: cpcFieldLevel,
          cpcCodeList: selectedCPCCodeList.map(item => item.cpcFieldCode),
          // patentIDList: ["all"],
          topK: topKPatentAssignee,
          selectedResearcherIdList,
          patentYearRange,
        });
      } catch (error) {
        console.error(error);
      } finally {
        updateTechnologyInspectionViewLoading(false)
      }
    }
    const initialScienceInspectionView = async () => {
      try {
        updateScienceInspectionViewLoading(true)
        const result4 = await getPaperInspectionData({
          selectedResearcherIdList: selectedResearcherIdList,
          paperIDList: ["all"]
        });
      } catch (error) {
        console.error(error);
      } finally {
        updateScienceInspectionViewLoading(false)
      }
    }
    initialMainview();
    initialResearcherOverview();
    initialResearcherStatisticView();
    initialTechnologyInspectionView();
    initialScienceInspectionView();

    updateMatrixDataRange(MatrixDataRange.ALL); // to remember all NU data matrix position (当highlight一部分人的时候，matrix cell的位置依旧保持不变-->还是NU all data的位置)
    // const datasetMetaInfo: DescriptiveStatisticsTypes.DatasetMetaInfo = {
    //   stateName,
    //   university: value,
    // }
    // updateDatasetMetaInfo(datasetMetaInfo)
    // getDesctiptiveStatistics(datasetMetaInfo)
    // getGroupCareerAndLifeFlow(datasetMetaInfo)
    // setIsLoadingDSViewTableData(true)
    // setIsLoadingDSViewFlowData(true)
  }
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'white', // https://ant.design/docs/react/customize-theme
        colorSplit: 'rgba(5, 5, 5, 0.06)',
        colorText: '#4e4e4e',
        colorBgLayout: 'white', // '#f5f5f5', // backgournd
      },
    }}
  >
    <Router>
      <Layout>
        <div className="overlay"></div>
        ({!isLoginPage && <Header style={{ display: 'flex', alignItems: 'center' }} className='header-div'>
          <MyHeader />
        </Header>
        })
        <Content className='content-div'>
          {/* 1. homepage */}
          {/* <HomePage /> */}
          {/* <TeaserPage />
          <BackgroundPage />
          <ThreeFinderPage />
          <AboutPage /> */}

          {/* 2. 分页page */}
          {/* <div className='content'>
            <PeopleFinderPage />
            <IdeaFinderPage />
          </div> */}

          {/* 3. add router */}
          <Routes>
            <Route path="/login" element={<LoginPage />}  />
            {/* <Route path="/register" element={<RegisterPage />}/> */}
            {/* <Route path="/delete-account" element={<DeleteAccountPage />}/>*/}
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<HomePage />} />
              <Route path="people-finder" element={<div className='content'><PeopleFinderPage /></div>} />
              <Route path="idea-finder" element={<div className='content'><IdeaFinderPage/></div>} />
              <Route path="partner-finder" element={<div className='content'><HomePage /></div>} />
            </Route>  

            {/* <Route path="/" element={<HomePage />} />
            <Route path="/people-finder" element={<div className='content'><PeopleFinderPage /></div>} />
            <Route path="/idea-finder" element={<div className='content'><IdeaFinderPage /></div>} />
            <Route path="/partner-finder" element={<div className='content'><HomePage /></div>} /> */}
          </Routes>
        </Content>
        {!isLoginPage && <Footer style={{ textAlign: 'center' }} className='footer'>
          <div className="footer-div">
            <div className="footer-top">
              <div className="logo-group">
                <div className='image-div'>
                  <a href='https://www.kellogg.northwestern.edu/research/science-of-science.aspx'>
                    <img className='image' src={cssiImgUrl} alt={'cssi-logo'} />
                  </a>
                </div>
                <div className='image-div'>
                  <img className='image' src={innovationinsightsImgUrl} alt={'innovationinsights-logo'} />
                </div>
              </div>
            </div>

            <div className="footer-bottom">
              <div className="footer-bottom-left">
                Copyright © 2022-2024 CSSI
              </div>
              <div className="footer-bottom-right">
                <div className='org-info'>
                  <div className='text-div'>
                    <a href='https://www.kellogg.northwestern.edu/research/science-of-science.aspx' style={{color: 'white'}}>
                      The Center for Science of Science & Innovation
                    </a>
                  </div>
                  <div className='text-div'>Northwestern University</div>
                  <div className='text-div'>2211 Campus Drive</div>
                  <div className='text-div'>Evanston, IL 60208</div>
                </div>
              </div>
              
            </div>
          
          </div>
          {/* <div className='footer-div'>
            <div className='left'>
              <div className='logo-group'>
                <div className='image-div'>
                  <a href='https://www.kellogg.northwestern.edu/research/science-of-science.aspx'>
                    <img className='image' src={cssiImgUrl} alt={'cssi-logo'} />
                  </a>
                </div>
                <div className='image-div'>
                  <img className='image' src={innovationinsightsImgUrl} alt={'innovationinsights-logo'} />
                </div>
              </div>
            </div>
            <div className='center'>
              Copyright © 2022-2024 CSSI
            </div>
            <div className='right'>
              <div className='org-info'>
                <div className='text-div'>
                  <a href='https://www.kellogg.northwestern.edu/research/science-of-science.aspx' style={{color: 'white'}}>
                    The Center for Science of Science & Innovation
                  </a>
                </div>
                <div className='text-div'>Northwestern University</div>
                <div className='text-div'>2211 Campus Drive</div>
                <div className='text-div'>Evanston, IL 60208</div>
              </div>
            </div>
          </div> */}
        </Footer>
      }
      </Layout>
    </Router>

  </ConfigProvider>
    // <div className="App">
    //   <Layout className="layout">
    //     <Header className="header">
    //       <div className="system-logo">
    //         <img src={logoUrl} alt="" style={{ height: 45 }} />
    //         <span>Visual Analytics of Innovation Insights</span>
    //       </div>
    //       <div className="data-selection-drawer">
    //         <Drawer
    //           height={255}
    //           placement="top"
    //           closable={true}
    //           mask={false}
    //           onClose={onCloseDrawer}
    //           open={showDrawer}
    //           getContainer={false}
    //         >
    //           <div className="selector">
    //             <div className="selector-name">State:&nbsp;</div>
    //             <Select
    //               defaultValue="Select"
    //               style={{ width: 200 }}
    //               onChange={handleStateChange}
    //             >
    //               <Option value="All">All</Option>
    //               <Option value="IL">IL</Option>
    //               <Option value="DC">DC</Option>
    //             </Select>
    //           </div>
    //           <div className="selector">
    //             <div className="selector-name">University:&nbsp;</div>
    //             <Select
    //               defaultValue="Select"
    //               style={{ width: 200 }}
    //               onChange={handleUniversityChange}
    //             >
    //               <Option value="All">All</Option>
    //               <Option value="NU">NU</Option>
    //               <Option value="UChicago">UChicago</Option>
    //             </Select>
    //           </div>
    //           <div className="selector">
    //             <div className="selector-name">Science Field:&nbsp;</div>
    //             <Select
    //               defaultValue="Select"
    //               style={{ width: 200 }}
    //               onChange={handleScienceFieldChange}
    //             >
    //               <Option value="All">All</Option>
    //               <Option value="VIS/HCI/Graphics">VIS/HCI/Graphics</Option>
    //               <Option value="Deep Learning">Deep Learning</Option>
    //               <Option value="Chemistry">Chemistry</Option>
    //               <Option value="Medical">Medical</Option>
    //               <Option value="Material Science">Material Science</Option>
    //             </Select>
    //           </div>
    //         </Drawer>
    //         <button className="drawer-button" onClick={onChangeDrawer}>
    //           <div className="dataset-name button-div">
    //             <span className="span-text">STATE: </span>
    //             <span className="data-text"> {stateName}</span>
    //           </div>
    //           <div className="group-criteria button-div">
    //             <span className="span-text">UNIVERSITY: </span>
    //             <span className="data-text"> {universityName}</span>
    //           </div>
    //         </button>
    //       </div>
    //     </Header>
    //     <Layout className="body">
    //       <Content className="content">
    //         <div className="view-layout">
    //           <div className="side-two-views">
    //             <ResearcherOverviewView
    //               viewParam={viewSize.researcherOverviewView}
    //               panelParam={panelStyle}
    //               viewName={"Researcher Overview"}
    //             />
    //             <ResearcherStatisticsView
    //               viewParam={viewSize.researcherStatisticsView}
    //               panelParam={panelStyle}
    //               viewName={"Researcher Statistics"}
    //             />
    //           </div>
    //           <div className="side-two-views">
    //             <MainView
    //               viewParam={viewSize.mainView}
    //               panelParam={panelStyle}
    //               viewName={"Innovation View"}
    //             />
    //           </div>
    //           <div className="side-two-views">
    //             <TechnologyInspectionView
    //               viewParam={viewSize.technologyInspectionView}
    //               panelParam={panelStyle}
    //               viewName={"Technology Inspection"}
    //             />
    //             <ScienceInspectionView
    //               viewParam={viewSize.scienceInspectionView}
    //               panelParam={panelStyle}
    //               viewName={"Science Inspection"}
    //             />
    //           </div>
    //         </div>
    //       </Content>
    //     </Layout>
    //   </Layout>
    // </div>
  );
}

export default App;