import { MatrixTypes, MatrixTypes2 } from '../../../../types/data';
import _ from 'lodash';
import * as dataForge from 'data-forge';
import * as d3 from "d3";

import { PatentCitationType, CPCFieldLevelList } from '../../../../types/data.global';
import { svgSize, matrixViewStyle, numCol } from '../../../../types/style.global';

// 1. internal calculators
export const getPaperFieldFlowList = (colIdx: number, colName: string, paperFieldGroupList: MatrixTypes2.PaperFieldFlowGroup2[]) => {
  const paperFieldGroup: MatrixTypes2.PaperFieldFlowGroup2 = _.find(paperFieldGroupList, (paperField) => (paperField.colIdx === colIdx) && (paperField.colName === colName)) as MatrixTypes2.PaperFieldFlowGroup2
  if (!_.isEmpty(paperFieldGroup)) {
    return paperFieldGroup
  } else {
    return ({
      colIdx: colIdx,
      colName: colName,
      numPatent: 0,
      paperFieldFlowList: [],
    })
  }
}
export const getPaperFieldPairList = (numPaperFieldPair: number, paperFieldGroupList: MatrixTypes2.PaperFieldFlowGroup2[], paperFieldOrderedList: MatrixTypes2.PaperFieldUnit[]) => {
  const paperFieldPairList: MatrixTypes2.PaperFieldPairUnit[] = []
  _.forEach(_.range(numPaperFieldPair), (paperFieldPairIdx: number) => {
    const leftPaperFieldFlowData: MatrixTypes2.PaperFieldFlowGroup2 = getPaperFieldFlowList(paperFieldOrderedList[2 * paperFieldPairIdx].colIdx, paperFieldOrderedList[2 * paperFieldPairIdx].colName, paperFieldGroupList)
    // deal with odd number of paper fields
    let rightPaperFieldFlowData: MatrixTypes2.PaperFieldFlowGroup2 = {
      colIdx: Infinity,
      colName: '',
      numPatent: 0,
      paperFieldFlowList: [],
    }
    if ((2 * paperFieldPairIdx + 1) <= paperFieldOrderedList.length - 1) {
      rightPaperFieldFlowData = getPaperFieldFlowList(paperFieldOrderedList[2 * paperFieldPairIdx + 1].colIdx, paperFieldOrderedList[2 * paperFieldPairIdx + 1].colName, paperFieldGroupList)
    }
    // append paper_field pair
    const numPatent: number =
      _.sumBy(leftPaperFieldFlowData.paperFieldFlowList, (flowUnit) => flowUnit.numPatent)
      + _.sumBy(rightPaperFieldFlowData.paperFieldFlowList, (flowUnit) => flowUnit.numPatent)
    const paperFieldPair: MatrixTypes2.PaperFieldPairUnit = {
      numPatent: numPatent,
      leftPaperFieldFlowData: {
        colName: leftPaperFieldFlowData.colName,
        colIdx: leftPaperFieldFlowData.colIdx,
        numPatent: _.sumBy(leftPaperFieldFlowData.paperFieldFlowList, (flowUnit) => flowUnit.numPatent),
        flowList: _.sortBy(leftPaperFieldFlowData.paperFieldFlowList, item => item.rowIdx),
      },
      rightPaperFieldFlowData: {
        colName: rightPaperFieldFlowData.colName,
        colIdx: rightPaperFieldFlowData.colIdx,
        numPatent: _.sumBy(rightPaperFieldFlowData.paperFieldFlowList, (flowUnit) => flowUnit.numPatent),
        flowList: _.sortBy(rightPaperFieldFlowData.paperFieldFlowList, item => item.rowIdx),
      },
    }
    paperFieldPairList.push(paperFieldPair)
  })

  return (paperFieldPairList)
}

// 2. figure param functions
export const getFlowGroupFigureParam = (compondFlowData: MatrixTypes2.CompondFlowUnit[], cellGapWidth: number, paperFieldOrderedList: MatrixTypes2.PaperFieldUnit[], cpcFieldLevel: string) => {
  // calc based on compond flow (historical + predicted)

  // console.log('\n======== getFlowGroupFigureParam =========')
  // console.log('compondFlowData: ', compondFlowData)
  // console.log('cellGapWidth: ', cellGapWidth)
  // console.log('paperFieldOrderedList: ', paperFieldOrderedList)
  // console.log('cpcFieldLevel: ', cpcFieldLevel)

  // temp: hardcode
  // const cpcFieldLevel: string = CPCFieldLevelList.CPCSUBSECTION // 最细粒度的flow单位

  const numPaperFieldPair: number = Math.ceil(paperFieldOrderedList.length / 2)

  // 0. transform json data to dataframe
  const compondFlowDF: dataForge.DataFrame<number, MatrixTypes2.CompondFlowUnit> = new dataForge.DataFrame(compondFlowData)

  // 1. aggregrate to get 目前focus的flow最小粒度：
  //    focusedCPCFieldFlowDF: MatrixTypes2.FocusedCPCFieldCompondFlowUnit
  let focusedCPCFieldFlowList: MatrixTypes2.FocusedCPCFieldCompondFlowUnit[] = compondFlowDF
  .groupBy(compondFlow => {
    switch (cpcFieldLevel) {
      case CPCFieldLevelList.CPCSECTION:
        return [compondFlow.colIdx, compondFlow.rowIdx, compondFlow.cpcSection]
      case CPCFieldLevelList.CPCSUBSECTION:
        return [compondFlow.colIdx, compondFlow.rowIdx, compondFlow.cpcSubsection]
      case CPCFieldLevelList.CPCGROUP:
        return [compondFlow.colIdx, compondFlow.rowIdx, compondFlow.cpcGroup]
      default:
        console.log('Wrong cpcFieldLevel!!')
        return [compondFlow.colIdx, compondFlow.rowIdx, compondFlow.cpcSection]
    }
  })
  .select(colRowCPCFieldGroupDF => {
    let cpcFieldCode: string = ''
    switch (cpcFieldLevel) {
      case CPCFieldLevelList.CPCSECTION:
        cpcFieldCode = colRowCPCFieldGroupDF.first().cpcSection
        break
      case CPCFieldLevelList.CPCSUBSECTION:
        cpcFieldCode = colRowCPCFieldGroupDF.first().cpcSubsection
        break
      case CPCFieldLevelList.CPCGROUP:
        cpcFieldCode = colRowCPCFieldGroupDF.first().cpcGroup
        break
      default:
        console.log('Wrong cpcFieldLevel!!')
        cpcFieldCode = colRowCPCFieldGroupDF.first().cpcSection
        break
    }
    return {
      cpcFieldLevel: cpcFieldLevel,
      cpcFieldCode: cpcFieldCode,

      colIdx: colRowCPCFieldGroupDF.first().colIdx,
      colName: colRowCPCFieldGroupDF.first().colName,
      rowIdx: colRowCPCFieldGroupDF.first().rowIdx,
      rowRange: colRowCPCFieldGroupDF.first().rowRange,
      numPatent: colRowCPCFieldGroupDF.deflate(row => row.numPatent).sum(),

      flowType: colRowCPCFieldGroupDF.first().flowType,
    }
  }).inflate().toArray()
  focusedCPCFieldFlowList = _.sortBy(focusedCPCFieldFlowList, (flowUnit) => [flowUnit.cpcFieldCode, flowUnit.colIdx, flowUnit.rowIdx])
  const focusedCPCFieldFlowDF: dataForge.DataFrame<number, MatrixTypes2.FocusedCPCFieldCompondFlowUnit> = new dataForge.DataFrame(focusedCPCFieldFlowList)

  // 2. aggregrate by paperFieldPair, to get max sum_num_patent in all paperFieldPairs
  let paperFieldGroupList: MatrixTypes2.PaperFieldFlowGroup2[] = focusedCPCFieldFlowDF.groupBy(item => item.colName).select(group => {
    return {
      colIdx: group.first().colIdx,
      colName: group.first().colName,
      numPatent: group.deflate(row => row.numPatent).sum(), // for filtering 过细的flow
      paperFieldFlowList: group.toArray(),
    }
  }).inflate().toArray()
  paperFieldGroupList = _.sortBy(paperFieldGroupList, (paperField) => paperField.colIdx)
  const paperFieldPairList: MatrixTypes2.PaperFieldPairUnit[] = getPaperFieldPairList(numPaperFieldPair, paperFieldGroupList, paperFieldOrderedList)
  const maxNumPatent: number = _.maxBy(paperFieldPairList, paperFieldPair => paperFieldPair.numPatent)?.numPatent as number
  const xScaleFlowWidth: number = cellGapWidth / maxNumPatent

  // console.log('maxNumPatent: ', maxNumPatent)
  // console.log('--> flow, total numPatent: ', _.sumBy(focusedCPCFieldFlowList, (flowUnit) => flowUnit.numPatent), _.sumBy(compondFlowData, flowUnit => flowUnit.numPatent))

  return {
    maxNumPatent: maxNumPatent,
    xScaleFlowWidth: xScaleFlowWidth,
  }
}

export const getPatentTreemapFigureParam = (compondTreemapData: MatrixTypes2.CompondTreemapUnit[], xScaleFlowWidth: number, cpcFieldLevel: string) => {
  // console.log('xScaleFlowWidth: ', xScaleFlowWidth)
  // console.log('compondTreemapData: ', compondTreemapData)

  // console.log('\n======== getPatentTreemapFigureParam =========')
  // console.log('compondTreemapData: ', compondTreemapData)
  // console.log('xScaleFlowWidth: ', xScaleFlowWidth)
  // console.log('cpcFieldLevel: ', cpcFieldLevel)

  // // temp: hardcode
  // const cpcFieldLevel: string = CPCFieldLevelList.CPCSUBSECTION // 最细粒度的flow单位

  // 1. get treemap figure params
  const numPatentSum: number = _.sumBy(compondTreemapData, (patentCPCSection) => patentCPCSection.numPatentCitation)
  // option 1: use independent xScale (hard code)
  // const xScale: number = treemapWidth / numPatentSum
  // option 2: use same scale with flow width
  const xScale: number = xScaleFlowWidth * 2 // 4// 2, 3.5

  // treemap整体的transformLeft
  // // opt-1: 写死的
  // const treemapWidth: number = (svgSize.mainviewPatent.width - 2 * svgSize.mainviewPatent.margin) * matrixViewStyle.fractionPatentTreemapWidth
  // const patentTreemapTransformLeft: number = ((svgSize.mainviewMatrix.width - 2 * svgSize.mainviewMatrix.margin) - treemapWidth) / 2
  // opt-2: 居中
  const treemapWidth: number = xScale * _.sumBy(compondTreemapData, (treemapUnit) => treemapUnit.numPatentCitation)
  const patentTreemapTransformLeft: number = ((svgSize.mainviewMatrix.width - 2 * svgSize.mainviewMatrix.margin) - treemapWidth) / 2

  const patentTreemapTransformTop: number = svgSize.mainviewPaperMatrix.height + svgSize.mainviewFlow.height
  const treemapHeightByLevel: number = (svgSize.mainviewPatent.height - 2 * svgSize.mainviewPatent.margin) / 4


  // 2. transform to current min treemap unit
  const compondTreemapDF: dataForge.DataFrame<number, MatrixTypes2.CompondTreemapUnit> = new dataForge.DataFrame(compondTreemapData)

  // 3. draw treemap
  const cpcSectionGroupList: MatrixTypes2.CPCFieldGroupUnit[] = compondTreemapDF.groupBy(item => item.cpcSection).select(group => {
    return {
      cpcFieldLevel: 'cpcSection',
      cpcFieldCode: group.first().cpcSection,
      numPatent: group.deflate(row => row.numPatentCitation).sum(),
      compondTreemapUnitList: group.toArray(),
    }
  }).inflate().toArray()

  // 3-2. calc flow position
  // temp: 目前是嵌套模式，不scalable。TODO：记录每一层的rect/flow position，变成扁平模式（for loop循环三个cpc field）
  const cpcSectionTreemapFigureParam: MatrixTypes.CPCFieldFlowPosition[] = [] // for flow position
  const cpcSubsectionTreemapParam: MatrixTypes.CPCFieldFlowPosition[] = []
  const cpcGroupTreemapParam: MatrixTypes.CPCFieldFlowPosition[] = []
  _.forEach(cpcSectionGroupList, (cpcSectionData: MatrixTypes2.CPCFieldGroupUnit, cpcSectionIdx: number) => {
    // 1. level 1: cpcSection
    const cpcSection: string = cpcSectionData.cpcFieldCode
    const cpcSectionCompondNumPatent: number = cpcSectionData.numPatent
    const cpcSectionCompondTreemapUnitList = cpcSectionData.compondTreemapUnitList

    // get cpcSection rect param
    const cpcSectionCompondRectWidth: number = cpcSectionCompondNumPatent * xScale
    const cpcSectionCompondRectHeight: number = treemapHeightByLevel
    const cpcFieldHistoricalRectWidth: number = _.sumBy(cpcSectionCompondTreemapUnitList, (treemapUnit) => treemapUnit.numPatentCitationDict.historicalNumPatentCitation * xScale)
    const cpcFieldPredictedRectWidth: number = _.sumBy(cpcSectionCompondTreemapUnitList, (treemapUnit) => treemapUnit.numPatentCitationDict.predictedNumPatentCitation * xScale)

    // get transform left based on previous rects
    const cumulatedPrevcpcSectionCompondNumPatent: number = _.sumBy(cpcSectionGroupList.slice(0, cpcSectionIdx), (item) => item.numPatent)
    const cpcSectionTransformLeft: number = cumulatedPrevcpcSectionCompondNumPatent * xScale
    cpcSectionTreemapFigureParam.push({
      cpcFieldLevel: 'cpcSection',  // 'cpcSection', 'cpcSubsection', 'cpcGroup'
      cpcFieldCode: cpcSection,
      cpcFieldCompondNumPatent: cpcSectionCompondNumPatent,
      cpcFieldCompondRectParam: {
        cpcFieldCompondRectWidth: cpcSectionCompondRectWidth,
        cpcFieldCompondRectHeight: cpcSectionCompondRectHeight,
        cpcFieldCompondRectMidPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + cpcSectionCompondRectWidth / 2,
        cpcFieldCompondRectStartingPointX: patentTreemapTransformLeft + cpcSectionTransformLeft,
        flowGroupStartingPointX: 0,
      },
      cpcFieldHistoricalRectWidth: cpcFieldHistoricalRectWidth,
      cpcFieldPredictedRectWidth: cpcFieldPredictedRectWidth,
    })

    // 2. level 2: cpcSubsection
    // group by cpcSubsection
    const cpcSectionCompondTreemapUnitDF: dataForge.DataFrame<number, MatrixTypes2.CompondTreemapUnit> = new dataForge.DataFrame(cpcSectionCompondTreemapUnitList)
    const cpcSubsectionGroupList: MatrixTypes2.CPCFieldGroupUnit[] = cpcSectionCompondTreemapUnitDF.groupBy(item => item.cpcSubsection).select(group => {
      return {
        cpcFieldLevel: 'cpcSubsection',
        cpcFieldCode: group.first().cpcSubsection,
        numPatent: group.deflate(row => row.numPatentCitation).sum(),
        compondTreemapUnitList: group.toArray(),
      }
    }).inflate().toArray()
    _.forEach(cpcSubsectionGroupList, ((cpcSubsectionData: MatrixTypes2.CPCFieldGroupUnit, cpcSubsectionIdx: number) => {
      const cpcSubsection: string = cpcSubsectionData.cpcFieldCode
      const cpcSubsectionCompondNumPatent: number = cpcSubsectionData.numPatent
      const cpcSubsectionCompondTreemapUnitList = cpcSubsectionData.compondTreemapUnitList

      // get cpcSubsection rect param
      const cpcSubsectionCompondRectWidth: number = cpcSubsectionCompondNumPatent * xScale
      const cpcSubsectionCompondRectHeight: number = treemapHeightByLevel
      const cpcSubsectionHistoricalRectWidth: number = _.sumBy(cpcSubsectionCompondTreemapUnitList, (treemapUnit) => treemapUnit.numPatentCitationDict.historicalNumPatentCitation * xScale)
      const cpcSubsectionPredictedRectWidth: number = _.sumBy(cpcSubsectionCompondTreemapUnitList, (treemapUnit) => treemapUnit.numPatentCitationDict.predictedNumPatentCitation * xScale)
      // const cpcSubsectionHistoricalRectWidth: number = cpcSubsectionData.numPatentCitationDict.historicalNumPatentCitation * xScale
      // const cpcSubsectionPredictedRectWidth: number = cpcSubsectionData.numPatentCitationDict.predictedNumPatentCitation * xScale
      const cumulatedPrevCPCSubsectionNumPatent: number = _.sumBy(cpcSubsectionGroupList.slice(0, cpcSubsectionIdx), (cpcSubsectionData) => _.sumBy(cpcSubsectionData.compondTreemapUnitList, (treemapUnit) => treemapUnit.numPatentCitation))
      const cpcSubsectionTransformLeft: number = cumulatedPrevCPCSubsectionNumPatent * xScale
      cpcSubsectionTreemapParam.push({
        cpcFieldLevel: 'cpcSubsection',  // 'cpcSection', 'cpcSubsection', 'cpcGroup'
        cpcFieldCode: cpcSubsection,
        cpcFieldCompondNumPatent: cpcSubsectionCompondNumPatent,
        cpcFieldCompondRectParam: {
          cpcFieldCompondRectWidth: cpcSubsectionCompondRectWidth,
          cpcFieldCompondRectHeight: cpcSubsectionCompondRectHeight,
          cpcFieldCompondRectMidPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + cpcSubsectionTransformLeft + cpcSubsectionCompondRectWidth / 2,
          cpcFieldCompondRectStartingPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + cpcSubsectionTransformLeft,
          flowGroupStartingPointX: 0,
        },
        cpcFieldHistoricalRectWidth: cpcSubsectionHistoricalRectWidth,
        cpcFieldPredictedRectWidth: cpcSubsectionPredictedRectWidth,
      })


      // 3. level 3: cpcGroup
      _.forEach(cpcSubsectionCompondTreemapUnitList, ((cpcGroupData: MatrixTypes2.CompondTreemapUnit, cpcGroupIdx: number) => {
        const cpcGroup: string = cpcGroupData.cpcGroup
        const cpcGroupCompondNumPatent: number = cpcGroupData.numPatentCitation

        // get cpcGroup rect param
        const cpcGroupCompondRectWidth: number = cpcGroupCompondNumPatent * xScale
        const cpcGroupCompondRectHeight: number = treemapHeightByLevel
        const cpcGroupHistoricalRectWidth: number = cpcGroupData.numPatentCitationDict.historicalNumPatentCitation * xScale
        const cpcGroupPredictedRectWidth: number = cpcGroupData.numPatentCitationDict.predictedNumPatentCitation * xScale
        const cumulatedPrevCPCGroupNumPatent: number = _.sumBy(cpcSubsectionCompondTreemapUnitList.slice(0, cpcGroupIdx), (cpcGroupData) => cpcGroupData.numPatentCitation)
        const cpcGroupTransformLeft: number = cumulatedPrevCPCGroupNumPatent * xScale
        cpcGroupTreemapParam.push({
          cpcFieldLevel: 'cpcGroup',  // 'cpcSection', 'cpcSubsection', 'cpcGroup'
          cpcFieldCode: cpcGroup,
          cpcFieldCompondNumPatent: cpcGroupCompondNumPatent,
          cpcFieldCompondRectParam: {
            cpcFieldCompondRectWidth: cpcGroupCompondRectWidth,
            cpcFieldCompondRectHeight: cpcGroupCompondRectHeight,
            cpcFieldCompondRectMidPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + cpcSubsectionTransformLeft + cpcGroupTransformLeft + cpcGroupCompondRectWidth / 2,
            cpcFieldCompondRectStartingPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + cpcSubsectionTransformLeft + cpcGroupTransformLeft,
            flowGroupStartingPointX: 0,
          },
          cpcFieldHistoricalRectWidth: cpcGroupHistoricalRectWidth,
          cpcFieldPredictedRectWidth: cpcGroupPredictedRectWidth,
        })

        })
      )
    })
  )})

  // add root node
  const allNumPatent: number = _.sumBy(cpcSectionGroupList, (cpcSectionData) => cpcSectionData.numPatent)
  const allHistoricalRectWidth: number = _.sumBy(compondTreemapData, (compondTreemap) => compondTreemap.numPatentCitationDict.historicalNumPatentCitation * xScale)
  const allPredictedRectWidth: number = _.sumBy(compondTreemapData, (compondTreemap) => compondTreemap.numPatentCitationDict.predictedNumPatentCitation * xScale)
  const cpcRootTreemapFigureParam: MatrixTypes.CPCFieldFlowPosition[] = [{
    cpcFieldLevel: 'cpcRoot',
    cpcFieldCode: 'root',
    cpcFieldCompondNumPatent: allNumPatent,
    cpcFieldCompondRectParam: {
      cpcFieldCompondRectWidth: allNumPatent * xScale,
      cpcFieldCompondRectHeight: treemapHeightByLevel,
      cpcFieldCompondRectMidPointX: patentTreemapTransformLeft + allNumPatent * xScale / 2,
      cpcFieldCompondRectStartingPointX: patentTreemapTransformLeft,
      flowGroupStartingPointX: 0,
    },
    cpcFieldHistoricalRectWidth: allHistoricalRectWidth,
    cpcFieldPredictedRectWidth: allPredictedRectWidth,
  }]

  return {
    xScale: xScale,
    treemapWidth: treemapWidth,
    treemapHeightByLevel: treemapHeightByLevel,
    patentTreemapTransformLeft: patentTreemapTransformLeft,
    patentTreemapTransformTop: patentTreemapTransformTop,
    treemapFigureParam: {
      cpcGroup: cpcGroupTreemapParam,
      cpcSubsection: cpcSubsectionTreemapParam,
      cpcSection: cpcSectionTreemapFigureParam,
      cpcRoot: cpcRootTreemapFigureParam,
    }
    // cpcSectionTreemapFigureParam: cpcSectionTreemapFigureParam,
    // cpcSubsectionTreemapParam: cpcSubsectionTreemapParam,
    // cpcGroupTreemapParam: cpcGroupTreemapParam,
  }
}

export const getPaperMatrixFigureParam = (paperMatrixData: MatrixTypes2.CellUnit[]) => {
  const patentCitationType: string = PatentCitationType.CALL // hard code

  const matrixWidth: number = svgSize.mainviewPaperMatrix.width - 2 * svgSize.mainviewPaperMatrix.margin
  const matrixHeight: number = svgSize.mainviewPaperMatrix.height - 2 * svgSize.mainviewPaperMatrix.margin - matrixViewStyle.paperMatrixFieldNameHeight

  // 1. get cell max min value
  const cellNumPaperRange: number[] = [
    _.minBy(paperMatrixData, (item) => item.numPaper)?.numPaper as number,
    _.maxBy(paperMatrixData, (item) => item.numPaper)?.numPaper as number,
  ]
  // console.log(paperMatrixData.map((item) => item.numPaper))
  // console.log('cellNumPaperRange: ', cellNumPaperRange)


  let cellAvgPatentCitationRange: number[] = []
  if (patentCitationType === PatentCitationType.CALL) {
    cellAvgPatentCitationRange =  [
      _.minBy(paperMatrixData, (item) => item.avgNumPatentCitationCall)?.avgNumPatentCitationCall as number,
      _.maxBy(paperMatrixData, (item) => item.avgNumPatentCitationCall)?.avgNumPatentCitationCall as number,
    ]
  } else if (patentCitationType === PatentCitationType.C5) {
    cellAvgPatentCitationRange =  [
      _.minBy(paperMatrixData, (item) => item.avgNumPatentCitationC5)?.avgNumPatentCitationC5 as number,
      _.maxBy(paperMatrixData, (item) => item.avgNumPatentCitationC5)?.avgNumPatentCitationC5 as number,
    ]
  } else {
    console.log('error in Store, getPaperMatrixFigureParam.tsx line 45: incorrect patentCitationType!')
  }

  const numPaperField: number = _.uniq(_.map(paperMatrixData, 'colIdx')).length
  // const numPaperField: number = numCol
  const firstColIdx: number = paperMatrixData[0].colIdx
  const numYQuantile: number = _.filter(paperMatrixData, { colIdx: firstColIdx }).length // TODO: 可能有问题。如果是0的话，这个row的object不会出现的-后端处理需要修改

  // const test = ScaleLinear<string>(['red', 'blue'])
  const opacityScale: number = (1 - 0) / (cellNumPaperRange[1] - 0)
  const opacityScaleLog: number = (1 - 0) / (Math.log1p(cellNumPaperRange[1] + 1) - Math.log1p(0 + 1))
  const opacityScaleRoot: number = (1 - 0) / (Math.pow(cellNumPaperRange[1], 0.3) - 0)
  const xScale: number = +(matrixWidth / numPaperField).toFixed(2)
  const yScale: number = +(matrixHeight / numYQuantile).toFixed(2)
  const cellWidthHeight: number = xScale * matrixViewStyle.fractionCellWidthHeight
  const cellGapWidth: number = xScale * (1 - matrixViewStyle.fractionCellWidthHeight) - matrixViewStyle.paperMatrixCellGapMargin * 2
  const cellCircleRMax: number = cellWidthHeight / 2
  const cellCircleRScale: number = (cellCircleRMax - 0) / (cellAvgPatentCitationRange[1] - 0)
  const cellCircleRScaleLog: number = (cellCircleRMax - 0) / (Math.log(cellAvgPatentCitationRange[1]) - 0)
  const cellCircleRScaleRoot: number = (cellCircleRMax - 0) / (Math.pow(cellAvgPatentCitationRange[1], 0.5) - 0)


  // generate flow level 1 ending pointX (to each paper field)
  const paperMatrixGapStartingPointXList: MatrixTypes2.PaperFieldFlowPosition[] = []
  _.forEach(_.range(numPaperField), (colIdx: number) => {
    const colMatrixGapStartingPointX: number = colIdx * xScale + cellWidthHeight + matrixViewStyle.paperMatrixCellGapMargin
    const colMatrixGapMidPointX: number = colIdx * xScale + cellWidthHeight +  xScale * (1 - matrixViewStyle.fractionCellWidthHeight) / 2
    const paperMatrixGapStartingPointX: MatrixTypes2.PaperFieldFlowPosition = {
      colIdx: colIdx,
      colMatrixGapStartingPointX: colMatrixGapStartingPointX,
      colMatrixGapMidPointX: colMatrixGapMidPointX,
      colCellMidPointX: colIdx * xScale + cellWidthHeight / 2,
    }
    paperMatrixGapStartingPointXList.push(paperMatrixGapStartingPointX)
  })

  // get ordered paper field list: for flow drawing (form into paper_pairs)
  // transform json data to dataframe
  const paperFieldDF: dataForge.DataFrame<number, MatrixTypes2.CellUnit> = new dataForge.DataFrame(paperMatrixData)
  // console.log('  ==> paperMatrixData: ', paperMatrixData)
  let paperFieldOrderedList: MatrixTypes2.PaperFieldUnit[] = paperFieldDF
    .groupBy(item => item.colIdx)
    .select(group => {
      return {
        colName: group.first().colName,
        colIdx: group.first().colIdx,
      }
    }).inflate().toArray()
    paperFieldOrderedList = _.orderBy(paperFieldOrderedList, (paperField) => paperField.colIdx)
    // console.log('    ==> paperFieldOrderedList: ', paperFieldOrderedList)

  return {
    numPaperField: numPaperField,
    numYQuantile: numYQuantile,
    opacityScale: {
      linearScale: opacityScale,
      log10Scale: opacityScaleLog,
      rootHalfScale: opacityScaleRoot,
    },
    xScale: xScale,
    yScale: yScale,
    cellGapWidth: cellGapWidth,
    cellCircleRScale: cellCircleRScaleRoot,
    cellWidthHeight: cellWidthHeight,
    paperMatrixGapStartingPointXList: paperMatrixGapStartingPointXList,
    paperFieldOrderedList: paperFieldOrderedList, // TODO: data reducer: sort paper field order (both paperMatrix & flowList)
  }
}


// // only for historical flow, abandon
// export const getFlowGroupFigureParamHistoricalFlow = (historicalFlowData: MatrixTypes2.FlowUnit[], cellGapWidth: number) => {
//   console.log('getFlowGroupFigureParam, cellGapWidth: ', cellGapWidth)

//   // temp: calc based on historical flow

//   // 0. transform json data to dataframe
//   const flowDataframe: dataForge.DataFrame<number, MatrixTypes2.FlowUnit> = new dataForge.DataFrame(historicalFlowData)

//   // 1. get numPatent & xScale for each paper field
//   const fieldGroupListTemp = flowDataframe.groupBy(item => item.colIdx)
//   const fieldGroupList: MatrixTypes2.PaperFieldFlowGroup[] = fieldGroupListTemp.select(group => {
//     return {
//       colName: group.first().colName,
//       colIdx: group.first().colIdx,
//       numPatent: group.deflate(row => row.numPatent).sum(),
//       cpcSubsectionFlowList: group.toArray(),
//     }
//   }).inflate().toArray() // fieldGroupSummary.toObject, fieldGroupSummary.toString(), fieldGroupSummary.toPairs()
//   const maxNumPatent: number = _.maxBy(fieldGroupList, item => item.numPatent)?.numPatent as number
//   const xScaleFlowWidth: number = cellGapWidth / maxNumPatent

//   return {
//     maxNumPatent: maxNumPatent,
//     xScaleFlowWidth: xScaleFlowWidth,
//   }
// }


// // only for historical patentTreemap, abandon
// export const getPatentTreemapFigureParamHistoricalTreemap = (patentTreemapData: MatrixTypes2.CPCSectionUnit[], xScaleFlowWidth: number) => {
//   console.log('xScaleFlowWidth: ', xScaleFlowWidth)

//   // 1. get sum of patents
//   const numPatentSum: number = _.sumBy(patentTreemapData, (patentCPCSection) => patentCPCSection.numPatent)
//   // 2. get figure params
//   const treemapWidth: number = (svgSize.mainviewPatent.width - 2 * svgSize.mainviewPatent.margin) * matrixViewStyle.fractionPatentTreemapWidth
//   const treemapHeightByLevel: number = (svgSize.mainviewPatent.height - 2 * svgSize.mainviewPatent.margin) / 2
//   const patentTreemapTransformLeft: number = ((svgSize.mainviewMatrix.width - 2 * svgSize.mainviewMatrix.margin) - treemapWidth) / 2
//   const patentTreemapTransformTop: number = svgSize.mainviewPaperMatrix.height + svgSize.mainviewFlow.height
//   // option 1: use independent xScale (hard code)
//   // const xScale: number = treemapWidth / numPatentSum
//   // option 2: use same scale with flow width
//   const xScale: number = xScaleFlowWidth * 3.5

//   // 3. draw treemap
//   const cpcFieldTreemapParam: MatrixTypes.cpcFieldTreemapParam[] = [] // for flow position
//   _.forEach(patentTreemapData, (cpcSection: MatrixTypes.PatentCPCSectionUnit, index: number) => {
//     // 2-1. Level 1: CPC Section
//     const cpcSectionCode: string = cpcSection.cpcSection
//     const numPatent: number = cpcSection.numPatent
//     const rectWidth: number = numPatent * xScale
//     const rectHeight: number = treemapHeightByLevel
//     // get transform left based on previous rects
//     const cumulatedPrevValue: number = _.sumBy(patentTreemapData.slice(0, index), (item) => item.numPatent)
//     const cpcSectionTransformLeft: number = cumulatedPrevValue * xScale

//     // 2-2. Level 2: CPC Subsection
//     _.forEach(cpcSection.cpcSubsectionList, ((cpcSubsection: MatrixTypes.PatentCPCSubsectionUnit, index: number) => {
//       const cpcSubsectionCode: string = cpcSubsection.cpcSubsection
//       const numPatent: number = cpcSubsection.numPatent
//       const rectWidth: number = numPatent * xScale
//       const rectHeight: number = treemapHeightByLevel
//       // get transform left based on previous rects
//       const cumulatedPrevValue: number = _.sumBy(cpcSection.cpcSubsectionList.slice(0, index), (item) => item.numPatent)
//       const transformLeft: number = cumulatedPrevValue * xScale
//       const transformTop: number = treemapHeightByLevel
//       cpcFieldTreemapParam.push({
//         cpcSubsection: cpcSubsectionCode,
//         rectMidPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + transformLeft + rectWidth / 2,
//         rectStartingPointX: patentTreemapTransformLeft + cpcSectionTransformLeft + transformLeft,
//         flowGroupStartingPointX: 0,
//       })
//     })
//   )})

//   return {
//     xScale: xScale,
//     treemapWidth: treemapWidth,
//     treemapHeightByLevel: treemapHeightByLevel,
//     patentTreemapTransformLeft: patentTreemapTransformLeft,
//     patentTreemapTransformTop: patentTreemapTransformTop,
//     cpcFieldTreemapParam: cpcFieldTreemapParam,
//   }
// }