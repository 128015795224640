import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';

import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as d3 from  "d3";
import _ from 'lodash';
import { parse } from 'papaparse';
import { GlobalStyle } from '../../../../../types/style';
import { viewSize, svgSize, themeColor, caseMark } from '../../../../../types/style.global';
import { PaperInspectionTypes } from '../../../../../types/data';


interface LollipopChartProps {
  paperList: PaperInspectionTypes.PaperBarplotUnit[];
  chartWidth: number;
  chartHeight: number;
  //onLollipopClick: (data: string) => void;
}

  const LollipopChart: React.FC<LollipopChartProps> = (props) => {

    const { chartWidth, chartHeight, paperList } = props
    // const { chartWidth, chartHeight, paperList, onLollipopClick } = props


    // console.log('paperList', paperList)

    const svgRef = useRef<SVGSVGElement | null>(null);

    // Set dimensions and margins
    const marginTop = 15
    const margin = { top: 20, right: 20, bottom: 20, left: 52 };
    // const margin = { top: 5, right: 20, bottom: 18, left: 52 };
    const width = chartWidth - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;
  
    useEffect(() => {
      d3.select(svgRef.current).selectAll(".lollipop-chart").remove()
  
      // Create the SVG container
      const svg = d3.select(svgRef.current)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('class', 'lollipop-chart')
        .attr('transform', `translate(${margin.left}, ${margin.top + marginTop})`);
  
      // Set up scales
      const x = d3.scaleLinear()
        .domain([d3.min(paperList, d => d.paper_year_dim_adjusted)! - 1, d3.max(paperList, d => d.paper_year_dim_adjusted)! + 1])
        .range([0, width]);
  
      const y = d3.scaleLinear()
        .domain([0, 100])
        .range([height-marginTop, 0]);
  
      // Add the X Axis
      svg.append('g')
        .attr('transform', `translate(0,${height-marginTop})`)
        .call(d3.axisBottom(x).tickFormat(d3.format('.4')));
  
      // Add the Y Axis
      svg.append('g')
        .call(d3.axisLeft(y));
  
      // Add lollipops
      svg.selectAll('.lollipop-line')
        .data(paperList)
        .enter()
        .append('line')
        .attr('class', 'lollipop-line')
        .attr('x1', d => x(d.paper_year_dim_adjusted))
        .attr('x2', d => x(d.paper_year_dim_adjusted))
        .attr('y1', y(0))
        .attr('y2', d => y(d.predicted_patentability * 100))
        .attr('stroke', '#624D9C');
  
      svg.selectAll('.lollipop-circle')
        .data(paperList)
        .enter()
        .append('circle')
        .attr('class', 'lollipop-circle')
        .attr('cx', d => x(d.paper_year_dim_adjusted))
        .attr('cy', d => y(d.predicted_patentability * 100))
        .attr('r', 6)
        .attr('fill', d => d.is_lead_author ? '#624D9C' : 'white')
        .attr('stroke', '#624D9C')
        .on('mouseover', function(event, d) {
          tooltip
            .style('opacity', 1)
            .html("Paper Title: "+ d.paper_title_dim)
            .style('left', (event.pageX + 20) + 'px')
            .style('top', (event.pageY + 5) + 'px');
        })
        .on('mouseout', function() {
          tooltip.style('opacity', 0);
        })
        //.on('click', (event, d) => onLollipopClick(d.paper_id));


      // Tooltip
      const tooltip = d3.select('body').append('div')
        .attr('class', 'barchart-tooltip')
        // .style('opacity', 0)
        // .style('position', 'absolute')
        // .style('background-color', 'white')
        // .style('border', 'solid')
        // .style('border-width', '1px')
        // .style('border-radius', '5px')
        // .style('padding', '10px')
        // .style('pointer-events', 'none');

      // Add X Axis Label
      svg.append('text')
        .attr('class', 'x-axis-label')
        .attr('text-anchor', 'end')
        // .attr('text-anchor', 'middle')
        .attr('x', width)
        .attr('y', height - 20)
        .text('Publication Year')
        .style('font-size', '14px');

      // Add Y Axis Label
      svg.append('text')
        .attr('class', 'y-axis-label')
        // .attr('text-anchor', 'end')
        .attr('text-anchor', 'middle')
        .attr('transform', `rotate(-90)`)
        // .attr('x', -10)
        .attr('x', -height / 2)
        .attr('y', -margin.left + 20)
        .text('Predicted Score')
        .style('font-size', '14px');


      // // Add legend
      // const svgRaw = d3.select(svgRef.current)
      // const legend = svgRaw.append('g')
      // .attr('class', 'legend')
      // // .attr('transform', `translate(${100}, 15)`);
      // .attr('transform', `translate(${width - 25}, 5)`);

      // legend.append('circle')
      //   .attr('cx', 0)
      //   .attr('cy', 0)
      //   .attr('r', 6)
      //   .attr('fill', '#624D9C')
      //   .attr('stroke', '#624D9C');

      // legend.append('text')
      //   .attr('x', 12)
      //   .attr('y', 0)
      //   .text('Is Leading Author')
      //   .style('font-size', '14px')
      //   .attr('alignment-baseline', 'middle');

      // legend.append('circle')
      //   .attr('cx', 0)
      //   .attr('cy', 0)
      //   .attr('r', 6)
      //   .attr('fill', 'white')
      //   .attr('stroke', '#624D9C');

      // legend.append('text')
      //   .attr('x', 12)
      //   .attr('y', 0)
      //   .text('Not Leading Author')
      //   .style('font-size', '14px')
      //   .attr('alignment-baseline', 'middle');
  
    }, [paperList, chartWidth]);
    // }, [paperList, chartWidth, onLollipopClick]);
  
    return (
      <svg ref={svgRef}>
        {/* <g className='legend-g' transform={`translate(200, 10)`}> */}
        <g className='legend-g' transform={`translate(${width - 100}, 10)`}>
          <g transform='translate(-150, 0)'>
            <circle cx={0} cy={0} r={6} fill='#624D9C' stroke='#624D9C' />
            <text x={12} y={0} alignmentBaseline='middle' style={{ fontSize: '14px' }}>Is Leading Author</text>
          </g>
          <g>
            <circle cx={0} cy={0} r={6} fill='white' stroke='#624D9C' />
            <text x={12} y={0} alignmentBaseline='middle' style={{ fontSize: '14px' }}>Not Leading Author</text>
          </g>
        </g>
      </svg>
    );
  };
  
  export default LollipopChart;