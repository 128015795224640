import React, {
  FunctionComponent as FC,
  useRef,
  useEffect,
  useState,
} from "react";
import { jwtDecode } from 'jwt-decode';
import { useNavigate, Link } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import { Button, Row, Col, Form, Input, message, theme, Checkbox, Layout } from 'antd';
import type { CSSProperties } from 'react';

import backgroundImage from '../../../../../assets/9-login/background.JPG';  // Corrected path to your background image
import logo from '../../../../../assets/9-login/logo-4x-color.png';
import innovationinsightsLogo from '../../../../../assets/9-login/innovationinsights-logo.png';
import cssiLogo from '../../../../../assets/9-login/cssi-logo-white.png';

const { Header, Content, Footer } = Layout;


// Function to check the token validity
export const checkToken = () => {
  const remem = localStorage.getItem("rememberMe");
  const storedLocalToken = localStorage.getItem("token");
  if (remem==="true" && storedLocalToken){
    sessionStorage.setItem("token", storedLocalToken)
  }
  const storedToken = sessionStorage.getItem("token");
  if(!storedToken) {
    return false;
  }
  try {
    const decoded = jwtDecode(storedToken);
    const currentTime = Date.now() / 1000;  // Convert milliseconds to seconds
    // return true
    if (decoded.exp === undefined) {
      return false; 
    }
    return decoded.exp > currentTime;
  } catch (e) {
    return false;  // if token is invalid or expired
  }
};

// let globalToken = sessionStorage.getItem('authToken');

type LoginFields = {
  username: string;
  password: string;
  remember: boolean;
};

interface LoginProps{

}

const Login: FC<LoginProps> = (props) => {
  const {
  } = props

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {
    performLogin,
  } = bindActionCreators(actionCreators, dispatch)

  const tokenStatus: string = useSelector((state: State) => state.uiReducer?.authState.token) as string;
  const authStatus: boolean = useSelector((state: State) => state.uiReducer?.authState.isAuthenticated) as boolean;
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  useEffect(() => {
    if (authStatus && tokenStatus) {
      navigate('/');
      message.success('Login Successful!');
    }
  }, [authStatus, tokenStatus]);

  useEffect(() => {
    // Check local storage for remember me status on component mount
    const remembered = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(remembered);
  }, []);

  // Function to handle form submission
  const onFinish = (values: LoginFields) => {
    performLogin(values)
  };

  // Function to handle submission failure
  const onFinishFailed = (errorInfo: any) => {
    console.log('Login failed:', errorInfo);
    message.error('Submission failed, please check your input.');
  };

  const handleRememberMeChange = (e: any) => {
    setRememberMe(e.target.checked);
  };
  return (
    <Layout>
      <Header style={{ backgroundColor: 'transparent', boxShadow: "none" }}>
        <Row justify="space-between" style={{width: '100%'}}>
          <Col xs={8} sm={8} md={8} lg={8}>
            <div className='header-left-div'>
              <div className='logo'>
                <a href='https://www.kellogg.northwestern.edu/research/science-of-science.aspx'>
                  <img src={cssiLogo} alt='logo' />
                </a>
              </div>
              <div className='logo'><img src={innovationinsightsLogo} alt='logo' /></div>
              {/* <div className='logo'><img src={innovationinsightsText} alt='logo' height={28}/></div> */}
            </div>
          </Col>
        </Row>
      </Header>
      <Content>
        <div className="login-wrapper">
          <div className="branding-section">
            <div className="branding-container">
              <div className="branding-text">InnovationInsights</div>
              <div className="branding-tagline">Find untapped potential of innovation</div>
            </div>
            <div className='branding-copyright'>
                Copyright © 2022-2024 CSSI
            </div>
          </div>
          <div className="content-wrapper">
            <div className="login-form">
              <div className="login-text">Log in</div>
              <Form
                name="login"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: false }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
              >
                <Form.Item
                  className="username-box"
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input 
                  prefix={<UserOutlined 
                  className="site-form-item-icon" />} 
                  placeholder="User Name" 
                  style={{ borderRadius: '16px', border: 'none', boxShadow: 'none', color: '#B6ACD1' }}/>
                </Form.Item>
                <Form.Item
                  className="password-box"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    style={{ borderRadius: '16px', border: 'none', boxShadow: 'none', color: '#B6ACD1' }}
                  />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked" className="remember-checkbox" >
                  <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
                    Remember me
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
                <div className="login-form-footer">
                  {/*<Link to="/register" className="link">Register</Link>*/}
                  {/*<Link to="/delete-account" className="link">Delete Account</Link>*/}
                </div>
              </Form>


            </div>
          </div>
        </div>
      </Content>
      {/* <Footer >
      <div className='footer-div'>
        Copyright © 2022-2024 CSSI
      </div>
      </Footer> */}
    </Layout>
  );
};

export default Login;

