import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';

import { Avatar } from 'antd';
import { UserOutlined, HomeOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import * as d3 from  "d3";
import _ from 'lodash';
import { parse } from 'papaparse';
import { GlobalStyle } from '../../../../../types/style.d';
import { viewSize, svgSize, themeColor, caseMark } from '../../../../../types/style.global';
import { ResearcherStatisticsTypes } from '../../../../../types/data';


interface ResearcherCard {
  viewParam: GlobalStyle.ViewSize;
  ResearcherStatisticsData: ResearcherStatisticsTypes.ResearcherStatisticsUnit;
  boxShadowStyle: string;
  divOutlineColor: string;
}

const ResearcherCard: FC<ResearcherCard> = (props) => {
  const { viewParam, ResearcherStatisticsData, boxShadowStyle, divOutlineColor } = props
  const imgSrc = `../../../../../assets/11-vu-researchers-20240816/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`;
  const [showImage, setShowImage] = useState<boolean>(true);

  // setImgSrc(`../../../../../assets/11-vu-researchers-20240816/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`)

  const handleImgError = (event:any) => {
    event.preventDefault();
    setShowImage(false)
  };
  
  let nameColor:string;
  nameColor = ResearcherStatisticsData.demographicInfo.gender === "Male" ? themeColor.default.maleColor : themeColor.default.femaleColor
  const genderLetter = ResearcherStatisticsData.demographicInfo.gender === "Male" ? "M" : "F"
  // get avatar image
  let avatarSrc = null;
  // try {
  //   // 使用require引入图片
  //   avatarSrc = require(`../../../../../public/avatar/${ResearcherStatisticsData.empidHash}.png`);
  //   // avatarSrc = require("../../../../../public/avatar/Frame 1.png");
  // } catch (e) {
  //   // 如果文件不存在，则使用默认值
  //   avatarSrc = null;
  // }

  //const departmentName = ResearcherStatisticsData.demographicInfo['departmentName'].split(' - ')[1] for Vanderbilt
  const departmentName = ResearcherStatisticsData.demographicInfo['departmentName'] //for NU
  const tenureStatus = ResearcherStatisticsData.demographicInfo['tenureStatus']
    return (
      <div className='researcherCardDiv'
          style={{
            height: viewParam.height, 
            width: viewParam.width, 
            margin: viewParam.margin,
            outlineColor: divOutlineColor}}
          // onClick={()=>handleOnClickResearcherCard()}
        >
          <div className="card-left-div">
            <div className="researcher-details">
              <div className='cardName' > 
                {ResearcherStatisticsData.demographicInfo['fullName']}
              </div>
              <div className='cardDemographic' >
                <div className='cardGender'>
                  <div className='card-gender-letter'>
                    {genderLetter}
                  </div>
                </div>
                <div className='cardTenure'>
                    {tenureStatus}
                </div>
                {/* <div className='cardSchool'>
                  <div className="combinedSchoolName">
                    <span className="schoolName">{ResearcherStatisticsData.demographicInfo['schoolName']}</span>
                    <span className="departName">{' > ' + departmentName}</span>
                  </div>
                </div> */}
                {/* <div className='cardSchool'>
                  {ResearcherStatisticsData.demographicInfo['schoolName'] + ' > ' + departmentName}
                </div> */}
              </div>
            </div>
            
            <div className='scisci-index-div-group'>
              <div className='cardCitation scisci-index'>
                <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                  <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
                </svg>
                <div className='index-name'>papers cited by patents (2001-2023)</div>
                <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numPapersSince2001CitedByCompanyPatents']}</div>
              </div>
              <div className='cardLeadAuthorPapers scisci-index'>
                <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                  <path d="M0.86486 3.96788C0.850144 3.86983 0.840341 3.78321 0.832175 3.71294C0.823999 3.64267 0.822361 3.57239 0.822361 3.50049C0.822361 3.27496 0.855046 3.1246 0.917151 3.05433C0.980894 2.98406 1.06914 2.94811 1.18354 2.94811H3.13973L3.77872 0.501627C4.17585 0.52942 4.4406 0.583339 4.57625 0.661787C4.71189 0.740235 4.77889 0.835015 4.77889 0.947785V1.0965L4.26737 2.94647H6.62887L7.2891 0.5C7.68623 0.527783 7.95097 0.581712 8.08662 0.66015C8.22226 0.738598 8.28927 0.833388 8.28927 0.946147C8.28927 1.00335 8.28109 1.05238 8.26802 1.09487L7.77775 2.94647H9.45775C9.48554 3.03146 9.50025 3.11317 9.50025 3.19161V3.4155C9.50025 3.62795 9.46757 3.77341 9.40384 3.85185C9.34009 3.93029 9.25185 3.96788 9.13744 3.96788H7.50155L6.7351 6.79677H8.71254C8.72562 6.89646 8.73705 6.98471 8.74523 7.06316C8.75294 7.13754 8.75676 7.21227 8.75666 7.28705C8.75666 7.65639 8.63572 7.83943 8.3955 7.83943H6.45728L5.73493 10.4984C5.33781 10.4706 5.07471 10.4166 4.94723 10.3398C4.81976 10.2614 4.75603 10.1666 4.75603 10.0522C4.75603 9.99664 4.76255 9.95252 4.77727 9.92473L5.32964 7.84106H2.94853L2.24744 10.5C1.85032 10.4722 1.58066 10.4183 1.43848 10.3415C1.2963 10.263 1.22603 10.1682 1.22603 10.0538C1.22603 10.0261 1.22929 10.0048 1.23583 9.99011C1.24237 9.97703 1.24564 9.95415 1.24564 9.92637L1.8209 7.84269H0.119637C0.0918537 7.74301 0.0771484 7.65475 0.0771484 7.57631V7.35242C0.0771484 7.13997 0.109833 6.99451 0.173566 6.91608C0.237308 6.83763 0.325549 6.80004 0.439956 6.80004H2.09872L2.8423 3.97114H0.86486V3.96788ZM3.22472 6.79677H5.60582L6.37229 3.96788H3.99119L3.22472 6.79677Z" fill="#9380B6"/>
                </svg>
                <div className='index-name'>recent high-score lead-author papers (2019-2023)</div>
                <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numHighScoreLeadRecentPapers']}</div>
              </div>
              <div className='cardInventions scisci-index'>
                <svg className='pound-sign'xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                  <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
                </svg>
                <div className='index-name'>invention disclosures (2001-2023)</div>
                <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numInventions_2001_2023']}</div>
              </div>
            </div>

            <div className='cardDetail'>
              <div className='cardDetailText'>
                <div className='button'>Details</div>
              </div>
              <div className='cardDetailLine'></div>
            </div>
          
          </div>

          <div className="card-right-div">
            <div className="researcher-img-container">
              <div className = "img-ellipse">
                <UserOutlined />
                {/* {showImage ? (
                  <img src={`${process.env.PUBLIC_URL}/researcherPhoto/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`} onError={handleImgError} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" />
                  // <img src={require(`../../../../../assets/11-vu-researchers-20240816/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`)} onError={handleImgError} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" />
                ) : (
                  <UserOutlined className='img-icon'/>
                )}
                {/* <UserOutlined className='img-icon'/> */}
                {/* <img src={`${process.env.PUBLIC_URL}/assets/11-vu-researchers-20240816/${ResearcherStatisticsData.demographicInfo['fullName']}.jpg`} onError={handleImgError} alt={ResearcherStatisticsData.demographicInfo['fullName']} className="researcher-photo" /> */}
              </div>
            </div>
            <div className="researcher-actions">
              {/* <div className="card-icon">
                <HomeOutlined className="action-icon" href={ResearcherStatisticsData.demographicInfo['weblink']} target="_blank" onClick={(event) => event.stopPropagation()}/>
              </div>
              <div className="card-icon">
                <PhoneOutlined className="action-icon" />
              </div> */}
              {/* <div className="card-icon">
                <a href={`mailto:${ResearcherStatisticsData.demographicInfo.weblink}`}><MailOutlined className="action-icon" /></a>
              </div> */}
            </div>
          </div>

          {/* <div className="researcher-img">
              <svg className = "img-ellipse" xmlns="http://www.w3.org/2000/svg" width="165" height="197" viewBox="0 0 165 197" fill="none">
                <circle cx="111.56" cy="85.1155" r="110.5" stroke="#B6ACD1"/>
              </svg>
              <div className="img-container"></div>

          </div> */}

      </div>

    )
    // return (
    //   <div className='researcherCardDiv'
    //       style={{
    //         height: viewParam.height, 
    //         width: viewParam.width, 
    //         margin: viewParam.margin,
    //         outlineColor: divOutlineColor}}
    //       // onClick={()=>handleOnClickResearcherCard()}
    //     >
    //       <div className='top-div'>
    //         <div className='card-left-div'>
    //           {/* <div className='pIndexBadge'>
    //             <strong>{"P-Index"}</strong>
    //             <strong>{ResearcherStatisticsData.scisciIndex.pIndex.toFixed(3) * 100}</strong> 
    //             <strong>{ResearcherStatisticsData.scisciIndex.pIndex ? (Number(ResearcherStatisticsData.scisciIndex.pIndex.toFixed(3)) * 100).toFixed(1) : "0.00"}</strong>
    //           </div> */}
    //           <div className='cardAvatar'>
    //               <Avatar size={viewParam.height/2} shape="square" icon={<UserOutlined />} src={avatarSrc} style={{
    //                 backgroundColor: '#DBDBDB',
    //                 borderRadius: 16, 
    //                 borderWidth: 5, 
    //                 // borderColor: '#BBB2D9', 
    //                 borderStyle:'solid'
    //                 }}></Avatar>
    //               {/* <Avatar size={90} src={avatarSrc}>{ResearcherStatisticsData.demographicInfo['fullName']}</Avatar> */}
    //               <div className='cardJob'>
    //                   {ResearcherStatisticsData.demographicInfo['jobTitle']}
    //               </div>
    //           </div>
    //         </div>
    //         <div className='card-right-div'>
    //           <div className='cardText'>
    //               <div className='cardName' > 
    //               {/* style={{color: nameColor}} */}
    //                   {/* <a href={ResearcherStatisticsData.demographicInfo['weblink']} target="_blank" onClick={(event) => event.stopPropagation()}>
    //                     <u style={{color: nameColor}}>{ResearcherStatisticsData.demographicInfo['fullName']}</u>
    //                   </a> */}
    //                   {ResearcherStatisticsData.demographicInfo['fullName']}
    //               </div>
    //               {/* <div WQclassName='cardJob'>
    //                   {ResearcherStatisticsData.demographicInfo['jobTitle']}
    //               </div> */}
    //               <div className='cardSchool'>
    //                   {ResearcherStatisticsData.demographicInfo['schoolName'] + ' > ' + departmentName}
    //               </div>
    //               {/* <div className='cardSchool'>
    //                   School: {ResearcherStatisticsData.demographicInfo['schoolName']}
    //               </div>
    //               <div className='cardDepartment'>
    //                   Department: {ResearcherStatisticsData.demographicInfo['departmentName']}
    //               </div> */}
    //               <div className='scisci-index-div-group'>
    //                 <div className='cardCitation scisci-index'>
    //                   <div className='index-name'># papers cited by patents</div>
    //                   <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numPapersSince2001CitedByCompanyPatents']}</div>
    //                 </div>
    //                 <div className='cardInventions scisci-index'>
    //                   <div className='index-name'># inventions</div>
    //                   <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numInventions_2001_2023']}</div>
    //                 </div>
    //                 <div className='cardLeadAuthorPapers scisci-index'>
    //                   <div className='index-name'># high-score lead-author papers</div>
    //                   <div className='index-value'>{ResearcherStatisticsData.scisciIndex['numHighScoreLeadRecentPapers']}</div>
    //                 </div>
    //               </div>
    //               {/* <div className='cardPatents'>
    //                   # patents: {ResearcherStatisticsData.scisciIndex['numPatents']}
    //               </div> */}
    //               {/* <div className='cardPIndex'>
    //               P-index: {ResearcherStatisticsData.scisciIndex['pIndex']===-2?"--":ResearcherStatisticsData.scisciIndex['pIndex'].toFixed(3)}
    //               </div> */}
    //               {/* <li>numInventions: {ResearcherStatisticsData.scisciIndex.numInventions}</li>
    //               <li>numPapers: {ResearcherStatisticsData.scisciIndex.numPapers}</li>
    //               <li>numPatentCitations: {ResearcherStatisticsData.scisciIndex.numPatentCitations}</li> */}
    //           </div>
    //         </div>
    //       </div>
    //       <div className='buttom-div'>
    //         <div className='cardDetail'>
    //             <div className='cardDetailText'>
    //               <div className='button'>Details</div>
    //             </div>
    //             <div className='cardDetailLine'></div>
    //             {/* <div className='cardDetailIcon'></div> */}
    //             <div className='cardDetailIconList'>
    //                 <a href={ResearcherStatisticsData.demographicInfo['weblink']} target="_blank" onClick={(event) => event.stopPropagation()}>
    //                   <div className='cardDetailIcon1'></div>
    //                 </a>
    //               <div className='cardDetailIcon2'></div>
    //               <div className='cardDetailIcon3'></div>
    //             </div>
    //         </div>
    //       </div>
    //     </div>
    // )
}


export default ResearcherCard;