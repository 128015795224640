import React, { FunctionComponent as FC } from 'react';
import './index.scss';

import Login from './LoginForm';

import { GlobalStyle } from '../../../../types/style';



interface LoginPageProps {

}

const LoginPage: FC<LoginPageProps> = (props) => {

  const {
  } = props

  return (
    <div className='login-page-div'>
      <Login />
    </div>

  )
}

export default LoginPage