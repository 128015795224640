import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid as Grid } from 'react-window';
import { Select, Spin, Button, Tooltip, Empty, Pagination, Breadcrumb } from 'antd';
import _ from 'lodash';

import { GlobalStyle } from '../../../../../types/style.d';
import PaperCard from '../../../common/Visualization/PaperCard';
import { PaperInspectionTypes } from '../../../../../types/data';
import PaperDetails from '../PaperDetails';


interface PaperViewProps {
  //selectedPaper passed from Idea Finder
  selectedPaperFromIF: PaperInspectionTypes.PaperInspectionUnit | null; 
}

const PaperView: FC<PaperViewProps> = (props) => {
  //const location = useLocation();
  const navigate = useNavigate();

  const {selectedPaperFromIF} = props

  // store
  const dispatch = useDispatch()
  const {
    // TODO: 添加获取researcher card list data的action
    // getPaperInspectionData,
    // getPatentInspectionData,
    setideaFinderSelectedPaper,
    setSelectedPaperID,
    getTopKPaperCardListData
  } = bindActionCreators(actionCreators, dispatch)

  const paperRankByField: string = useSelector((state: State) => state.dataReducer?.paperRankByField) as string
  const paperRankByOrder: string = useSelector((state: State) => state.dataReducer?.paperRankOrder) as string
  const searchPaperKeyword: string = useSelector((state: State) => state.dataReducer?.searchPaperKeyword) as string
  const selectedPaperIdList: string[] = useSelector((state: State) => state.dataReducer?.selectedPaperIdList) as string[]
  const selectedPaperId: string = useSelector((state: State) => state.dataReducer?.selectedPaperID) as string

  const paperCardListData: PaperInspectionTypes.PaperInspectionUnit[] = useSelector((state: State) => state.dataReducer?.paperCardListData) as PaperInspectionTypes.PaperInspectionUnit[]
  const paperCardSize: number = useSelector((state: State) => state.dataReducer?.paperCardSize) as number

  const [isPaperViewLoading, setIsPaperViewLoading] = useState(false);
  const [sortedPaperListData, setSortedPaperListData] = useState<PaperInspectionTypes.PaperInspectionUnit[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [cardListSize, setCardListSize] = useState(paperCardListData.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPaper, setSelectedPaper] = useState<PaperInspectionTypes.PaperInspectionUnit | null>(null);

  const pageSetByRankingChange = useRef(false);


  // console.log("location: ", location)
  // //CASE 1: user clicks paper card in RD
  // // used for receiving selected paper card data from RD
  // useEffect(() => {
  //   if (location.state && location.state.selectedPaper) {
  //     setSelectedPaper(location.state.selectedPaper); //allows rendering of PD page within PV
  //     setSelectedPaperID(location.state.selectedPaper.paper_mag_id) //allows PD to get patents list
  //   }
  //   console.log("location.state: ", location)
  // }, [location.state]);

  // //CASE 2: user clicks Idea Finder
  // // console.log('paperCardListData:', paperCardListData);
  // // selectedPaperId mainly used in PD, but used here to receive sign for below case 
  // // case: user clicks paper card in RD and comes to PV/PD -> user clicks Idea Finder on header 
  // // then Idea Finder sets selectedPaperId to "" since we want to return to all papers
  // useEffect(() => {
  //   if (selectedPaperId==""){
  //     setSelectedPaper(null);
  //   }
  // }, [selectedPaperId])

  useEffect(() => {
    //CASE 1: user clicks paper card in RD
    if (selectedPaperFromIF){
      console.log("paper card selected from RD")
      setSelectedPaper(selectedPaperFromIF); //allows rendering of PD page within PV
      setSelectedPaperID(selectedPaperFromIF.paper_mag_id) //allows PD to get patents list
      setideaFinderSelectedPaper(null); //reset
    } 
    //CASE 2: user clicks Idea Finder
    else {
      console.log("directly from IF")
      setSelectedPaper(null);
      setSelectedPaperID("")
    }
    
  }, [selectedPaperFromIF])

  useEffect(() => {
      if(!_.isEmpty(selectedPaperIdList)){
        console.log("selectedPaperIdList: ", selectedPaperIdList)
        // console.log("==> in Paper View getPaperCardListData")
        // setIsPaperViewLoading(true)
        // TODO: 待补充
        // 监测到selectedResearcherIdList的变化后，调用后端api获得ResearcherCardListData
        const updatePaperCardListData = async () => {
          try {
            setIsPaperViewLoading(true);
            console.log("pageSetByRankingChange set to TRUE")
            pageSetByRankingChange.current = true; 
            setCurrentPage(1);
            //TO DO: await getTopKPaperCardListData(params: selectedPaperIdList, rankMetric, rankOrder)
            var params = {
              paperIdList: selectedPaperIdList,
              rankingMetric: paperRankByField,
              rankingOrder: paperRankByOrder,
              pageNumber: 1,
            }
            await getTopKPaperCardListData(params)
            // await getPaperCardListData(selectedPaperIdList)
            // console.log('selectedPaperIdList:', selectedPaperIdList)             
          } catch (error) {
            console.error(error);
            setIsPaperViewLoading(false)      
          } finally {
            console.log('Paper View update finished');
            setIsPaperViewLoading(false) 
          }
        }
        updatePaperCardListData();
      }
    // fetchData();
  }, [selectedPaperIdList, paperRankByField, paperRankByOrder])

  useEffect(() => {
    console.log("currentPage: ", currentPage)
    console.log("pageSetByRankingChange.current: ", pageSetByRankingChange.current)
    if(currentPage >= 1 && !pageSetByRankingChange.current){
      console.log("Page changed to: ", currentPage)
      // console.log("==> in Paper View getPaperCardListData")
      // setIsPaperViewLoading(true)
      // TODO: 待补充
      // 监测到selectedResearcherIdList的变化后，调用后端api获得ResearcherCardListData
      const updatePaperCardListData = async () => {
        try {
          setIsPaperViewLoading(true)
          //TO DO: await getTopKPaperCardListData(params: selectedPaperIdList, rankMetric, rankOrder)
          var params = {
            paperIdList: selectedPaperIdList,
            rankingMetric: paperRankByField,
            rankingOrder: paperRankByOrder,
            pageNumber: currentPage,
          }
          
          await getTopKPaperCardListData(params)
          // await getPaperCardListData(selectedPaperIdList)
          // console.log('selectedPaperIdList:', selectedPaperIdList)             
        } catch (error) {
          console.error(error);
          setIsPaperViewLoading(false)      
        } finally {
          console.log('Paper View update finished for changed page number');
          setIsPaperViewLoading(false) 
        }
      }
      updatePaperCardListData();
    } else {
      pageSetByRankingChange.current = false; // Reset after avoiding the call
    }
  // fetchData();
}, [currentPage])

  useEffect(() => {
    let sortedPaperList: PaperInspectionTypes.PaperInspectionUnit[] = paperCardListData.slice()
    console.log('sortedPaperList:', sortedPaperList);
    setSortedPaperListData(sortedPaperList)
    setCardListSize(paperCardSize)
  }, [paperCardListData, paperCardSize])

  useEffect(() => {
    let sortedPaperList: PaperInspectionTypes.PaperInspectionUnit[] = paperCardListData.slice()
    if (searchPaperKeyword == ""){
      setIsEmpty(false);
      setSortedPaperListData(sortedPaperList);
    }
    // // 过滤关键词
    if (searchPaperKeyword) {
      const keyword = searchPaperKeyword.toLowerCase();
      sortedPaperList = sortedPaperList.filter(paper =>
        paper.demographicInfo.paperTitle.toLowerCase().includes(keyword)
      );
      if (_.isEmpty(sortedPaperList)){
        setIsEmpty(true);
      } else{
        setIsEmpty(false);
        setSortedPaperListData(sortedPaperList);
      }
    } else{
        setIsEmpty(false);
    }
  // fetchData();
  }, [searchPaperKeyword])
  
  // 监测paperRankByField, paperRankByOrder的改变进行重新排序
  // useEffect(() => {
  //   let sortedPaperList: PaperInspectionTypes.PaperInspectionUnit[] = paperCardListData.slice()
    
  //   // // 过滤关键词
  //   if (searchPaperKeyword) {
  //     const keyword = searchPaperKeyword.toLowerCase();
  //     sortedPaperList = sortedPaperList.filter(paper =>
  //       paper.demographicInfo.paperTitle.toLowerCase().includes(keyword)
  //     );
  //   }

  //   // 排序
  //   if(paperRankByField === 'predictionScore'){
  //     sortedPaperList = sortedPaperList.sort(function(a, b){
  //       if(paperRankByOrder === 'ascending'){
  //         return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
  //       }
  //       else{
  //         // descending
  //         return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
  //       }
  //     })
  //   }
  //   else if(paperRankByField === 'patentCitationsCall'){
  //     sortedPaperList = sortedPaperList.sort(function(a, b){
  //       if(paperRankByOrder === 'ascending'){
  //         return (b.scisciIndex.patentCitationsCall < a.scisciIndex.patentCitationsCall) ? 1 : (b.scisciIndex.patentCitationsCall > a.scisciIndex.patentCitationsCall)? -1 : 0;
  //       }
  //       else{
  //         // descending
  //         return (b.scisciIndex.patentCitationsCall > a.scisciIndex.patentCitationsCall) ? 1 : (b.scisciIndex.patentCitationsCall < a.scisciIndex.patentCitationsCall)? -1 : 0;
  //       }
  //     })
  //   }
  //   // else if(paperRankByField === 'num of citation'){
  //   //   sortedPaperList = sortedPaperList.sort(function(a, b){
  //   //     if(paperRankByOrder === 'ascending'){
  //   //       return (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall)? -1 : 0;
  //   //     }
  //   //     else{
  //   //       // descending
  //   //       return (b.scisciIndex.scienceCitationsCall > a.scisciIndex.scienceCitationsCall) ? 1 : (b.scisciIndex.scienceCitationsCall < a.scisciIndex.scienceCitationsCall)? -1 : 0;
  //   //     }
  //   //   })
  //   // }
  //   else {
  //     //paperRankByField === 'prediction score'
  //     sortedPaperList = sortedPaperList.sort(function(a, b){
  //       if(paperRankByOrder === 'ascending'){
  //         return (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore)? -1 : 0;
  //       }
  //       else{
  //         // descending
  //         return (b.scisciIndex.predictionScore > a.scisciIndex.predictionScore) ? 1 : (b.scisciIndex.predictionScore < a.scisciIndex.predictionScore)? -1 : 0;
  //       }
  //     })
  //   }
  //   setSortedPaperListData(sortedPaperList)
  // }, [paperRankByField, paperRankByOrder, searchPaperKeyword, paperCardListData])


  // console.log('sortedPaperListData:', sortedPaperListData);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
    pageSetByRankingChange.current = false;
  }

  const handleCardClick = (paper: PaperInspectionTypes.PaperInspectionUnit) => {
    console.log('click Paper: ', paper);
    setSelectedPaper(paper);
    setSelectedPaperID(paper.paper_mag_id);
  };
  // const handleBack = () => {
  //   //from RD
  //   if (selectedPaperFromIF) {
  //     // navigate back to researcher details
  //     navigate('/people-finder', { state: { showResearcherDetails: true } });
  //   } else {
  //     // Default back behavior (could be going back to IdeaFinder)
  //     navigate(-1);
  //   }
  // };

  const handleBreadcrumbClick = (event: React.MouseEvent) => {
    console.log("handleBreadcrumbClick" );
    event.preventDefault();
    setSelectedPaper(null);
    setSelectedPaperID("");
  };

  const breadcrumbItems = [
    { title: 'Papers' },
    { title: <a href="">All</a>, onClick: handleBreadcrumbClick },
    ...(selectedPaper ? [{ title: <a href="">{selectedPaper.demographicInfo.paperTitle}</a> }] : [])
  ];

  const cardWidth = 440; // 固定卡片宽度
  const cardHeight = 193; // 固定卡片高度
  const gap = 20; // 卡片之间的间隔



  // set paper card width
  const paperCardParentDivRef = React.useRef<HTMLDivElement>(null);
  const [paperCardParentDivWidth, setPaperCardParentDivWidth] = React.useState(0);
  React.useEffect(() => {
    if (paperCardParentDivRef.current) {
      setPaperCardParentDivWidth(paperCardParentDivRef.current.offsetWidth);
    }
  }, []);
  const paperCardMargin = 16
  const numPaperCardsPerRowDict: { [windowWidth: number]: number} = {
    800: 1, 
    1080: 2, 
    1240: 3, 
    1920: 4, 
    2560: 5, 
  }
  let numPaperCardsPerRow = 1; // Default value
  for (const width in numPaperCardsPerRowDict) {
    if (paperCardParentDivWidth >= parseInt(width)) {
      numPaperCardsPerRow = numPaperCardsPerRowDict[width];
    }
  }
  console.log("numPaperCardsPerRow")
  console.log(numPaperCardsPerRow)
  const paperCardWidth = (paperCardParentDivWidth - numPaperCardsPerRow * (paperCardMargin + 8) * 2) / numPaperCardsPerRow;

  console.log('sortedPaperListData:', sortedPaperListData);
  const paperCardList: JSX.Element[] = sortedPaperListData.map((paperCard, index) => {
    // const numPaperCardsPerRow = Math.floor(paperCardParentDivWidth / (paperCardWidth_min + 2 * paperCardMargin));
    return (
      <div onClick={() => handleCardClick(sortedPaperListData[index])}>
        <PaperCard
          key={sortedPaperListData[index].paper_mag_id}
          viewName='Idea-Finder' //used to indicate source page when clicking on author
          viewParam={{ width: paperCardWidth, height: cardHeight, margin: paperCardMargin, padding: 4 }}
          PaperInspectionData={sortedPaperListData[index]}
        />
      </div>
    )
  })

  return (
    <div className='researcher-view-div'>
      <Spin tip="Loading" spinning={isPaperViewLoading} size='large' className='paper-view-custom-spin'>
      <div className='back-and-breadcrumb'>
        {/* <button onClick={handleBack}>Back</button> */}
        <Breadcrumb className="breadcrumb" items={breadcrumbItems} />
      </div>
      
      {isEmpty ? (
          <div className='is-empty'>
            <Empty description='No Results' />
          </div>
      ) : (
          <div ref={paperCardParentDivRef} className='idea-finder-paper-card-group'>
            { (selectedPaper) && <PaperDetails paper={selectedPaper} /> }
            { (!selectedPaper) && paperCardList }
          </div>
      )}
      {/* <div style={{ width: '100%', height: '90vh' }}>
        <AutoSizer>
           {({ height, width }: { height: number; width: number })  => {
            const columnCount = Math.floor(width / (cardWidth + gap));
            const totalCardWidth = columnCount *  (cardWidth + gap);
            const remainingSpace = width - totalCardWidth;
            const adjustedGap = remainingSpace / (columnCount + 1);

            // console.log('columnCount:', columnCount);
            return (
              <Grid
                columnCount={columnCount}
                columnWidth={cardWidth + gap}
                height={height}
                rowCount={Math.ceil(sortedPaperListData.length / columnCount)}
                rowHeight={cardHeight + gap}
                width={width}
              >
                {({ columnIndex, rowIndex, style }) => {
                  const index = rowIndex * columnCount + columnIndex;
                  if (index >= sortedPaperListData.length) return null;
                  return (
                    <div
                      style={{
                        ...style,
                        left: (style.left as number) + (columnIndex+1) * adjustedGap,
                        top: (style.top as number) + gap / 2,
                      }}
                    >
                      <PaperCard
                        key={sortedPaperListData[index].paper_mag_id}
                        viewName='Idea-Finder'
                        viewParam={{ width: cardWidth, height: cardHeight, margin: 20, padding: 4 }}
                        PaperInspectionData={sortedPaperListData[index]}
                      />
                    </div>
                  );
                }}
              </Grid>
            );
          }}
        </AutoSizer>
      </div> */}
      </Spin>
      {!selectedPaper && (
      <div className='paper-view-pagination'>
        <Pagination current={currentPage} defaultCurrent={1} total={cardListSize} pageSize={100} showSizeChanger={false} onChange={onPageChange}/>
      </div>
      )}
    </div>
  );
}

export default PaperView