import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';

import { Avatar, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as d3 from  "d3";
import _ from 'lodash';
import { parse } from 'papaparse';
import { GlobalStyle } from '../../../../../types/style';
import { viewSize, svgSize, themeColor, caseMark } from '../../../../../types/style.global';
import { PaperInspectionTypes, } from '../../../../../types/data';
//import ResearcherDetails from '../../../ThreePages/PeopleFinderPage/ResearcherDetails';


interface PaperInspectionCard {
  viewName: string;
  viewParam: GlobalStyle.ViewSize;
  PaperInspectionData: PaperInspectionTypes.PaperInspectionUnit;
  onClick?: () => void;
}

const PaperCard: FC<PaperInspectionCard> = (props) => {
  const { viewName, viewParam, PaperInspectionData, onClick } = props

  const dispatch = useDispatch()
  const {
    setSelectedAuthorID,
    //setSelectedResearcherID,
  } = bindActionCreators(actionCreators, dispatch)

  const content = (
    <div>
      <p>{PaperInspectionData.paper_abstract}</p>
    </div>
  );
  const authors = PaperInspectionData.demographicInfo['authorsName'].split(', ');
  const authorsHash = PaperInspectionData.demographicInfo['authorsHash'].split(', ');

  const handleAuthorClick = (authorhash:string) => {
    console.log("Author clicked:", authorhash);
    setSelectedAuthorID(authorhash);
    //setSelectedResearcherID(authorhash);
    // navigate('/people-finder', {state: {viewName: viewName}});
    navigate(`/people-finder?viewName=${encodeURIComponent(viewName)}`);
  };


  const navigate = useNavigate();

  return (
    // <Popover content={content} title="Abstract" placement="bottomLeft" overlayClassName="custom-popover" overlayStyle={{ width: viewParam.width,  margin:"20px auto" }}>
      <div className='paperCardDiv'
        style={{
          height: viewParam.height, 
          //width: viewParam.width, 
          width: "476px", 
          margin:"20px 20px"}}
          onClick={onClick}
      >
        <div className='card-left-div'>
          <div className='card-metadata'>
            <div className='card-year'>
              <div className='card-year-text'>
                {PaperInspectionData.demographicInfo['paperYear']}
              </div>
            </div>
            <div className='card-mag'>
                <span className="card-mag-attr">Journal</span>
                <span className="card-mag-name">{' > ' + PaperInspectionData.demographicInfo['paperMagName']}</span>
            </div>
          </div>

          <div className='card-title'>
            <div className='card-title-text'> {PaperInspectionData.demographicInfo['paperTitle']} </div>
            <div className='card-title-tooltip'>
              <div className='card-title-tooltip-text'>
                {PaperInspectionData.demographicInfo['paperTitle']}
              </div>
            </div>
          </div>

          {/* <div className='card-author'>
              {authors.map((author, index) => (
                <span key={index} onClick={() => handleAuthorClick(authorsHash[index])}>
                  {author}
                  {index < authors.length - 1 ? ', ' : ''}
                </span>
              ))}
          </div> */}

          <div className='card-citation'>
            <svg className='pound-sign' xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
              <path d="M0.787711 3.96788C0.772996 3.86983 0.763193 3.78321 0.755026 3.71294C0.74685 3.64267 0.745213 3.57239 0.745213 3.50049C0.745213 3.27496 0.777898 3.1246 0.840003 3.05433C0.903745 2.98406 0.991987 2.94811 1.10639 2.94811H3.06258L3.70158 0.501627C4.09871 0.52942 4.36345 0.583339 4.4991 0.661787C4.63474 0.740235 4.70174 0.835015 4.70174 0.947785V1.0965L4.19022 2.94647H6.55172L7.21195 0.5C7.60908 0.527783 7.87382 0.581712 8.00947 0.66015C8.14511 0.738598 8.21212 0.833388 8.21212 0.946147C8.21212 1.00335 8.20394 1.05238 8.19087 1.09487L7.7006 2.94647H9.38061C9.40839 3.03146 9.4231 3.11317 9.4231 3.19161V3.4155C9.4231 3.62795 9.39042 3.77341 9.32669 3.85185C9.26294 3.93029 9.1747 3.96788 9.0603 3.96788H7.42441L6.65795 6.79677H8.63539C8.64847 6.89646 8.6599 6.98471 8.66808 7.06316C8.67579 7.13754 8.67961 7.21227 8.67951 7.28705C8.67951 7.65639 8.55857 7.83943 8.31835 7.83943H6.38013L5.65779 10.4984C5.26067 10.4706 4.99756 10.4166 4.87008 10.3398C4.74261 10.2614 4.67888 10.1666 4.67888 10.0522C4.67888 9.99664 4.68541 9.95252 4.70012 9.92473L5.25249 7.84106H2.87139L2.17029 10.5C1.77317 10.4722 1.50351 10.4183 1.36133 10.3415C1.21915 10.263 1.14888 10.1682 1.14888 10.0538C1.14888 10.0261 1.15215 10.0048 1.15869 9.99011C1.16522 9.97703 1.16849 9.95415 1.16849 9.92637L1.74375 7.84269H0.0424883C0.0147052 7.74301 0 7.65475 0 7.57631V7.35242C0 7.13997 0.0326849 6.99451 0.0964174 6.91608C0.16016 6.83763 0.248401 6.80004 0.362808 6.80004H2.02157L2.76516 3.97114H0.787711V3.96788ZM3.14757 6.79677H5.52867L6.29514 3.96788H3.91404L3.14757 6.79677Z" fill="#9380B6"/>
            </svg>
            <div className='index-name'>Company Patent Citations</div>
            <div className='index-value'>{PaperInspectionData.scisciIndex['numCompanyPatentCitations']}</div>
            {/* <div className='index-value'>{PaperInspectionData.scisciIndex['patentCitationsCall']}</div> */}
          </div>

          <div className='card-doi'>
            <Popover content={content} title="Abstract" placement="bottomLeft" overlayClassName="custom-popover" overlayStyle={{ width: viewParam.width,  margin:"20px auto" }}>
              <span className="card-doi-attr" style={{ marginRight: 12, textDecoration: 'underline' }}>Abstract</span> 
            </Popover>
            
            <span className="card-doi-attr">Doi Link</span>
            <span className="card-doi-arrow">{'>'}</span>
            <span className="card-doi-text"><a href={`https://doi.org/${PaperInspectionData.demographicInfo['hyperlink']}`} target="_blank">{PaperInspectionData.demographicInfo['paperDoi']}</a></span>            
          </div>
        </div>

        <div className='card-right-div'>
          <div className='card-score-container'>
            <div className='card-score-badge'>
              <strong>{PaperInspectionData.scisciIndex.predictionScore ? (Number(PaperInspectionData.scisciIndex.predictionScore.toFixed(3)) * 100).toFixed(1) : "0.00"}</strong>
            </div>
          </div>
        </div>
      </div>
    // </Popover>
  )

  // return (
  //   <Popover content={content} title="Abstract" placement="bottomLeft" overlayClassName="custom-popover" overlayStyle={{ width: viewParam.width,  margin:"20px auto" }}>
  //     <div className='paperCardDiv'
  //       // style={{height: viewParam.height, margin:"20px auto"}}
  //       style={{
  //         height: viewParam.height, 
  //         width: viewParam.width, 
  //         margin:"20px auto"}}
  //     >
  //         <div className='left-div'>
  //           <div className='predictionScoreBadgeContainer'>
  //             <div className='predictionScoreBadge'>
  //               <strong>{PaperInspectionData.scisciIndex.predictionScore ? (Number(PaperInspectionData.scisciIndex.predictionScore.toFixed(3)) * 100).toFixed(1) : "0.00"}</strong>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='right-div'>
  //           <div className='cardTitleBlock'>
  //             <div className='cardTitleText'> {PaperInspectionData.demographicInfo['paperTitle']} </div>
  //               <div className='cardTitle-tooltip'>
  //                 <div className='cardTitle-tooltip-text'>
  //                 {PaperInspectionData.demographicInfo['paperTitle']}
  //                 </div>
  //               </div>
  //           </div>
  //           <div className='cardYear'>
  //               <div className='attr-name'><div className='cardTextName'>Publication Year</div></div>
  //               <div className='cardYearNameText'>
  //                 {PaperInspectionData.demographicInfo['paperYear']}
  //               </div>
  //           </div>
  //           <div className='cardAuthorsName'>
  //               <div className='attr-name'><div className='cardTextName'>Authors</div></div>
  //               <div className='cardAuthorsNameText'>
  //                 {authors.map((author, index) => (
  //                   <span key={index} onClick={() => handleAuthorClick(authorsHash[index])}>
  //                     {author}
  //                     {index < authors.length - 1 ? ', ' : ''}
  //                   </span>
  //                 ))}
  //               </div>
  //           </div>
  //           <div className='cardMagName'>
  //               <div className='attr-name'><div className='cardTextName'>Journal Name</div></div>
  //               <div className='cardMagNameText'>
  //                 {PaperInspectionData.demographicInfo['paperMagName']}
  //               </div>
  //           </div>
  //           <div className='cardPaperDoi'>
  //               <div className='attr-name'>
  //                 <div className='cardTextName'>Doi Link</div>
  //               </div>
  //               <div className='cardDoiText'>
  //                 <a href={`https://doi.org/${PaperInspectionData.demographicInfo['hyperlink']}`} target="_blank">{PaperInspectionData.demographicInfo['paperDoi']}</a>
  //               </div>
  //           </div>
  //           <div className='cardSciCitation'>
  //               <div className='attr-name'><div className='cardTextName'>Patent Citation</div></div>
  //               <div className='cardSciCitationText'>
  //                 {PaperInspectionData.scisciIndex['patentCitationsCall']}
  //               </div>
  //           </div>
           
  //           {/* <div className='cardAuthor-tooltip'>
  //             <div className='cardAuthor-tooltip-text'>
  //               {PaperInspectionData.demographicInfo['authorsName']}
  //             </div>
  //           </div> */}
  //         </div>
  //     </div>
  //   </Popover>
  // )
}


export default PaperCard;